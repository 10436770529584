<template>
  <router-link
    class="grid grid-cols-1 content-center"
    :to="{ name: pathName, params: pathParams }"
  >
    <p v-if="isMobileView" class="mobileNavBarItem">
      {{ navBarItemName }}
    </p>
    <p v-else class="text-white no-underline">{{ navBarItemName }}</p>
  </router-link>
</template>

<script>
import { useWindowSize } from "vue-window-size";

export default {
  name: "NavBarItem",
  props: {
    navBarItemName: String,
    pathName: String,
    pathParams: String,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  computed: {
    isMobileView() {
      if (this.windowWidth <= 650) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
