import { createRouter, createWebHistory } from "vue-router";

import msal from "@/helpers/MsalHelpers.js";

// Dead Nav
import DeadPage from "@/views/404.vue";

// Main Nav
import MainHome from "@/views/main/Home.vue";

//remove the results link for now
import MainResults from "@/views/main/Results.vue";
import MainJudge from "@/views/main/Judge.vue";

// Main Admin Nav
import MainAdminDashboard from "@/views/admin/main/Dashboard.vue";
import MainAdminSettings from "@/views/admin/main/Settings.vue";

// Event Admin Nav
import EventAdminDashboard from "@/views/admin/event/Dashboard.vue";
import EventAdminJudges from "@/views/admin/event/Judges.vue";
import EventAdminProjects from "@/views/admin/event/Projects.vue";

// Day Admin Nav
import DayAdminDashboard from "@/views/admin/day/Dashboard.vue";
import DayAdminJudges from "@/views/admin/day/Judges.vue";

// Instructor Nav
import InstructorDashboard from "@/views/instructor/Dashboard.vue";
import InstructorSettings from "@/views/instructor/Settings.vue";

// Judge Nav
import JudgeCheckin from "@/views/judge/Checkin.vue";
import JudgeDashboard from "@/views/judge/Dashboard.vue";
import JudgeProjects from "@/views/judge/Projects.vue";
import JudgeRules from "@/views/judge/Rules.vue";
import JudgeSettings from "@/views/judge/Settings.vue";
import JudgeBallot from "@/views/judge/Ballot.vue";

const MainDir = "Main";

const MainAdminDir = "MainAdmin";
const EventAdminDir = "EventAdmin";
const DayAdminDir = "DayAdmin";

const InstructorDir = "Instructor";

const JudgeDir = "Judge";

const None = "None";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "DeadPage",
    component: DeadPage,
    meta: {
      title: "404 - Not Found",
    },
  },
  {
    path: "/",
    name: "MainHome",
    component: MainHome,
    meta: {
      dir: MainDir,
      title: "Home",
    },
  },
  {
    path: "/results",
    name: "MainResults",
    component: MainResults,
    meta: {
      dir: MainDir,
      title: "Results",
    },
  },
  {
    path: "/judge",
    name: "MainJudge",
    component: MainJudge,
    meta: {
      dir: MainDir,
      title: "Judge",
    },
  },
  {
    path: "/admin",
    name: "MainAdminDashboard",
    component: MainAdminDashboard,
    meta: {
      dir: MainAdminDir,
      title: "Main Dashboard",
    },
  },
  {
    path: "/admin/settings",
    name: "MainAdminSettings",
    component: MainAdminSettings,
    meta: {
      dir: MainAdminDir,
      title: "Main Settings",
    },
  },
  {
    path: "/admin/:eventName",
    name: "EventAdminDashboard",
    component: EventAdminDashboard,
    meta: {
      dir: EventAdminDir,
      title: "Event Dashboard",
    },
  },
  {
    path: "/admin/:eventName/judges",
    name: "EventAdminJudges",
    component: EventAdminJudges,
    meta: {
      dir: EventAdminDir,
      title: "Event Judges",
    },
  },
  {
    path: "/admin/:eventName/projects",
    name: "EventAdminProjects",
    component: EventAdminProjects,
    meta: {
      dir: EventAdminDir,
      title: "Event Projects",
    },
  },
  {
    path: "/admin/:eventName/:dayIndex",
    name: "DayAdminDashboard",
    component: DayAdminDashboard,
    meta: {
      dir: DayAdminDir,
      title: "Day Dashboard",
    },
  },
  {
    path: "/admin/:eventName/:dayIndex/judges",
    name: "DayAdminJudges",
    component: DayAdminJudges,
    meta: {
      dir: DayAdminDir,
      title: "Day Judges",
    },
  },
  {
    path: "/instructor",
    name: "InstructorDashboard",
    component: InstructorDashboard,
    meta: {
      dir: InstructorDir,
      title: "Dashboard",
    },
  },
  {
    path: "/instructor/settings",
    name: "InstructorSettings",
    component: InstructorSettings,
    meta: {
      dir: InstructorDir,
      title: "Settings",
    },
  },
  {
    path: "/judge/:uniqueId",
    name: "JudgeCheckin",
    component: JudgeCheckin,
    meta: {
      dir: None,
      title: "Checkin",
    },
  },
  {
    path: "/judge/dashboard",
    name: "JudgeDashboard",
    component: JudgeDashboard,
    meta: {
      dir: JudgeDir,
      title: "Dashboard",
    },
  },
  {
    path: "/judge/projects",
    name: "JudgeProjects",
    component: JudgeProjects,
    meta: {
      dir: JudgeDir,
      title: "Projects",
    },
  },
  {
    path: "/judge/rules",
    name: "JudgeRules",
    component: JudgeRules,
    meta: {
      dir: JudgeDir,
      title: "Rules",
    },
  },
  {
    path: "/judge/settings",
    name: "JudgeSettings",
    component: JudgeSettings,
    meta: {
      dir: JudgeDir,
      title: "Settings",
    },
  },
  {
    path: "/judge/ballot/:projectId",
    name: "JudgeBallot",
    component: JudgeBallot,
    meta: {
      dir: JudgeDir,
      title: "Ballot",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Add judge protected directories here:
const judgeAuthorizedDirs = new Set([JudgeDir]);

// (Admin & Instructor AzureAD Auth)
// Add azure protected directories here:
const adminAuthorizedDirs = new Set([MainAdminDir, EventAdminDir, DayAdminDir]);

const instructorAuthorizedDirs = new Set([InstructorDir]);

router.beforeEach(async (to, from) => {
  if (
    (adminAuthorizedDirs.has(to.meta.dir) &&
      !(await msal.verifyAccessToken(
        localStorage.getItem("accessToken"),
        null,
        "AdminDir"
      ))) ||
    (instructorAuthorizedDirs.has(to.meta.dir) &&
      !(await msal.verifyAccessToken(
        localStorage.getItem("accessToken"),
        null,
        "InstructorDir"
      ))) ||
    (judgeAuthorizedDirs.has(to.meta.dir) &&
      !(await msal.verifyAccessToken(
        null,
        localStorage.getItem("judgeHash"),
        "JudgeDir"
      )))
  ) {
    router.push({
      name: "MainHome",
    });

    return;
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (nearestWithMeta) {
    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
      .map((tagDef) => {
        const tag = document.createElement("meta");

        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute("data-vue-router-controlled", "");

        return tag;
      })
      // Add the meta tags to the document head.
      .forEach((tag) => document.head.appendChild(tag));
  }
});

export default router;
