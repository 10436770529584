import axios from "axios";

export const resultsDefaultState = () => {
    return { results: undefined };
};

export const results = {
    state: resultsDefaultState,
    getters: {
        getAllResults: (state) => {
            return state.results;
        },
        getResultsForEvent: (state) => (id) => {
            return state.results?.filter((r) => r.id == id)[0];
        },
        resultsLoaded: (state) => {
            return !!state.results;
        },
    },
    mutations: {
        setResults(state, results) {
            state.results = results;
        },
        postResult(state, eventId) {
            const index = state.results.findIndex((r) => r.id == eventId);
            state.results[index].posted = true;
        },
        unpostResult(state, eventId) {
            const index = state.results.findIndex((r) => r.id == eventId);
            state.results[index].posted = false;
        },
    },
    actions: {
        async fetchAllResults(context) {
            await axios
                .get("/results")
                .then((response) => context.commit("setResults", response.data));
        },
        async fetchAllPostedResults(context) {
            await axios
                .get("/results/posted")
                .then((response) => context.commit("setResults", response.data));
        },
        async postResultsWithEventId(context, eventId) {
            await axios
                .put(`results/${eventId}/post`)
                .then(() => context.commit("postResult", eventId));
        },
        async unpostResultsWithEventId(context, eventId) {
            await axios
                .put(`results/${eventId}/unpost`)
                .then(() => context.commit("unpostResult", eventId));
        },
        downloadBallots(context, { eventId, round }) {
            let url = "/results/" + eventId + "/csvBallot/";
            if (round || round == 0) url += round;

            return axios({
                url: url,
                method: "GET",
                responseType: "blob",
            });
        },
        downloadProjectBallots(context, { eventId }) {
            let url = "/results/" + eventId + "/csvProjectBallots/";
  
            return axios({
                url: url,
                method: "GET",
                responseType: "blob",
            });
        },
        downloadProjects(context, { eventId, round }) {
            let url = "/results/" + eventId + "/csvProject/";
            if (round || round == 0) url += round;

            return axios({
                url: url,
                method: "GET",
                responseType: "blob",
            });
        },
        downloadJudges(context, { eventId }) {
            let url = "/results/" + eventId + "/psvJudge/";

            return axios({
                url: url,
                method: "GET",
                responseType: "blob",
            });
        },
    },
};
