<template>
  <div class="eventDetailsListFilter grid justify-items-center">
    <n-input
      v-model:value="searchValue"
      placeholder="Filter by Name, Disciplines, Sponsors..."
    />
  </div>
  <div v-if="projectListLength == 0" class="grid place-items-center">
    <h1 class="font-bold my-10">There are no projects.</h1>
  </div>
  <div v-else>
    <n-layout class="eventDetailsList" :native-scrollbar="false">
      <div v-for="project in projectList" :key="project">
        <project-details
          :is-ballots="isJudgeProject(project)"
          :project-id="project.id"
        />
      </div>
    </n-layout>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import ProjectDetails from "./ProjectDetails.vue";

export default defineComponent({
  props: {
    isBallots: Boolean,
  },
  components: {
    "project-details": ProjectDetails,
  },
  computed: {
    projectListLength() {
      return this.projectList == null ? 0 : this.projectList.length;
    },
  },
  setup(props) {
    const store = useStore();

    const searchValue = ref("");

    const isJudgeProject = (project) => {
      return !!project["ballots"]?.length;
    };

    const projectList = computed(() => {
      return [
        ...(props.isBallots
          ? store.getters.getProjectsAssignedToMe
          : store.getters.getAllProjects),
      ]
        .filter((b) => {
          let flag = false;
          const filter = searchValue.value.toLowerCase();
          flag |= b.name.toLowerCase().includes(filter);
          b.disciplines.forEach(
            (d) => (flag |= d.toLowerCase().includes(filter))
          );
          b.sponsors.forEach((s) => (flag |= s.toLowerCase().includes(filter)));
          return flag;
        })
        .sort((a, b) => {
          if (props.isBallots) {
            const ballotA = a.ballots[0];
            const ballotB = b.ballots[0];
            if (ballotA.scores.length > 0 && ballotB.scores.length > 0) {
              return a.name < b.name ? -1 : 1;
            } else if (ballotA.scores.length > 0) {
              return 1;
            } else if (ballotB.scores.length > 0) {
              return -1;
            } else {
              if (a.name < b.name) return -1;
              else return 1;
            }
          } else {
            return a.name < b.name ? -1 : 1;
          }
        });
    });

    return {
      searchValue,
      projectList,
      isJudgeProject,
    };
  },
});
</script>
