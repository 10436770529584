<template>
  <div class="ProjectDetails">
    <n-card class="ballotDetailsCard" @click="toggle ^= true">
      <n-grid :cols="2">
        <n-gi className="ballotDetailsDisciplines">{{ disciplines }} </n-gi>
        <n-gi className="ballotDetailsSubmittedVal">
          <span className="ballotDetailsNumCompleted">{{ numCompleted }}</span>
          / {{ numTotal }} Ballots Submitted
          <span className="tertiaryButton">
            <n-button @click.stop="didClickNotification()">
              <BellIcon class="buttonIcon" /> Notify
            </n-button>
          </span>
        </n-gi>
      </n-grid>
      <n-divider />
      <div className="ballotDetailsName">{{ judgeObject.name }}</div>
      <div className="ballotDetailsDescription">
        <div>
          <p className="ballotDetailsSubdescription">
            <span className="ballotDetailsSubtext"> Affiliation(s) </span>
            <span>
              {{ affiliations }}
            </span>
          </p>
        </div>
        <div class="primaryButton ballotDetailsToggleButton">
          <n-button @click.stop="toggle ^= true">{{ getText }}</n-button>
        </div>
      </div>
      <div v-show="toggle">
        <p className="ballotCardJudgeManagement">Ballot Management</p>
        <n-grid :cols="2">
          <n-gi>
            <div className="eventJudgesDataTable">
              <n-data-table
                ref="table"
                :columns="columns"
                :data="data"
                :pagination="pagination"
                @update:page="toggle ^= true"
              >
                <template #empty>
                  This judge has no projects assigned.
                </template>
              </n-data-table>
            </div>
          </n-gi>
          <n-gi class="judgeManagementButtons">
            <span class="primaryButton">
              <n-button
                @click.stop="showAddProjectModal = true"
                :disabled="!activeEvent.dayOngoing"
              >
                Add Project
              </n-button>
            </span>
          </n-gi>
        </n-grid>
      </div>
    </n-card>
    <div class="primaryModal">
      <n-modal
        :class="[isMobileView() ? 'popoverModalMobile' : 'popoverModal']"
        v-model:show="showAddProjectModal"
        preset="card"
        :title="'Add Project(s) to ' + judgeObject.name"
        :key="judgeObject.id"
      >
        <n-select
          placeholder="Select Projects"
          v-model:value="newProjects"
          :options="getAvailableProjects"
          multiple
          filterable
        >
          <template #empty>
            There are no projects available for this judge.
          </template>
        </n-select>
        <n-checkbox-group v-model:value="notify" class="mt-2">
          <n-checkbox :value="true"> Notify Judge </n-checkbox>
        </n-checkbox-group>
        <div class="primaryButton addJudgeRemoveProjButton">
          <n-button
            @click="assignBallots()"
            :loading="assignIsLoading"
            :disabled="newProjects.length < 1"
          >
            Assign Ballots
          </n-button>
        </div>
      </n-modal>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import { BellIcon } from "@heroicons/vue/outline";
import { useWindowSize } from "vue-window-size";

export default defineComponent({
  props: {
    judgeObject: Object,
  },
  components: {
    BellIcon,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      showModal: ref(false),
      windowWidth: width,
      windowHeight: height,
    };
  },
  methods: {
    isMobileView() {
      if (this.windowWidth <= 650) {
        return true;
      } else {
        return false;
      }
    },
  },
  emits: ["send"],
  computed: {
    disciplines() {
      if (this.judgeObject.disciplines.length == 0)
        return "No Preferred Disciplines";
      return this.judgeObject.disciplines.toString().replaceAll(",", ", ");
    },
    affiliations() {
      if (this.judgeObject.affiliations.length == 0) return "None";
      return this.judgeObject.affiliations.toString().replaceAll(",", ", ");
    },
    getText() {
      return this.toggle ? "Show Less" : "Show More";
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const activeEvent = computed(() => {
      return store.getters.getActiveEvent;
    });

    const ballots = computed(() => {
      return store.getters.getBallotsByJudge(props.judgeObject.id);
    });

    const idMap = {};
    const data = computed(() => {
      return ballots.value?.map((b) => {
        let project = idMap[b.projectId]
          ? idMap[b.projectId]
          : (idMap[b.projectId] = store.getters.getProjectWithId(
              b.projectId
            )?.name);

        if (!project) project = "NOT IN ROUND";

        return {
          id: b.id,
          project: project,
          projectId: b.projectId,
          status: b.completed ? "Complete" : "Incomplete",
        };
      });
    });

    const getAvailableProjects = computed(() => {
      return [...store.getters.getAllProjects]
        ?.filter((p) => {
          let flag = false;
          props.judgeObject.affiliations.forEach(
            (s) => (flag |= p.sponsors.includes(s))
          );
          data.value?.forEach((d) => (flag |= d.projectId == p.id));
          return !flag;
        })
        .sort((a, b) => {
          let aFlag = 0,
            bFlag = 0;
          props.judgeObject.disciplines.forEach((d) => {
            if (a.disciplines.includes(d)) aFlag++;
            if (b.disciplines.includes(d)) bFlag++;
          });

          if (aFlag == bFlag) {
            return a.name < b.name;
          } else if (aFlag > bFlag) {
            return -1;
          } else {
            return 1;
          }
        })
        .map((j) => ({ label: j.name, value: j.id }));
    });

    const didClickNotification = () => {
      emit("send", props.judgeObject.id);
    };

    const table = ref(null);
    const columns = [
      {
        title: "Project",
        key: "project",
      },
      {
        title: "Status",
        key: "status",
        width: 150,
      },
    ];

    const newProjects = ref([]);

    const numCompleted = computed(() => {
      let ret = 0;
      data.value?.forEach((b) => (b.status == "Complete" ? ret++ : ret));
      return ret;
    });

    const numTotal = computed(() => {
      return data.value?.length;
    });

    const showAddProjectModal = ref(false);
    const notify = ref([true]);

    const assignIsLoading = ref(false);
    const assignBallots = () => {
      assignIsLoading.value = true;
      newProjects.value.forEach(
        async (p) =>
          await store
            .dispatch("assignBallotToJudge", {
              judgeId: props.judgeObject.id,
              projectId: p,
            })
            .then(() => {
              if (notify.value[0]) {
                store.dispatch("sendNotificationToJudge", {
                  judgeId: props.judgeObject.id,
                  notification: {
                    title: "New Ballot Assigned!",
                    body:
                      "You have been assigned a ballot for " + idMap[p] + ".",
                  },
                });
              }
            })
      );
      newProjects.value = [];
      assignIsLoading.value = false;
      showAddProjectModal.value = false;
    };

    return {
      activeEvent,
      toggle: ref(false),
      table,
      columns,
      data,
      showAddProjectModal,
      numCompleted,
      numTotal,
      getAvailableProjects,
      newProjects,
      pagination: {
        pageSize: 3,
      },
      assignIsLoading,
      assignBallots,
      didClickNotification,
      notify,
    };
  },
});
</script>
