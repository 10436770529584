<template>
  <div class="uploadFileContent primaryButton">
    <n-upload
      ref="upload"
      accept=".csv"
      name="files"
      :max="1"
      :action="getURL()"
      :headers="getHeaders()"
      @finish="onFinish"
      @error="onError"
    >
      <n-upload-dragger>
        <p style="font-size: 16px">
          Click or drag a file to this area to upload
        </p>
      </n-upload-dragger>
    </n-upload>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import { useMessage } from "naive-ui";

export default defineComponent({
  props: {
    isJudge: Boolean,
    eventId: String,
    eventName: String,
  },
  emits: ["close"],
  methods: {
    getHeaders() {
      return axios.defaults.headers.common;
    },
    getURL() {
      return (
        axios.defaults.baseURL +
        (this.isJudge ? "/judge/" : "/projects/") +
        this.eventId +
        "/csv"
      );
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const message = useMessage();
    const uploadRef = ref(null);

    return {
      upload: uploadRef,
      onFinish: () => {
        if (props.isJudge) store.dispatch("fetchAllJudges", props.eventName);
        else store.dispatch("fetchAllProjects", props.eventName);

        emit("close");
        message.success("File Upload Complete");
      },
      onError: () => {
        message.error("File Upload Failed");
      },
    };
  },
});
</script>
