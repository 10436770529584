import axios from "axios";

class msal {
  static async verifyAccessToken(accessToken, judgeHash, dir) {
    let flag = false;

    if (judgeHash && dir == "JudgeDir") {
      try {
        await axios.get("judge/self");
        return true;
      } catch (Exception) {
        return false;
      }
    } else if (accessToken && (dir == "AdminDir" || dir == "InstructorDir")) {
      try {
        await axios.get("account/instructors");
        flag = dir != "InstructorDir";
      } catch (Exception) {
        try {
          await axios.get("projects/round");
          flag = dir != "AdminDir";
        } catch (Exception) {
          return false;
        }
      }
    }

    return flag;
  }

  static async login(msalInstance, store) {
    // Method to acquire token for account
    // after logging in. Mutates states too
    const aquireToken = async (account, config) => {
      msalInstance
        .acquireTokenSilent({
          account,
          ...config,
        })
        .then((response) => {
          store.dispatch("setAuth", response);
        })
        .catch(() => {
          msalInstance
            .acquireTokenPopup({
              account,
              ...config,
            })
            .then((response) => {
              store.dispatch("setAuth", response);
            })
            .catch(() => {});
        })
        .finally(() => store.commit("stopLoading"));
    };

    store.commit("startLoading");

    await msalInstance
      .loginPopup({})
      .then(async () => {
        const account = msalInstance.getAllAccounts()[0];
        aquireToken(account, {
            scopes: ["api://637a0c38-6691-4450-ac1b-9b33d0ac507b/access_as_user"],
        });
      })
      .catch(() => {
        store.commit("stopLoading");
      });
  }

  static async logout(msalInstance, store) {
    if (store.getters.getAccountType == "Judge") {
      store.dispatch("setAuth", null);
    } else {
      // To Do: Failed logout fix
      await msalInstance
        .logoutPopup({})
        .then(() => {
          store.dispatch("setAuth", null);
        })
        .catch(() => {});
    }
  }
}

export default msal;
