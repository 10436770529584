<template>
  <div class="primaryButton">
    <n-button @click="emitSaveEvent()">
      <CheckCircleIcon class="h-3 w-3 mr-1" />
      {{ $strings.SAVE }}
    </n-button>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { CheckCircleIcon } from "@heroicons/vue/outline";

export default defineComponent({
  components: {
    CheckCircleIcon,
  },
  methods: {
    emitSaveEvent() {
      this.$emit("save");
    },
  },
});
</script>
