import axios from "axios";

const resetActiveEvents = (state, event) => {
  // Ensure nothing else is locally active
  if (event.status == "Active") {
    state.events.map((e) => ({
      ...e, // and overwrite status
      status: e.status !== "Active" ? e.status : "Inactive",
    }));
  }
};

export const eventsDefaultState = () => {
  return { events: undefined };
};

export const events = {
  state: eventsDefaultState,
  getters: {
    getAllEvents: (state) => {
      return state.events;
    },
    getEventsWithStatus: (state) => (status) => {
      return state.events?.filter((event) => event.status == status);
    },
    getActiveEvent: (state) => {
      return state.events?.filter((event) => event.status == "Active")[0];
    },
    getEventWithId: (state) => (id) => {
      const index = state.events.findIndex((e) => e.id == id);
      return state.events[index];
    },
    getEventWithName: (state) => (name) => {
      const index = state.events.findIndex((e) => e.name == name);
      return state.events[index];
    },
    hasActiveEvent: (state, getters) => {
      return getters.getEventsWithStatus("Active").length > 0;
    },
    eventsLoaded: (state) => {
      return !!state.events;
    },
  },
  mutations: {
    setEvents(state, events) {
      state.events = events;
    },
    addEvent(state, event) {
      resetActiveEvents(state, event);

      // Add event
      state.events.push(event);
    },
    editEvent(state, event) {
      resetActiveEvents(state, event);

      const existingIdx = state.events.findIndex((e) => e.id == event.id);
      state.events[existingIdx] = event;
    },
    resetEvents(state) {
      state.events = undefined;
    },
    setProjectCount(state, { eventId, count }) {
      const existingIdx = state.events.findIndex((e) => e.id == eventId);
      state.events[existingIdx].numProjects = count;
    },
    setJudgeCount(state, { eventId, count }) {
      const existingIdx = state.events.findIndex((e) => e.id == eventId);
      state.events[existingIdx].numJudges = count;
    },
  },
  actions: {
    async fetchAllEvents(context) {
      await axios.get("events").then((response) => {
        const events = response.data;
        context.commit("setEvents", events);
      });
    },
    async fetchActiveEvent(context) {
      await axios.get("events/active").then((response) => {
        const event = response.data;
        context.commit("setEvents", [event]);
      });
    },
    async startActiveEvent(context) {
      const activeEvent = context.getters.getActiveEvent;
      await axios
        .post(`events/start/${activeEvent.id}`)
        .then((response) => context.commit("editEvent", response.data));
    },
    async startJudging(context) {
      const activeEvent = context.getters.getActiveEvent;
      await axios
        .post(`events/start/${activeEvent.id}/judge`)
        .then((response) => {
          context.commit("editEvent", response.data);
        });
    },
    async startCurrentRound(context) {
      const activeEvent = context.getters.getActiveEvent;
      await axios.post(`events/start/${activeEvent.id}/day`);
    },
    async endDaySameRound(context) {
      await axios.post("events/day/conclude").then((response) => {
        context.commit("resetEvents");
        context.commit("setEvents", [response.data]);
      });
    },
    async endDayNextRound(context, topCut) {
      const activeEvent = context.getters.getActiveEvent;
      await axios
        .post(`events/tabulate/${activeEvent.id}/${topCut}`)
        .then((response) => {
          context.commit("resetEvents");
          context.commit("setEvents", [response.data]);
        });
    },
    async addEvent(context, event) {
      await axios.post("events", event).then((response) => {
        const event = response.data;
        context.commit("addEvent", event);
      });
    },
    async editEvent(context, event) {
      await axios.put("events", event).then((response) => {
        const newEvent = response.data;
        context.commit("editEvent", newEvent);
      });
    },
    async advanceProject(context, pid) {
      axios.post(`events/advance/${pid}`);
    },
    async deadvanceProject(context, pid) {
      axios.post(`events/deadvance/${pid}`);
    },
  },
};
