<template>
  <div>
    <div
      v-show="$store.getters.isLoading"
      class="top-0 z-50 h-screen w-screen fixed bg-black opacity-60"
    >
      <loading
        class="loadingStyling"
        :active="true"
        loader="dots"
        color="#FFF"
        :height="100"
        :width="100"
      />
    </div>
    <div class="primaryModal">
      <n-modal
        v-if="$store.getters.isErrorShowing"
        :show="true"
        preset="dialog"
        type="error"
        :closable="false"
        :title="$store.getters.getHeader"
        :content="$store.getters.getErrorMessage"
        :positive-text="$strings.CONFIRM_ERROR"
        :negative-text="$strings.CANCEL_ERROR"
        @positive-click="tryAgain"
        @negative-click="closeErrorDialog"
      />
    </div>
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Loading from "vue3-loading-overlay";

export default defineComponent({
  components: {
    Loading,
  },
  methods: {
    tryAgain() {
      this.closeErrorDialog();
      while (!this.$store.getters.retryQueueIsEmpty) {
        const nextItem = this.$store.getters.getNextRetryItem;
        this.$store.dispatch("APIHelper", nextItem);
      }
    },
    closeErrorDialog() {
      this.$store.commit("hideErrorDialog");
    },
  },
});
</script>
