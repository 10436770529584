<template>
  <div class="EventDetails" @click="handleSelectEvent(eventObject)">
    <n-card
      hoverable
      :title="eventObject.name"
      class="
        eventDetailsCard
        my-5
        cursor-pointer
        grid
        transition
        duration-500
        ease-in-out
      "
    >
      <p class="eventDetailsCardDate text-light-gray">
        {{ getDate }}
      </p>
      <hr />
      <div class="grid grid-cols-2 mt-4">
        <div>
          <p class="grid grid-cols-2 self-center">
            <span id="eventDetailsCardContent">{{ $strings.TEAMS }}</span>
            <span id="eventDetailsCardTeamsVal">
              {{ getTeamCount }}
            </span>
          </p>
          <p class="grid grid-cols-2 self-center">
            <span id="eventDetailsCardContent">{{ $strings.JUDGES }}</span>
            <span id="eventDetailsCardJudgesVal">{{ getJudgeCount }}</span>
          </p>
        </div>
        <edit-event-button
          class="justify-self-end self-center"
          :event-object="eventObject"
        />
      </div>
    </n-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DateFormatters from "../helpers/DateFormatters.js";
import NewEventForm from "../components/NewEventForm.vue";
import EditEventButton from "../components/EditEventButton.vue";

export default defineComponent({
  name: "EventDetails",
  components: {
    "new-event-form": NewEventForm,
    "edit-event-button": EditEventButton,
  },
  props: {
    eventId: String,
  },
  methods: {
    handleSelectEvent(eventObject) {
      this.$router.push({
        name: "EventAdminDashboard",
        params: { eventName: eventObject.name },
      });
    },
  },
  computed: {
    isMobileView() {
      if (this.windowWidth <= 950) {
        return true;
      } else {
        return false;
      }
    },
    eventObject() {
      return this.$store.getters.getEventWithId(this.eventId);
    },
    getJudgeCount() {
      return this.eventObject.numJudges > 0
        ? this.eventObject.numJudges
        : this.$strings.TBD;
    },
    getTeamCount() {
      return this.eventObject.numProjects > 0
        ? this.eventObject.numProjects
        : this.$strings.TBD;
    },
    getDate() {
      return DateFormatters.getDateRange(this.eventObject.timespan);
    },
  },
});
</script>

<style></style>
