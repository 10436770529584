import axios from "axios";
import { HubConnectionBuilder, LogLevel } from "@aspnet/signalr";

const createSocketConnection = (context, isJudge) => {
  const azureAdToken = axios.defaults.headers.common["AUTHORIZATION"];
  const baseURL = `${
    axios.defaults.baseURL?.split("/api/")[0]?.split("/api")[0]
  }`;

  const connection = new HubConnectionBuilder()
    .withUrl(
      `${baseURL}/hub`,
      azureAdToken ? { accessTokenFactory: () => azureAdToken } : null
    )
    .configureLogging(LogLevel.Information)
    .build();

  let startedPromise = null;
  function start() {
    startedPromise = connection.start().catch(() => {
      return new Promise((resolve, reject) =>
        setTimeout(() => start().then(resolve).catch(reject), 5000)
      );
    });
    return startedPromise;
  }
  connection.onclose(() => start());

  // Start connection and join correct channels
  start().finally(() => {
    // Update app state
    // context.commit('setSocketConnectionStatus', true)

    // Connect to judge channel
    if (isJudge) {
      connection.invoke("SubscribeToJudge", isJudge).then((res) => {
        console.log(res);
      });
    }

    // Otherwise only connect to Event Channel when on event pages as admin
    else {
      connection.invoke("SubscribeToEvent").then((res) => {
        console.log(res);
      });
    }
  });

  // Handle Socket Events
  connection.on("Message", (message) => {
    console.log("Socket sent payload ", message);
  });

  ///////////////////////
  // Judge related events
  ////////////////////////
  //
  // Admin assigns ballot to signed in judge
  // Not sure about this one - Fiona
  connection.on("JudgeBallotAssigned", ({ ballot, project }) => {
    console.log("[Socket] Ballot assigned", ballot, project);
    context.commit("addBallotMutation", ballot);
  });

  // Judge receives notification
  connection.on("JudgeNotificationReceived", ({ notification }) => {
    console.log("[Socket] Notification received", notification);
    context.commit("addNotification", notification);

    let account = context.getters.getAccount;
    account.notifications = context.getters.getAllNotifications;
    context.commit("setAccount", account);
  });

  // Judge deletes a notification
  connection.on("JudgeNotificationRemoved", ({ notificationId }) => {
    console.log("[Socket] Notification", notificationId, "deleted");
    // TODO : context update state
  });

  ///////////////////////
  // Event related events
  ////////////////////////
  //
  // Judge now assigned to certain ballot
  connection.on("BallotOptIn", ({ ballot, judge, project }) => {
    console.log("[Socket] Ballot opt in", ballot, judge, project);
    context.commit("addBallot", ballot);
  });

  // Judge submits
  connection.on(
    "BallotSubmitted",
    ({ newActive, newSubmit, ballot, judge }) => {
      console.log("[Socket] Ballot save", newActive, newSubmit, ballot, judge);
      context.commit("editBallot", ballot);
    }
  );

  // Attendance Updated
  connection.on("AttendanceUpdate", ({ judge }) => {
    console.log("[Socket] New attendance", judge);
    context.commit("editJudge", judge);
  });

  connection.on("projectDeadvanced", ({ project, ballots }) => {
    console.log("[Socket] Project Deadvanced", project, ballots);
    context.commit("editProject", project);
    ballots.forEach((b) => context.commit("deleteBallot", b.id));
  });

  connection.on("projectAdvanced", ({ project }) => {
    console.log("[Socket] Project Advanced", project);
    context.commit("editProject", project);
  });

  // Event id across instance of connection
  connection.eventId = null;

  return connection;
};

export const userDefaultState = () => {
  return {
    msalConfig: {
      auth: {
            clientId: "b65a507f-caaf-48d7-b136-5552fd56c37b",
        authority:
          "https://login.microsoftonline.com/455c698a-b465-4879-aa55-ec8d02dc3190",
      },
      cache: {
        cacheLocation: "localStorage",
      },
    },
    accessToken: undefined,
    account: undefined,
    accountType: undefined,
    judgeHash: undefined,
  };
};

// Axios Interceptor Identifier
let authInterceptor, socketConnection;

// Dispose method
export const disposeAuthInterceptor = () => {
  // Dispose old Auth Interceptor
  if (authInterceptor != undefined) {
    axios.interceptors.response.eject(authInterceptor);
    authInterceptor = undefined;
  }
};
export const disposeSocketConnection = async () => {
  // Dispose old Auth Interceptor
  if (socketConnection != undefined) {
    await socketConnection.stop();
    socketConnection = undefined;
  }
};

// Method to create axios interceptor for auth
const createAuthInterceptor = (
  resetStateMethod,
  isJudge,
  context,
  createSocket = true
) => {
  // Dispose old interceptor just in case
  disposeAuthInterceptor();

  // Dispose old socket just in case
  disposeSocketConnection();

  // Create new socket connection
  if (createSocket) socketConnection = createSocketConnection(context, isJudge);

  return axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const AUTH_ERROR = isJudge ? 403 : 401;
      const originalError = error;

      switch (error.response.status) {
        // Auth related errors
        case AUTH_ERROR:
          // Reset application state
          // and essentailly log out user
          resetStateMethod();

          // Clear Socket
          disposeSocketConnection();

          // Return the error promise
          return Promise.reject(originalError);

        default:
          return Promise.reject(originalError);
      }
    }
  );
};

export const user = {
  state: userDefaultState,
  getters: {
    getMsalConfig: (state) => {
      return state.msalConfig;
    },
    getAccessToken: (state) => {
      return state.accessToken;
    },
    getJudgeHash: (state) => {
      return state.judgeHash;
    },
    getAccount: (state) => {
      return state.account;
    },
    getAccountType: (state) => {
      return state.accountType;
    },
    accountLoaded: (state) => {
      return !!state.account;
    },
  },
  mutations: {
    setAccountInfo(state, account) {
      state.account = account;
      state.accountType = account.role;
      localStorage.setItem("account", JSON.stringify(account));
      localStorage.setItem("role", account.role);
    },
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken;
      localStorage.setItem("accessToken", state.accessToken);
    },
    setAccount(state, account) {
      state.account = account;
      localStorage.setItem("account", JSON.stringify(account));
    },
    setAccountType(state, role) {
      state.accountType = role;
    },
    setJudgeHash(state, judgeHash) {
      state.judgeHash = judgeHash;
    },
  },

  actions: {
    setFromLocal(context, { judgeHash, accessToken, account, role }) {
      // Set judge auth headers
      if (judgeHash) {
        axios.defaults.headers.common["X-JUDGE-AUTH"] = judgeHash;
        // TODO : create interceptor for judge auth
        context.commit("setJudgeHash", judgeHash);
        context.commit("setAccountType", "Judge");

        // Call this whenever
        const resetState_f = () => context.commit("resetState");

        // Register axios auth interceptor for judge
        authInterceptor = createAuthInterceptor(
          resetState_f,
          judgeHash,
          context
        );

        // Other info
        if (account) context.commit("setAccount", JSON.parse(account));

        // Register Socket
        // TODO :
      }

      // Set azure auth headers
      else if (accessToken) {
        axios.defaults.headers.common[
          "AUTHORIZATION"
        ] = `Bearer ${accessToken}`;

        context.commit("setAccessToken", accessToken);

        // Call this whenever
        const resetState_f = () => context.commit("resetState");

        // Register axios auth interceptor for admin
        authInterceptor = createAuthInterceptor(
          resetState_f,
          false,
          context,
          role == "Admin"
        );

        // Other info
        if (account) context.commit("setAccount", JSON.parse(account));
        if (role) context.commit("setAccountType", role);

        // Register Socket
        // TODO :
      }
    },
    setJudgeAccessToken(context, judgeHash) {
      context.commit("setAccount", undefined);
      context.commit("setJudgeHash", judgeHash);
      context.commit("setAccountType", "Judge");

      localStorage.clear();

      // Clear axios state stuff
      delete axios.defaults.headers.common["AUTHORIZATION"];

      // Set axios judge auth header
      axios.defaults.headers.common["X-JUDGE-AUTH"] = judgeHash;
      localStorage.setItem("judgeHash", judgeHash);

      // Call this whenever
      const resetState_f = () => context.commit("resetState");

      // Register axios auth interceptor for judge
      authInterceptor = createAuthInterceptor(resetState_f, judgeHash, context);

      // Register Socket
      // TODO :
    },
    async setAuth(context, auth) {
      if (!auth || !auth.accessToken) {
        context.commit("resetState");
      } else {
        context.commit("setAccessToken", auth.accessToken);
        // Get from database
        // Axios Auth header
        axios.defaults.headers.common[
          "AUTHORIZATION"
        ] = `Bearer ${auth.accessToken}`;

        // Register and retrieve metadata
        await axios
          .post("account")
          .then((response) => {
            context.commit("setAccountInfo", response.data);

            // Call this whenever
            const resetState_f = () => context.commit("resetState");

            // Register axios auth interceptor
            authInterceptor = createAuthInterceptor(
              resetState_f,
              false,
              context,
              response.data?.role == "Admin"
            );

            // Register Socket
            // TODO :
          })
          .catch(() => {
            // Clear auth state
            // or set account infromation from MSAL only
            context.commit("resetState");

            // TODO : log error ..?
            context.commit("showErrorDialog", {
              message: "Error loging in to account",
            });
          });
      }
    },
    async updateAccountInfo(context, { firstName, lastName }) {
      let account = context.getters.getAccount;
      account.name = firstName + " " + lastName;
      await axios
        .put("account/", account)
        .then((response) => context.commit("setAccountInfo", response.data));
    },
    async updateJudgeInfo(
      context,
      { firstName, lastName, email, affiliations }
    ) {
      let account = context.getters.getAccount;
      account.name = firstName + " " + lastName;
      account.email = email;
      account.affiliations = affiliations;
      await axios
        .put("judge/", account)
        .then((response) => context.commit("setAccountInfo", response.data));
    },
    async updateJudgeAttendance(context, attendance) {
      // Get attendance days
      const days = attendance
        .map((v, i) => (v === "Attending" ? i : ""))
        .filter(String);

      await axios
        .put("judge/attendance", days)
        .then((response) => context.commit("setAccountInfo", response.data));
    },
    async getJudgeFromId(context) {
      await axios
        .get("judge/self")
        .then((response) => context.commit("setAccountInfo", response.data));
    },
    async checkInJudge(context, info) {
      let account = context.getters.getAccount;
      account.affiliations = info.affiliations;
      account.attendance = info.attendance;
      account.isCheckedIn = true;

      // Get attendance days
      account.days = account.attendance
        .map((v, i) => (v === "Attending" ? i : ""))
        .filter(String);

      // Checkin Item for Request
      const checkinItem = (({ days, affiliations }) => ({
        days,
        affiliations,
      }))(account);

      await axios
        .post("judge/checkin", checkinItem)
        .then(() => context.commit("setAccountInfo", account));
    },
  },
};
