<template>
  <div v-if="eventsLoaded">
    <p class="settingsSubheader">Attendance</p>
    <p class="settingsDescription">
      This contains information visible to others on the application. Filler
      sentence detailing block.
    </p>

    <div class="settingsBody">
      <n-grid :cols="getResponsiveCols()">
        <n-gi v-for="index in numDays" :key="index">
          <h2>{{ activeEvent.modality[index - 1] }}</h2>
          <n-form-item :label="getDateLabel(index)">
            <n-radio-group
              v-model:value="attendance[index - 1]"
              :disabled="index - 1 <= currDay"
              @update:value="changesMade = true"
            >
              <n-radio value="Attending">Attending</n-radio>
              <n-radio value="Absent">Absent</n-radio>
            </n-radio-group>
          </n-form-item>
        </n-gi>
      </n-grid>
    </div>

    <div class="primaryButton">
      <n-button @click="updateAttendance" :disabled="!changesMade">
        <PencilAltIcon class="buttonIcon" />
        Save Changes
      </n-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import { useMessage } from "naive-ui";
import { PencilAltIcon } from "@heroicons/vue/outline";
import DateFormatters from "@/helpers/DateFormatters.js";
import { useWindowSize } from "vue-window-size";

export default defineComponent({
  components: {
    PencilAltIcon,
  },
  computed: {
    eventsLoaded() {
      return this.$store.getters.eventsLoaded;
    },
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  watch: {
    att(newValue) {
      this.attendance = newValue;
    },
  },
  methods: {
    getResponsiveCols() {
      if (this.windowWidth > 1000) {
        return 4;
      } else if (this.windowWidth <= 1000 && this.windowWidth > 600) {
        return 3;
      } else if (this.windowWidth <= 600 && this.windowWidth > 350) {
        return 2;
      } else {
        return 1;
      }
    },
    getDateLabel(index) {
      return DateFormatters.formatDate(
        DateFormatters.incrementDayBy(
          new Date(this.activeEvent.timespan[0]),
          index - 1
        )
      );
    },
  },
  setup() {
    const store = useStore();
    const message = useMessage();

    const account = store.getters.getAccount;
    const activeEvent = computed(() => {
      return store.getters.eventsLoaded ? store.getters.getActiveEvent : null;
    });
    const numDays = computed(() => {
      return activeEvent.value
        ? DateFormatters.getLength(activeEvent.value.timespan)
        : 0;
    });
    const currDay = computed(() => {
      return activeEvent.value ? activeEvent.value.currentDay : 0;
    });

    const att = computed(() => {
      let ret = [];
      for (let i = 0; i < numDays.value; i++) {
        ret.push(
          account.days.findIndex((idx) => idx === i) != -1
            ? "Attending"
            : "Absent"
        );
      }
      return ret;
    });

    const attendance = ref(att.value);

    const changesMade = ref(false);

    const updateAttendance = () => {
      store
        .dispatch("updateJudgeAttendance", attendance.value)
        .then(() => {
          message.success("Attendance Updated");
          changesMade.value = false;
        })
        .catch(() => message.error("Update Failed"));
    };

    return {
      activeEvent,
      numDays,
      attendance,
      updateAttendance,
      currDay,
      changesMade,
      att,
    };
  },
});
</script>
