<template>
    <n-form :model="formValue" :rules="rules" ref="formRef">
        <n-form-item class="formItemBlock mt-2" label="Judge Name" path="name">
            <n-input class="eventFormInput"
                     v-model:value="formValue.name"
                     placeholder="Judge Name" />
        </n-form-item>

        <n-form-item class="formItemBlock mt-2" label="Judge URL" path="hash">

            <n-input class="eventFormInput"
                     v-model:value="formValue.hashURL"
                     readonly="isReadOnly"
                     placeholder="Judge Hash" />
        </n-form-item>

        <n-form-item class="formItemBlock mt-2" label="Judge Email" path="email">
            <n-input class="eventFormInput"
                     v-model:value="formValue.email"
                     placeholder="Judge Email" />
        </n-form-item>

        <n-form-item class="formItemBlock"
                     label="Preferred Discipline(s)"
                     path="disciplines">
            <n-select :placeholder="$strings.SELECT_DISC"
                      :options="disciplineOptions"
                      v-model:value="formValue.disciplines"
                      multiple
                      filterable />
        </n-form-item>

        <n-form-item class="formItemBlock mt-2"
                     label="Affiliation(s)"
                     path="affiliations">
            <n-dynamic-tags v-model:value="formValue.affiliations" />
        </n-form-item>

        <n-form-item class="formItemBlock mt-2"
                     label="Confirmed Day(s)"
                     path="days">
            <n-dynamic-tags v-model:value="formValue.days" tag-style="string" />

        </n-form-item>


    </n-form>
    <div class="primaryButton">
        <n-button @click="saveForm">Save</n-button>
    </div>
</template>

<script>import { defineComponent, ref, computed } from "vue";
    import { useStore } from "vuex";
    import { useRoute } from "vue-router";

    export default defineComponent({
        props: {
            currJudge: {
                default: null,
                type: Object,
            },
        },
        emits: ["close"],
        setup(props, { emit }) {
            const store = useStore();
            const route = useRoute();

            const isEdit = props.currJudge != null ? true : false;

            const eventObject = computed(() => {
                return store.getters.getEventWithName(route.params.eventName);
            });

            const disciplines = computed(() => {
                return eventObject.value.disciplines;
            });
            const disciplineOptions = computed(() =>
                disciplines.value.map((v) => ({
                    label: v,
                    value: v,
                }))
            );

            const formRef = ref(null);
            const formValue = ref({
                id: isEdit ? props.currJudge.id : null,
                name: isEdit ? props.currJudge.name : "",
                email: isEdit ? props.currJudge.email : "",
                disciplines: isEdit ? props.currJudge.disciplines : [],
                affiliations: isEdit ? props.currJudge.affiliations : [],
                days: isEdit ? props.currJudge.days : [],
                isCheckedIn: isEdit ? props.currJudge.isCheckedIn : false,
                projects: isEdit ? props.currJudge.projects : [],
                notifications: isEdit ? props.currJudge.notifications : [],
                role: isEdit ? props.currJudge.role : "Judge",
                eventId: isEdit ? props.currJudge.eventId : eventObject.value?.id,
                numCompleted: isEdit ? props.currJudge.numCompleted : 0,
                hash: isEdit ? props.currJudge.hash : undefined,
                hashURL: isEdit ? "https://www.ucfsdshowcase.com/judge/" + props.currJudge.hash : undefined,
            });
            const rules = {
                name: {
                    required: true,
                    message: "Please input judge name.",
                    trigger: "blur",
                    validator() {
                        const input = formValue.value.name;
                        return input.length > 0;
                    },
                },
                email: {
                    required: true,
                    message: "Please input judge email.",
                    trigger: "blur",
                    validator() {
                        const input = formValue.value.name;
                        return input.length > 0;
                    },
                },
                disciplines: {
                    required: false,
                },
            };

            const saveForm = () => {
                formRef.value.validate().then(() => {
                    store
                        .dispatch("APIHelper", {
                            name: isEdit ? "editJudge" : "addJudge",
                            params: formValue.value,
                        })
                        .then(() => emit("close"));
                });
            };

            return {
                formRef,
                rules,
                formValue,
                saveForm,
                disciplineOptions,
            };
        },
    });

    console.log();</script>
