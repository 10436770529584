import axios from "axios";

export const disciplinesDefaultState = () => {
  return { disciplines: undefined };
};

export const disciplines = {
  state: disciplinesDefaultState,
  getters: {
    getAllDisciplines: (state) => {
      return state.disciplines;
    },
    getDefaultDisciplines: (state) => {
      return state.disciplines.filter((disc) => disc.isDefault);
    },
    disciplinesLoaded: (state) => {
      return !!state.disciplines;
    },
    getDisciplineByName: (state) => (name) => {
      return state.disciplines.filter((disc) => disc.name == name);
    },
  },
  mutations: {
    setDisciplines(state, disc) {
      state.disciplines = disc;
    },
    addDiscipline(state, disc) {
      state.disciplines.push(disc);
    },
    deleteDiscipline(state, disc) {
      state.disciplines = state.disciplines.filter((d) => d.id != disc.id);
    },
    updateDefaultDisciplines(state, { disc, isDefault }) {
      const index = state.disciplines.findIndex((d) => d.id == disc.id);
      state.disciplines[index].isDefault = isDefault;
    },
  },
  actions: {
    async fetchAllDisciplines(context) {
      await axios.get("disciplines").then((response) => {
        context.commit(
          "setDisciplines",
          response.data.map((d) => ({ ...d, name: d.id }))
        );
      });
    },
    async addDiscipline(context, name) {
      // Discipline payload
      const disc = { id: name, isDefault: false };

      await axios.post("disciplines", disc).then((response) => {
        let newDisc = response.data;
        newDisc.name = newDisc.id;
        context.commit("addDiscipline", newDisc);
      });
    },
    async deleteDiscipline(context, disc) {
      // Send the id as an object and contain this object within data
      const id = disc.id;

      // Delete takes the payload within a data object
      await axios.delete("disciplines/", { data: { id } }).then(() => {
        context.commit("deleteDiscipline", disc);
      });
    },
    async updateDisciplines(context, names) {
      let oldNames = [];
      await context.state.disciplines.forEach((disc) => {
        oldNames.push(disc.name);
        if (!names.includes(disc.name))
          context.dispatch("deleteDiscipline", disc);
      });

      await names.forEach((name) => {
        if (!oldNames.includes(name)) context.dispatch("addDiscipline", name);
      });
    },
    async updateDefaultDisciplines(context, names) {
      await context.state.disciplines.forEach((disc) => {
        // Skip unchanged discipline states
        if (names.includes(disc.name) == disc.isDefault) return;

        // New state
        const isDefault = names.includes(disc.name);
        const op = isDefault ? "activate" : "deactivate";

        // Send the id as an object
        const id = disc.id;

        // Send POST request for specific state change
        axios.put(`disciplines/${op}`, { id }).then(() =>
          context.commit("updateDefaultDisciplines", {
            disc: disc,
            isDefault: isDefault,
          })
        );
      });
    },
  },
};
