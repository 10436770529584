<template>
  <div v-if="allIsLoaded">
    <n-card
      class="adminDashboardCard"
      :bordered="false"
      title="Judge Management"
      style="margin-bottom: 5px"
    >
      <template #header-extra>
        <div class="primaryButton">
          <n-button
            @click="showNotifModal = true"
            :class="[isMobileView() ? 'hidden' : 'visible']"
          >
            Send Notifications
          </n-button>
        </div>
      </template>
      <div :class="[isMobileView() ? 'hidden' : 'visible']">
        <judge-card-list @send="didClickNotification" />
      </div>
      <div :class="[isMobileView() ? 'visible' : 'hidden']">
        <ManageOnDesktop contentDescription="Judge Management" />
      </div>
    </n-card>
    <div class="primaryModal">
      <n-modal
        :class="[isMobileView() ? 'popoverModalMobile' : 'popoverModal']"
        v-model:show="showNotifModal"
        preset="card"
        title="Send Notifications"
        @after-leave="recipientId = null"
      >
        <notification-modal
          @close="showNotifModal = false"
          :recipient-id="recipientId"
        />
      </n-modal>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import NotificationModal from "@/components/admin-day/NotificationModal.vue";
import JudgeCardList from "@/components/admin-day/JudgeCardList.vue";
import { useWindowSize } from "vue-window-size";
import ManageOnDesktop from "@/components/ManageOnDesktop.vue";

export default defineComponent({
  components: {
    "notification-modal": NotificationModal,
    "judge-card-list": JudgeCardList,
    ManageOnDesktop,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      judgeLoadComplete: ref(false),
      ballotsFetched: ref(false),
      recipientId: ref(null),
      showNotifModal: ref(false),
      windowWidth: width,
      windowHeight: height,
    };
  },
  methods: {
    isMobileView() {
      if (this.windowWidth <= 720) {
        return true;
      } else {
        return false;
      }
    },
    didClickNotification(recipientId) {
      this.recipientId = recipientId;
      this.showNotifModal = true;
    },
  },
  computed: {
    allIsLoaded() {
      return (
        this.ballotsFetched &&
        this.judgeLoadComplete &&
        this.$store.getters.projectsLoaded
      );
    },
  },
  async created() {
    if (!this.eventsLoaded) {
      this.$store
        .dispatch("APIHelper", { name: "fetchActiveEvent" })
        .then(() => {
          this.$store.dispatch("APIHelper", { name: "fetchAllRoundProjects" });
          this.$store
            .dispatch("APIHelper", { name: "fetchAllBallotsForRound" })
            .then(() => (this.ballotsFetched = true));
        });
    } else {
      this.$store.dispatch("APIHelper", { name: "fetchAllRoundProjects" });
      this.$store
        .dispatch("APIHelper", { name: "fetchAllBallotsForRound" })
        .then(() => (this.ballotsFetched = true));
    }

    this.$store
      .dispatch("APIHelper", { name: "fetchAllJudgesOfActive" })
      .then(() => (this.judgeLoadComplete = true));
  },
});
</script>
