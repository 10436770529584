<template>
  <div class="settings" v-if="allIsLoaded">
    <n-layout has-sider>
      <n-layout-sider
        class="settingsSidebar"
        collapse-mode="transform"
        :collapsed-width="15"
        show-trigger="arrow-circle"
        content-style="padding: 24px;"
        bordered
        @collapse="collapsed = true"
        @expand="collapsed = false"
      >
        <n-menu v-model:value="menuSelection" :options="menuOptions" />
      </n-layout-sider>
      <n-layout-content
        v-if="(isMobileView && collapsed) || isMobileView == false"
        content-style="padding: 24px;"
      >
        <h1 class="settingsHeader">Settings</h1>
        <profile v-show="menuSelection == 'profile'" />
        <results v-show="menuSelection == 'results'" />
        <account v-show="menuSelection == 'account'" />
        <event v-show="menuSelection == 'event'" />
      </n-layout-content>
      <n-layout-content v-else>
        <div class="settingsHiddenLayout">
          <h1>Close menu to see page.</h1>
        </div>
      </n-layout-content>
    </n-layout>
  </div>
</template>

<script>
import { ref, defineComponent, getCurrentInstance } from "vue";
import { useWindowSize } from "vue-window-size";
import SettingsProfile from "@/components/admin-main/SettingsProfile.vue";
import SettingsAccount from "@/components/admin-main/SettingsAccount.vue";
import SettingsEvent from "@/components/admin-main/SettingsEvent.vue";
import SettingsPostResults from "@/components/admin-main/SettingsPostResults.vue";
import {
  UserIcon,
  SearchIcon,
  ViewGridIcon,
  PlusIcon,
} from "@heroicons/vue/outline";

export default defineComponent({
  isMobileView() {
      if (this.windowWidth <= 650) {
        return true;
      } else {
        return false;
      }
    },

  data() {
    const { width, height } = useWindowSize();
    return {
      resultsFetched: false,
      windowWidth: width,
      windowHeight: height,
      collapsed: ref(this.isMobileView),
    };
  },
  components: {
    profile: SettingsProfile,
    results: SettingsPostResults,
    account: SettingsAccount,
    event: SettingsEvent,
    UserIcon,
    SearchIcon,
    ViewGridIcon,
    PlusIcon,
  },
  computed: {
    allIsLoaded() {
      return (
        this.$store.getters.disciplinesLoaded &&
        this.$store.getters.instructorsLoaded &&
        this.resultsFetched
      );
    }
  },
  async created() {
    if (!this.$store.getters.disciplinesLoaded)
      this.$store.dispatch("APIHelper", { name: "fetchAllDisciplines" });
    if (!this.$store.getters.instructorsLoaded)
      this.$store.dispatch("APIHelper", { name: "fetchAllInstructors" });
    this.$store
      .dispatch("APIHelper", { name: "fetchAllResults" })
      .then(() => (this.resultsFetched = true));
  },
  setup() {
    const strings =
      getCurrentInstance().appContext.config.globalProperties.$strings;

    return {
      menuSelection: ref("profile"),
      menuOptions: [
        {
          icon: UserIcon,
          label: strings.PROFILE_HEADER,
          key: "profile",
        },
        {
          icon: PlusIcon,
          label: "Post Results",
          key: "results",
        },
        {
          icon: SearchIcon,
          label: strings.ACCOUNT_HEADER,
          key: "account",
        },
        {
          icon: ViewGridIcon,
          label: strings.EVENT_HEADER,
          key: "event",
        },
      ],
    };
  },
});
</script>
