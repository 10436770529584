<template>
  <div class="sticky top-0 z-40 filter drop-shadow">
    <UCFNavBar />
    <div v-if="$route.meta.dir == 'Main'">
        <NavBar>
            <NavBarItem navBarItemName="Home" pathName="MainHome" />
            <!-- <NavBarItem navBarItemName="Results" pathName="MainResults" /> -->
            <NavBarItem navBarItemName="Judge" pathName="MainJudge" />
          </NavBar>
        </div>
        <div v-else-if="$route.meta.dir == 'MainAdmin'">
          <NavBar
            :back="{
              name: 'MainHome',
            }"
          >
            <NavBarItem navBarItemName="Dashboard" pathName="MainAdminDashboard" />
            <NavBarItem navBarItemName="Settings" pathName="MainAdminSettings" />
          </NavBar>
        </div>
        <div v-else-if="$route.meta.dir == 'EventAdmin'">
          <NavBar
            :back="{
              name: 'MainAdminDashboard',
            }"
          >
            <NavBarItem
              navBarItemName="Dashboard"
              pathName="EventAdminDashboard"
              pathParams="{ eventName: $route.params.eventName }"
            />
            <NavBarItem
              navBarItemName="Judges"
              pathName="EventAdminJudges"
              pathParams="{ eventName: $route.params.eventName }"
            />
            <NavBarItem
              navBarItemName="Projects"
              pathName="EventAdminProjects"
              pathParams="{ eventName: $route.params.eventName }"
            />
          </NavBar>
        </div>
        <div v-else-if="$route.meta.dir == 'DayAdmin'">
          <NavBar
            :back="{
              name: 'EventAdminDashboard',
              params: { eventName: $route.params.eventName },
            }"
          >
            <NavBarItem
              navBarItemName="Dashboard"
              pathName="DayAdminDashboard"
              pathParams="{
                  eventName: $route.params.eventName,
                  dayIndex: $route.params.dayIndex,
                }"
            />
            <NavBarItem
              navBarItemName="Judges"
              pathName="DayAdminJudges"
              pathParams="{
                  eventName: $route.params.eventName,
                  dayIndex: $route.params.dayIndex,
                }"
            />
          </NavBar>
        </div>
        <div v-else-if="$route.meta.dir == 'Instructor'">
          <NavBar>
            <NavBarItem navBarItemName="Dashboard" pathName="InstructorDashboard" />
            <NavBarItem navBarItemName="Settings" pathName="InstructorSettings" />
          </NavBar>
        </div>
        <div v-else-if="$route.meta.dir == 'Judge'">
          <NavBar>
            <NavBarItem navBarItemName="Dashboard" pathName="JudgeDashboard" />
            <NavBarItem navBarItemName="Projects" pathName="JudgeProjects" />
            <NavBarItem navBarItemName="Rules" pathName="JudgeRules" />
            <NavBarItem navBarItemName="Settings" pathName="JudgeSettings" />
          </NavBar>
        </div>
        <div v-else>
          <NavBar />
        </div>
      </div>
    </template>

    <script>
    import NavBarItem from "./NavBarItem.vue";
    import NavBar from "./NavBar.vue";
    import UCFNavBar from "./UCFNavBar.vue";
    import { defineComponent } from "vue";

    export default defineComponent({
      name: "SdShowCaseNav",
      components: {
        NavBarItem,
        NavBar,
        UCFNavBar,
      },
    });
    </script>

    <style></style>
