import axios from "axios";

export const projectsDefaultState = () => {
  return { projects: undefined };
};

export const projects = {
  state: projectsDefaultState,
  getters: {
    getAllProjects: (state) => {
      return state.projects;
    },
    getProjectCount: (state) => {
      return state.projects?.length;
    },
    getAllProjectsOfRound: (state) => (round) => {
      return state.projects.filter((p) => p.round == round);
    },
    getProjectsAssignedToMe: (state) => {
      return state.projects.filter((p) => p["ballots"] && p["ballots"].length);
    },
    getProjectWithId: (state) => (id) => {
      const index = state.projects.findIndex((p) => p.id == id);
      return state.projects[index];
    },
    projectsLoaded: (state) => {
      return !!state.projects;
    },
  },
  mutations: {
    setProjects(state, projects) {
      state.projects = projects.map((p) => ({
        ballots: p.ballots ?? [],
        ...p,
      }));
    },
    addProject(state, project) {
      state.projects.push(project);
    },
    deleteProject(state, id) {
      state.projects = state.projects.filter((p) => p.id != id);
    },
    editProject(state, project) {
      if (!project.id) project.id = project.projectId;
      const index = state.projects.findIndex((p) => p.id == project.id);
      state.projects[index] = project;
    },
    addBallotMutation(state, ballot) {
      // Proceed if provided ballot object
      if (!ballot) return;

      // Map projects
      const allProjects = state.projects;
      const allProjectsMap = new Map(
        // map [projectId => projectObject]
        allProjects.map((p) => [p.id, p])
      );

      // Ensure project in allProjects map
      if (!allProjectsMap.has(ballot.projectId)) return;

      // Overwrite particular project to include new ballot
      const proj = allProjectsMap.get(ballot.projectId);
      proj["ballots"] = [ballot];
    },
  },
  actions: {
    async fetchAllProjects(context, eventName) {
      const eventId = context.getters.getEventWithName(eventName)?.id;
      await axios.get(`projects/${eventId}`).then((response) => {
        context.commit("setProjects", response.data);
      });
    },
    async fetchAllRoundProjects(context) {
      const roundId = context.getters.getActiveEvent.currentRound;
      await axios
        .get(`events/projects/round/${roundId}`)
        .then((response) => context.commit("setProjects", response.data));
    },
    async addProject(context, project) {
      project.ballots = [];
      await axios.post("projects", project).then((response) => {
        context.commit("addProject", response.data);
        context.commit("setProjectCount", {
          eventId: project.eventId,
          count: context.getters.getProjectCount,
        });
      });
    },
    async editProject(context, project) {
      await axios
        .put(`projects/`, project)
        .then((response) => context.commit("editProject", response.data));
    },
    async deleteProject(context, id) {
      await axios.delete(`projects/${id}`).then(() => {
        context.commit("setProjectCount", {
          eventId: context.getters.getProjectWithId(id).eventId,
          count: context.getters.getProjectCount - 1,
        });
        context.commit("deleteProject", id);
      });
    },
    // Get all of judges projects
    async fetchAllMyProjects(context) {
      // Get all MY ballots and CURRENT ROUND projects
      axios.get("ballots").then((response) => {
        const allProjects = response.data.projects;
        const allProjectsMap = new Map(
          // map [projectId => projectObject]
          allProjects.map((p) => [p.id, p])
        );

        const myBallots = response.data.ballots;

        // Associate ballots to projects
        myBallots.forEach((b) => {
          // Ensure Project with id exists
          if (allProjectsMap.has(b.projectId)) {
            // Separte ballot information
            // eslint-disable-next-line no-unused-vars
            let { project, ...ballot } = b;

            // Append ballot information to project
            const proj = allProjectsMap.get(b.projectId);
            proj["ballots"] = [ballot];
          }
        });

        // After appending judge's ballots
        // to his appropriate projects
        // Commit all projets to state
        context.commit("setProjects", allProjects);
      });
    },

    // Save Ballot of judge
    async saveBallot(context, { ballot }) {
      await axios.put("ballots/", ballot).then((response) => {
        // Add updated ballot to the fitting project
        context.commit("addBallotMutation", response.data);
      });
    },

    // Opt in to ballot
    async addBallotForProject(context, pid) {
      // API:
      await axios.post(`ballots/${pid}`).then((response) => {
        // Add ballot to the fitting project
        context.commit("addBallotMutation", response.data);
      });
    },
  },
};
