<template>
  <n-grid :cols="isMobileView()">
    <n-gi>
      <n-input
        v-model:value="filterValue"
        placeholder="Search Judges by Name"
        @update:value="filterJudges"
      />
    </n-gi>
    <n-gi
      :class="[
        isSmallerView()
          ? 'advancedClearFilterBlockMobile'
          : 'advancedClearFilterBlock',
      ]"
    >
      <div className="primaryButton">
        <n-button @click="showFilterModal = true"> Advanced Filter </n-button>
        <span @click="clearFilter" className="clearFilterSearch">
          Clear Filter
        </span>
      </div>
    </n-gi>
  </n-grid>
  <p className="ballotFilterResultsShown">
    {{ judgeListFiltered.length }} Results Shown
  </p>
  <div v-if="judgeListFiltered?.length > 0">
    <div v-for="judge in paginatedJudgeList" :key="judge.id">
      <judge-card @send="didClickNotification" :judgeObject="judge" />
    </div>
    <div class="grid justify-items-center mt-4">
      <n-pagination
        v-if="getPageCount > 1"
        v-model:page="page"
        :page-count="getPageCount"
      />
    </div>
  </div>
  <div v-else className="ballotFilterResultsShown">
    There are no judges today that match this description.
  </div>
  <div class="primaryModal">
    <n-modal
      :class="[isMobileView() ? 'popoverModalMobile' : 'popoverModal']"
      v-model:show="showFilterModal"
      preset="card"
      title="Advanced Filter"
    >
      <n-input
        v-model:value="filterValue"
        placeholder="Search Judges by Name"
      />
      <div className="popoverModalBody">
        <p className="advancedFilterBodyHeader">Sort By</p>
        <n-radio-group v-model:value="sortBy">
          <n-radio value="default">Default</n-radio>
          <n-radio value="alpha">Alphabetical Order</n-radio>
          <n-radio value="mostSubmitted">Most Submitted Ballots</n-radio>
          <n-radio value="leastSubmitted">Least Submitted Ballots</n-radio>
          <n-radio value="mostAssigned">Most Assigned Ballots</n-radio>
          <n-radio value="leastAssigned">Least Assigned Ballots</n-radio>
        </n-radio-group>
        <p className="advancedFilterBodyHeader">View by Disciplines</p>
        <n-checkbox-group v-model:value="disc">
          <n-grid :cols="3">
            <n-gi v-for="d in disciplines" :key="d">
              <n-checkbox :value="d" :label="d" />
            </n-gi>
          </n-grid>
        </n-checkbox-group>
        <p className="advancedFilterBodyHeader">Filter by Ballots</p>
        <n-checkbox-group v-model:value="numBallots">
          <n-checkbox value="noneSubmitted">No Ballots Submitted</n-checkbox>
          <n-checkbox value="uptothreeSubmitted">
            1 to 3 Ballots Submitted
          </n-checkbox>
          <n-checkbox value="morethanthreeSubmitted">
            More than 3 Ballots Submitted
          </n-checkbox>
          <n-checkbox value="noneAssigned">No Ballots Assigned</n-checkbox>
          <n-checkbox value="uptothreeAssigned">
            1 to 3 Ballots Assigned
          </n-checkbox>
          <n-checkbox value="morethanthreeAssigned">
            More than 3 Ballots Assigned
          </n-checkbox>
        </n-checkbox-group>
      </div>
      <div class="primaryButton advancedFilterApplyButton">
        <n-button @click="filterJudges"> Apply </n-button>
      </div>
    </n-modal>
  </div>
</template>

<script>
import { XCircleIcon } from "@heroicons/vue/outline";
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import JudgeCard from "./JudgeCard.vue";
import { useWindowSize } from "vue-window-size";

const NUM_PER_PAGE = 5;

export default defineComponent({
  components: {
    "judge-card": JudgeCard,
    XCircleIcon,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  emits: ["send"],
  methods: {
    didClickNotification(recipientId) {
      this.$emit("send", recipientId);
    },
    isSmallerView() {
      if (this.windowWidth <= 1000) {
        return true;
      } else {
        return false;
      }
    },
    isMobileView() {
      if (this.windowWidth <= 1000) {
        return 1;
      } else {
        return 2;
      }
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const judgeList = computed(() => {
      return store.getters.getJudgesOnDay(parseInt(route.params.dayIndex));
    });

    const filterValue = ref("");
    const disc = ref(null);
    const sortBy = ref("default");
    const numBallots = ref(null);

    const judgeListFiltered = computed(() => {
      const ballotCount = {},
        submittedCount = {};
      const value = filterValue.value?.toLowerCase();

      let ret = judgeList.value?.filter((j) => {
        ballotCount[j.id] = 0;
        submittedCount[j.id] = 0;

        const ballots = store.getters.getBallotsByJudge(j.id);
        ballots.forEach((b) => {
          ballotCount[j.id]++;
          b.completed ? submittedCount[j.id]++ : submittedCount[j.id];
        });

        let inputFlag = j.name.toLowerCase().includes(value),
          discFlag = false;

        j.disciplines.forEach((d) => {
          if (disc.value?.includes(d)) discFlag = true;
        });

        const isAdvanced = disc.value && disc.value.length > 0;

        return isAdvanced ? discFlag && inputFlag : inputFlag;
      });

      ret?.sort((a, b) => {
        if (sortBy.value == "alpha") {
          return a.name < b.name ? -1 : 1;
        } else if (sortBy.value == "mostSubmitted") {
          return submittedCount[b.id] - submittedCount[a.id];
        } else if (sortBy.value == "leastSubmitted") {
          return submittedCount[a.id] - submittedCount[b.id];
        } else if (sortBy.value == "mostAssigned") {
          return ballotCount[b.id] - ballotCount[a.id];
        } else if (sortBy.value == "leastAssigned") {
          return ballotCount[a.id] - ballotCount[b.id];
        } else {
          return a.id - b.id;
        }
      });

      if (numBallots.value && numBallots.value.length > 0) {
        ret = ret?.filter((p) => {
          let flag = false;
          const val = numBallots.value,
            subCount = submittedCount[p.id],
            assCount = ballotCount[p.id];

          if (val.includes("noneSubmitted")) flag |= subCount == 0;
          if (val.includes("uptothreeSubmitted"))
            flag |= subCount > 0 && subCount < 3;
          if (val.includes("morethanthreeSubmitted")) flag |= subCount >= 3;
          if (val.includes("noneAssigned")) flag |= assCount == 0;
          if (val.includes("uptothreeAssigned"))
            flag |= assCount > 1 && assCount < 3;
          if (val.includes("morethanthreeAssigned")) flag |= assCount >= 3;

          return flag;
        });
      }

      return ret;
    });

    const disciplines = computed(() => {
      return store.getters.getActiveEvent.disciplines;
    });

    const showFilterModal = ref(false);

    const resetPageValue = () => {
      page.value = 1;
    };

    const filterJudges = () => {
      showFilterModal.value = false;
      resetPageValue();
    };

    const clearFilter = () => {
      filterValue.value = "";
      sortBy.value = "default";
      disc.value = null;
      numBallots.value = null;
      resetPageValue();
    };

    const page = ref(1);
    const paginatedJudgeList = computed(() => {
      if (judgeListFiltered.value != null)
        return [...judgeListFiltered.value].slice(
          (page.value - 1) * NUM_PER_PAGE,
          page.value * NUM_PER_PAGE
        );
      else return null;
    });
    const getPageCount = computed(() =>
      judgeListFiltered.value != null
        ? parseInt(
            (judgeListFiltered.value.length + NUM_PER_PAGE - 1) / NUM_PER_PAGE
          )
        : 0
    );

    return {
      paginatedJudgeList,
      getPageCount,
      page,
      judgeListFiltered,
      filterValue,
      sortBy,
      disc,
      numBallots,
      filterJudges,
      clearFilter,
      showFilterModal,
      disciplines,
    };
  },
});
</script>
