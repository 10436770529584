import axios from "axios";

export const instructorsDefaultState = () => {
  return { instructors: undefined };
};

export const instructors = {
  state: instructorsDefaultState,
  getters: {
    getAllInstructors: (state) => {
      return state.instructors;
    },
    instructorsLoaded: (state) => {
      return !!state.instructors;
    },
  },
  mutations: {
    setInstructors(state, instructors) {
      state.instructors = instructors;
    },
    editInstructor(state, inst) {
      const index = state.instructors.findIndex((v) => v.id == inst.id);
      state.instructors[index] = inst;
    },
  },
  actions: {
    async fetchAllInstructors(context) {
      await axios.get("account/instructors").then((response) => {
        const instructors = response.data;
        context.commit("setInstructors", instructors);
      });
    },
    async editInstructor(context, inst) {
      await axios.put("account/instructors", inst).then((response) => {
        context.commit("editInstructor", response.data);
      });
    },
  },
};
