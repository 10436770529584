<template>
  <div>{{ account }}</div>
  <div>
    <p class="settingsSubheader">{{ $strings.PROFILE_HEADER }}</p>
    <p class="settingsDescription">{{ $strings.PROFILE_DESCRIPTION }}</p>
    <div class="settingsBody">
      <n-form :model="personalInfo" ref="formRef" label-placement="top">
        <n-grid :cols="getResponsiveCols()" :span="24" :x-gap="24">
          <n-form-item-gi
            :span="12"
            :label="$strings.FIRST_NAME"
            path="firstName"
          >
            <n-input
              :placeholder="$strings.FIRST_NAME"
              v-model:value="personalInfo.firstName"
              @update:value="updateAccount()"
            />
          </n-form-item-gi>
          <n-form-item-gi
            :span="12"
            :label="$strings.LAST_NAME"
            path="lastName"
          >
            <n-input
              :placeholder="$strings.LAST_NAME"
              v-model:value="personalInfo.lastName"
              @update:value="updateAccount()"
            />
          </n-form-item-gi>
          <n-form-item-gi :span="12" :label="$strings.EMAIL" path="email">
            <n-input :value="personalInfo.email" disabled />
          </n-form-item-gi>
          <n-form-item-gi :span="12" :label="$strings.STATUS">
            <n-input value="Instructor" disabled />
          </n-form-item-gi>
        </n-grid>
        <n-form-item label="Disciplines">
          <n-tag
            v-for="disc in personalInfo.disciplines"
            :key="disc"
            class="mr-2"
          >
            {{ disc }}
          </n-tag>
        </n-form-item>
      </n-form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useMessage } from "naive-ui";
import { PencilAltIcon } from "@heroicons/vue/outline";
import { useWindowSize } from "vue-window-size";

export default defineComponent({
  components: {
    PencilAltIcon,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      resultsFetched: false,
      windowWidth: width,
      windowHeight: height,
      collapsed: ref(this.isMobileView),
    };
  },
  methods: {
    getResponsiveCols() {
      if (this.windowWidth <= 650) {
        return 1;
      } else {
        return 2;
      }
    },
  },
  setup() {
    const store = useStore();
    const message = useMessage();

    const account = store.getters.getAccount;

    const name = account.name.split(" ");
    const firstName = name[0];
    const lastName = name.slice(1).join(" ");
    const formRef = ref(null);

    const personalInfo = ref({
      firstName: firstName,
      lastName: lastName,
      email: account.email,
      disciplines: account.disciplines,
    });

    const changesMade = ref(false);

    const updateAccount = () => {
      store
        .dispatch("updateAccountInfo", {
          firstName: personalInfo.value.firstName,
          lastName: personalInfo.value.lastName,
        })
        .then(() => {
          changesMade.value = false;
        })
        .catch(() => message.error("Update Failed"));
    };

    return {
      formRef,
      updateAccount,
      personalInfo,
      changesMade,
    };
  },
});
</script>
