<template>
  <div v-if="allIsLoaded">
    <n-card
      class="adminDashboardCard"
      :bordered="false"
      title="Dashboard"
      style="margin-bottom: 5px"
    >
      <div :class="[isMobileView() ? 'hidden' : 'visible']">
        <p className="eventDayTracker">
          Day {{ dayIndex }}
          <span className="eventDayDate">
            {{ getDateLabel(dayIndex) }}
          </span>
        </p>
        <p className="eventDayEventName">{{ eventObject.name }}</p>
        <n-grid :cols="getResponsiveCols()">
          <n-gi>
            <day-management />
          </n-gi>
          <n-gi>
            <live-results />
          </n-gi>
        </n-grid>
        <div :class="[isMobileViewBallotManagement() ? 'visible' : 'hidden']">
          <ManageOnDesktop contentDescription="Ballot Management" />
        </div>
        <div :class="[isMobileViewBallotManagement() ? 'hidden' : 'visible']">
          <ballot-management
            :key="$store.getters.getAllJudges"
            @send="didClickNotification"
          />
        </div>
      </div>
      <div :class="[isMobileView() ? 'visible' : 'hidden']">
        <ManageOnDesktop
          contentDescription="Day Management, Live Results, and Ballot Management"
        />
      </div>
    </n-card>
    <div class="primaryModal">
      <n-modal
        :class="[isMobileView() ? 'popoverModalMobile' : 'popoverModal']"
        v-model:show="showNotifModal"
        preset="card"
        title="Send Notifications"
        @after-leave="recipientId = null"
      >
        <notification-modal
          @close="showNotifModal = false"
          :recipient-id="recipientId"
        />
      </n-modal>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import DayManagement from "@/components/admin-day/DayManagement.vue";
import LiveResults from "@/components/admin-day/LiveResults.vue";
import BallotCardList from "@/components/admin-day/BallotCardList.vue";
import NotificationModal from "@/components/admin-day/NotificationModal.vue";
import { useWindowSize } from "vue-window-size";
import DateFormatters from "@/helpers/DateFormatters.js";
import ManageOnDesktop from "@/components/ManageOnDesktop.vue";

export default defineComponent({
  components: {
    "day-management": DayManagement,
    "live-results": LiveResults,
    "ballot-management": BallotCardList,
    "notification-modal": NotificationModal,
    ManageOnDesktop,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      dayIndex: parseInt(this.$route.params.dayIndex) + 1,
      ballotsFetched: ref(false),
      recipientId: ref(null),
      showNotifModal: ref(false),
      windowWidth: width,
      windowHeight: height,
    };
  },
  computed: {
    eventObject() {
      return this.$store.getters.getActiveEvent;
    },
    allIsLoaded() {
      return (
        this.eventsLoaded &&
        this.ballotsFetched &&
        this.$store.getters.judgesLoaded &&
        this.$store.getters.projectsLoaded
      );
    },
    needToLoadResults() {
      return (
        this.eventObject.currentRound != 0 &&
        (!this.$store.getters.resultsLoaded ||
          !this.results.rounds[this.activeEvent.currentRound - 1])
      );
    },
    eventsLoaded() {
      return this.$store.getters.eventsLoaded;
    },
    results() {
      return this.$store.getters.getResultsForEvent(this.eventObject.id);
    },
  },
  methods: {
    getDateLabel(index) {
      return DateFormatters.formatDate(
        DateFormatters.incrementDayBy(
          new Date(this.eventObject.timespan[0]),
          index - 1
        )
      );
    },
    isMobileView() {
      if (this.windowWidth <= 720) {
        return true;
      } else {
        return false;
      }
    },
    isMobileViewBallotManagement() {
      if (this.windowWidth <= 900) {
        return true;
      } else {
        return false;
      }
    },
    getResponsiveCols() {
      if (this.windowWidth <= 1150) {
        return 1;
      } else {
        return 2;
      }
    },
    didClickNotification(recipientId) {
      this.recipientId = recipientId;
      this.showNotifModal = true;
    },
  },
  async created() {
    if (!this.eventsLoaded) {
      this.$store
        .dispatch("APIHelper", { name: "fetchActiveEvent" })
        .then(() => {
          this.$store.dispatch("APIHelper", {
            name: "fetchAllProjects",
            params: this.eventObject.name,
          });
          this.$store
            .dispatch("APIHelper", { name: "fetchAllBallotsForRound" })
            .then(() => (this.ballotsFetched = true));
          if (this.needToLoadResults)
            this.$store.dispatch("APIHelper", { name: "fetchAllResults" });
        });
    } else {
      this.$store.dispatch("APIHelper", {
        name: "fetchAllProjects",
        params: this.eventObject.name,
      });
      this.$store
        .dispatch("APIHelper", { name: "fetchAllBallotsForRound" })
        .then(() => (this.ballotsFetched = true));
      if (this.needToLoadResults)
        this.$store.dispatch("APIHelper", { name: "fetchAllResults" });
    }

    this.$store.dispatch("APIHelper", { name: "fetchAllJudgesOfActive" });
  },
});
</script>
