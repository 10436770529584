import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import "./assets/tailwind.css";
import { store } from "./store/index.js";
import strings from "./assets/strings.js";
import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  create,
  NCard,
  NTabs,
  NTabPane,
  NLayout,
  NButton,
  NIcon,
  NModal,
  NForm,
  NFormItem,
  NInput,
  NDatePicker,
  NSelect,
  NRadioGroup,
  NRadio,
  NGrid,
  NFormItemGi,
  NDataTable,
  NMessageProvider,
  NGridItem,
  NDynamicTags,
  NLayoutSider,
  NMenu,
  NLayoutContent,
  NDialogProvider,
  NPagination,
  NDivider,
  NSkeleton,
  NRate,
  NBackTop,
  NSteps,
  NStep,
  NLi,
  NCollapse,
  NCollapseItem,
  NTag,
  NSpace,
  NUpload,
  NUploadDragger,
  NCheckboxGroup,
  NCheckbox,
  NInputNumber,
  NAutoComplete,
  NPopover,
} from "naive-ui";

const naive = create({
  components: [
    NCard,
    NTabs,
    NTabPane,
    NLayout,
    NButton,
    NIcon,
    NModal,
    NForm,
    NFormItem,
    NInput,
    NDatePicker,
    NSelect,
    NRadioGroup,
    NRadio,
    NGrid,
    NFormItemGi,
    NDataTable,
    NMessageProvider,
    NGridItem,
    NDynamicTags,
    NLayoutSider,
    NMenu,
    NLayoutContent,
    NDialogProvider,
    NPagination,
    NDivider,
    NSkeleton,
    NRate,
    NBackTop,
    NSteps,
    NStep,
    NLi,
    NCollapse,
    NCollapseItem,
    NTag,
    NSpace,
    NUpload,
    NUploadDragger,
    NCheckboxGroup,
    NCheckbox,
    NInputNumber,
    NAutoComplete,
    NPopover,
  ],
});

//axios.defaults.baseURL = "https://sdshowcase-api-preprod.azurewebsites.net/api";
axios.defaults.baseURL = "https://sdshowcase-dev.azurewebsites.net/api";
const app = createApp(App);
app.config.globalProperties.$msalInstance = new PublicClientApplication(
  store.getters.getMsalConfig
);
app.config.globalProperties.$strings = strings;
app.use(router);
app.use(naive);
app.use(store);
app.mount("#app");
