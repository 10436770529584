<template>
  <div>
    <p class="settingsSubheader">{{ $strings.EVENT_HEADER }}</p>
    <p class="settingsDescription">{{ $strings.EVENT_DESCRIPTION }}</p>
    <p class="settingsSubheader">{{ $strings.EVENT_DEFAULT_DISC }}</p>
    <p class="settingsDescription">
      {{ $strings.EVENT_DEFAULT_DISC_DESCRIPTION }}
    </p>
    <n-select
      v-model:value="newDefaultDisciplines"
      v-bind:options="disciplineOptions"
      @blur="handleSaveDefaultDisciplines"
      multiple
      filterable
    />
    <p class="settingsSubheader">{{ $strings.EVENT_ALL_DISC }}</p>
    <p class="settingsDescription">{{ $strings.EVENT_ALL_DISC_DESCRIPTION }}</p>
    <n-dynamic-tags
      v-model:value="newDisciplineList"
      @update:value="handleSaveDisciplineList"
    />
  </div>
</template>

<script>
import { ref, defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    const disciplineList = computed(() => {
      return store.getters.getAllDisciplines;
    });
    const defaultDisciplines = computed(() => {
      return store.getters.getDefaultDisciplines;
    });

    const newDefaultDisciplines = ref(
      defaultDisciplines.value.map((v) => v.name)
    );
    const newDisciplineList = ref(disciplineList.value.map((v) => v.name));

    const disciplineOptions = computed(() =>
      newDisciplineList.value.map((v) => ({
        label: v,
        value: v,
      }))
    );

    const updateDOMDefaultDiscList = () => {
      newDefaultDisciplines.value = newDefaultDisciplines.value.filter((d) =>
        newDisciplineList.value.includes(d)
      );
    };

    return {
      newDefaultDisciplines: newDefaultDisciplines,
      newDisciplineList: newDisciplineList,
      disciplineOptions: disciplineOptions,
      handleSaveDefaultDisciplines: () => {
        store.dispatch("updateDefaultDisciplines", newDefaultDisciplines.value);
      },
      handleSaveDisciplineList: () => {
        // Make sure to filter duplicates before saving
        newDisciplineList.value = [...new Set(newDisciplineList.value)];
        store
          .dispatch("updateDisciplines", newDisciplineList.value)
          .then(() => {
            updateDOMDefaultDiscList();
          });
      },
    };
  },
});
</script>
