<template>
  <div>
    <p class="settingsSubheader">Results Management</p>
    <p class="settingsDescription">
      Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
      deserunt mollit anim id est laborum.
    </p>
    <div class="settingsBody" v-if="results?.length > 0">
      <n-checkbox-group v-model:value="values" @update:value="change = true">
        <div v-for="result in results" :key="result">
          <n-checkbox :value="result.id" :label="result.event.name" />
        </div>
      </n-checkbox-group>
      <div class="primaryButton settingsBody">
        <n-button @click="postResults()" :loading="loading" :disabled="!change">
          Update Posted Results
        </n-button>
      </div>
    </div>
    <p v-else className="ballotFilterResultsShown">There are no results to post.</p>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();
    const results = store.getters.getAllResults;
    const values = ref(results.filter((r) => r.posted).map((r) => r.id));

    const isPosted = {};
    results.forEach((r) => (isPosted[r.id] = r.posted));

    const loading = ref(false);
    const change = ref(false);

    const postResults = () => {
      loading.value = true;

      values.value.forEach(async (id) => {
        if (!isPosted[id]) await store.dispatch("postResultsWithEventId", id);
      });

      results.forEach(async (r) => {
        if (!values.value.includes(r.id))
          await store.dispatch("unpostResultsWithEventId", r.id);
      });

      loading.value = false;
      change.value = false;
    };

    return {
      values,
      results,
      postResults,
      loading,
      change,
    };
  },
});
</script>
