<template>
  <div class="dashboard">
    <n-card
      class="adminDashboardCard"
      :bordered="false"
      title="Dashboard"
      style="margin-bottom: 5px"
    >
      <template #header-extra>
        <create-event-button :class="[isMobileView() ? 'hidden' : 'visible']" />
      </template>
      <div :class="[isMobileView() ? 'visible' : 'hidden']">
        <ManageOnDesktop contentDescription="Event Creation and Editing" />
      </div>
      <div v-if="eventsLoaded" :class="[isMobileView() ? 'hidden' : 'visible']">
        <n-tabs class="grid justify-items-center" type="line">
          <n-tab-pane name="active" :tab="$strings.ACTIVE_EVENT">
            <event-details-list :tab="$strings.ACTIVE" />
          </n-tab-pane>
          <n-tab-pane name="inactive" :tab="$strings.INACTIVE_EVENTS">
            <event-details-list :tab="$strings.INACTIVE" />
          </n-tab-pane>
          <n-tab-pane name="archived" :tab="$strings.ARCHIVED_EVENTS">
            <event-details-list :tab="$strings.ARCHIVED" />
          </n-tab-pane>
        </n-tabs>
      </div>
    </n-card>
  </div>
</template>

<script>
import EventDetailsList from "@/components/EventDetailsList.vue";
import CreateEventButton from "@/components/CreateEventButton.vue";
import ManageOnDesktop from "@/components/ManageOnDesktop.vue";
import { useWindowSize } from "vue-window-size";

export default {
  name: "AdminMainDashboard",
  components: {
    "event-details-list": EventDetailsList,
    "create-event-button": CreateEventButton,
    ManageOnDesktop,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  methods: {
    isMobileView() {
      if (this.windowWidth <= 600) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    eventsLoaded() {
      return this.$store.getters.eventsLoaded;
    },
  },
  async created() {
    this.$store.dispatch("APIHelper", { name: "fetchAllEvents" });
    if (!this.$store.getters.disciplinesLoaded)
      this.$store.dispatch("APIHelper", { name: "fetchAllDisciplines" });
  },
};
</script>
