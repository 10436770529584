<template>
  <div class="ProjectResultDetails">
    <n-card hoverable class="projectDetailsCard">
      <n-grid :cols="2">
        <n-gi>
          <h1 class="projectDetailsDiscipline">
            {{ getStringValue(projectObject.disciplines) }}
          </h1>
        </n-gi>
      </n-grid>
      <n-divider />
      <n-grid :cols="getResponsiveCols()">
        <n-gi>
          <div class="projectInfo">
            <h1 class="projectDetailsName">{{ projectObject.name }}</h1>
            <h2 class="projectDetailsSponsor">
              {{ getStringValue(projectObject.sponsors) }}
            </h2>
            <p class="projectDetailsDescription" v-if="!isDescLong">
              {{ getWholeDescription }}
            </p>
            <p
              class="projectDetailsDescription"
              v-else-if="showShort"
              @click.stop="showShort ^= true"
            >
              {{ getShortDescription }}
              <span class="showMoreLessText">Click to see more.</span>
            </p>
            <p
              class="projectDetailsDescription"
              v-else
              @click.stop="showShort ^= true"
            >
              {{ getWholeDescription }}
              <span class="showMoreLessText"> Click to see less.</span>
            </p>
            <h2 class="projectDetailsStudentsHeader">Team Members</h2>
            <p class="projectDetailsStudents">
              {{ getStringValue(projectObject.students) }}
            </p>
            <div>
              <h2 class="projectDetailsScore">
                #<span class="judgeScore">{{ projectObject.discRanking }}</span>
                in
                <span class="resultedRankingDiscipline">
                  {{ getDisciplineString }} (#{{
                    projectObject.ranking
                  }}
                  Overall)
                </span>
              </h2>
            </div>
          </div>
        </n-gi>
        <n-gi>
          <iframe
            :class="[
              getResponsiveVideoFrame()
                ? 'projectDetailsVideoMobile'
                : 'projectDetailsVideoDesktop',
            ]"
            :width="vidWidth"
            :height="vidHeight"
            :src="getEmbedLink()"
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
            allowfullscreen
          ></iframe>
        </n-gi>
      </n-grid>
    </n-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useWindowSize } from "vue-window-size";
import getYouTubeID from "get-youtube-id";

let MAX_SHORT = 200;

export default defineComponent({
  props: {
    projectObject: Object,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      vidHeight: 360,
      vidWidth: "auto",
      windowWidth: width,
      windowHeight: height,
      showShort: true,
    };
  },
  computed: {
    getShortDescription() {
      let description = this.projectObject.description.slice(0, MAX_SHORT);
      return description;
    },
    getWholeDescription() {
      let description = this.projectObject.description;
      return description;
    },
    isDescLong() {
      return this.projectObject.description.length > MAX_SHORT;
    },
    getDisciplineString() {
      return (
        (this.projectObject.disciplines.length > 1
          ? "Interdisciplinary: "
          : "") + this.getStringValue(this.projectObject.disciplines)
      );
    },
  },
  methods: {
    getResponsiveVideoFrame() {
      if (this.windowWidth <= 550) {
        return true;
      } else {
        return false;
      }
    },
    getResponsiveCols() {
      if (this.windowWidth <= 1000) {
        return 1;
      } else {
        return 2;
      }
    },
    getEmbedLink() {
      return `https://www.youtube.com/embed/${getYouTubeID(
        this.projectObject.vidLink
      )}`;
    },
    getStringValue(list) {
      let listString = "";
      list.forEach((p) => (listString += p + ", "));
      return listString.slice(0, listString.length - 2);
    },
    handleResize() {
      this.showShort = true;
      if (this.$refs.projectInfo) {
        this.vidWidth = this.$refs.projectInfo.clientWidth;
        this.vidHeight = this.$refs.projectInfo.clientHeight;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
});
</script>
