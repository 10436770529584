<template>
  <div class="EditEventButton primaryButton">
    <n-button @click.stop="showModal = true" :disabled="isDisabled">
      <PencilAltIcon class="buttonIcon" />
      {{ getText }}
    </n-button>
    <div class="primaryModal">
      <n-modal
        :class="[isMobileView() ? 'popoverModalMobile' : 'popoverModal']"
        v-model:show="showModal"
        preset="card"
        :title="$strings.EDIT_EVENT"
      >
        <new-event-form
          :curr-event="eventObject"
          @close="closeModal"
          @name="changedName"
        />
      </n-modal>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import NewEventForm from "./NewEventForm.vue";
import { PencilAltIcon } from "@heroicons/vue/outline";
import { useWindowSize } from "vue-window-size";

export default defineComponent({
  name: "EditEventButton",
  components: {
    "new-event-form": NewEventForm,
    PencilAltIcon,
  },
  emit: ["name"],
  props: {
    eventObject: Object,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      showModal: ref(false),
      windowWidth: width,
      windowHeight: height,
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    changedName(name) {
      this.$emit("name", name);
    },
    isMobileView() {
      if (this.windowWidth <= 650) {
        return true;
      } else {
        return false;
      }
    },
  },

  computed: {
    isDisabled() {
      return (
        this.eventObject.eventOngoing || this.eventObject.status == "Archived"
      );
    },
    getText() {
      return this.eventObject.eventOngoing
        ? "Ongoing Event"
        : this.eventObject.status == "Archived"
        ? "Archived"
        : "Edit Event";
    },
  },
});
</script>
