<template>
  <div class="flex flex-row-reverse">
    <h1
      :class="[isComplete ? 'ballotStatusComplete' : 'ballotStatusIncomplete']"
    >
      {{ text }}
    </h1>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isComplete: Boolean,
  },
  data() {
    return {
      text: this.isComplete ? "Complete" : "Incomplete",
    };
  },
});
</script>
