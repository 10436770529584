<template>
  <div class="judgeWelcomeCard">
    <n-card title="Check In" :bordered="false" class="judgeDashboardCard">
      <h1 class="judgeCheckinSubheader">Select your Affiliation</h1>
      <h2 class="judgeCheckinDescription">
        Select any companies below that you have current or previous affiliation
        with.
      </h2>
      <div class="judgeCheckinBody">
        <n-form-item class="formItemBlock" label="Sponsor / Company Name">
          <n-select
            placeholder="Select Affiliations"
            :options="affiliationOptions"
            v-model:value="affiliations"
            @update:value="emitUpdateForm"
            multiple
            filterable
          />
        </n-form-item>
      </div>
      <div
        :class="[isMobileView() ? 'confirmGridMobile' : 'confirmGridDesktop']"
      >
        <n-grid :cols="1">
          <n-gi>
            <div class="primaryButton">
              <n-button @click="emitNextEvent()"> Continue </n-button>
            </div>
          </n-gi>
          <n-gi>
            <div class="secondaryButton mt-3">
              <n-button @click="emitBackEvent()"> Back </n-button>
            </div>
          </n-gi>
        </n-grid>
      </div>
    </n-card>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useWindowSize } from "vue-window-size";

import { useStore } from "vuex";

export default defineComponent({
  props: {
    formInfo: Object,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  emits: ["update", "next", "back"],
  methods: {
    isMobileView() {
      if (this.windowWidth <= 900) {
        return true;
      } else {
        return false;
      }
    },
    emitNextEvent() {
      this.emitUpdateForm();
      this.$emit("next");
    },
    emitBackEvent() {
      this.$emit("back");
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const affiliationList = computed(() => store.getters.getAllAffiliations);
    const affiliationOptions = affiliationList.value.map((v) => ({
      label: v,
      value: v,
    }));
    const affiliations = ref(props.formInfo.affiliations);

    const emitUpdateForm = () => {
      emit("update", affiliations.value);
    };

    return {
      affiliationOptions,
      affiliations,
      emitUpdateForm,
    };
  },
});
</script>
