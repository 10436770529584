<template>
  <nav
    class="flex items-center bg-black px-1 py-5 justify-between h-8 xs:flex-row"
  >
    <div class="flex flex-row items-center text-white">
      <img :src="UCFLogo" class="h-5 visible sm:invisible ml-4" />
      <p
        class="
          UCFNavbarText
          text-xxs
          invisible
          sm:visible
          text-ucf-gold
          tracking-navbar-wide
          justify-start
        "
      >
        UNIVERSITY OF CENTRAL FLORIDA
      </p>
    </div>
    <div class="text-xxs text-ucf-gold font-bold tracking-navbar-wide mr-10">
      <a
        class="UCFNavbarText text-ucf-gold no-underline visited:no-underline"
        href="https://www.cecs.ucf.edu/SeniorDesignShowcase/"
      >
        {{ $strings.SD_SHOWCASE_HOMEPAGE }}
      </a>
    </div>
  </nav>
</template>

<script>
import UCFLogo from "../assets/images/UCF_logo.png";
import UCFTabLogo from "../assets/images/UCF_tab_logo.png";

export default {
  name: "UCFNavBar",
  data: function () {
    return {
      UCFLogo: UCFLogo,
      UCFTabLogo: UCFTabLogo,
    };
  },
};
</script>
