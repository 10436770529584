<template>
  <div class="CreateEventButton">
    <div class="primaryButton">
      <n-button @click="showModal = true">
        <PlusCircleIcon class="buttonIcon" />
        {{ $strings.CREATE_AN_EVENT }}
      </n-button>
    </div>
    <div class="primaryModal">
      <n-modal
        :class="[isMobileView() ? 'popoverModalMobile' : 'popoverModal']"
        v-model:show="showModal"
        preset="card"
        title="Create an Event"
      >
        <new-event-form @close="closeModal" />
      </n-modal>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import NewEventForm from "./NewEventForm.vue";
import { PlusCircleIcon } from "@heroicons/vue/outline";
import { useWindowSize } from "vue-window-size";

export default defineComponent({
  name: "CreateEventButton",
  components: {
    "new-event-form": NewEventForm,
    PlusCircleIcon,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      showModal: ref(false),
      windowWidth: width,
      windowHeight: height,
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    isMobileView() {
      if (this.windowWidth <= 650) {
        return true;
      } else {
        return false;
      }
    },
  },
});
</script>
