<template>
  <div class="settings">
    <n-layout has-sider>
      <n-layout-sider
        class="settingsSidebar"
        collapse-mode="transform"
        :collapsed-width="15"
        show-trigger="arrow-circle"
        content-style="padding: 24px;"
        bordered
        @collapse="collapsed = true"
        @expand="collapsed = false"
      >
        <n-menu v-model:value="menuSelection" :options="menuOptions" />
      </n-layout-sider>
      <n-layout-content v-if="showContent" content-style="padding: 24px;">
        <h1 class="settingsHeader">Settings</h1>
        <profile v-show="menuSelection == 'profile'" />
      </n-layout-content>
      <n-layout-content v-else>
        <div class="settingsHiddenLayout">
          <h1>Close menu to see page.</h1>
        </div>
      </n-layout-content>
    </n-layout>
  </div>
</template>

<script>
import { ref, defineComponent, getCurrentInstance } from "vue";
import InstructorProfile from "@/components/InstructorProfile.vue";
import { UserIcon } from "@heroicons/vue/outline";
import { useWindowSize } from "vue-window-size";

export default defineComponent({
  components: {
    profile: InstructorProfile,
    UserIcon,
  },
  isMobileView() {
      if (this.windowWidth <= 650) {
        return true;
      } else {
        return false;
      }
    },
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
      collapsed: ref(this.isMobileView),
    };
  },
  computed: {
    showContent() {
      if (this.isMobileView) return this.collapsed;
      return true;
    }
  },
  setup() {
    const strings =
      getCurrentInstance().appContext.config.globalProperties.$strings;

    return {
      menuSelection: ref("profile"),
      menuOptions: [
        {
          icon: UserIcon,
          label: strings.PROFILE_HEADER,
          key: "profile",
        },
      ],
    };
  },
});
</script>
