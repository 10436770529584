<template>
  <div v-for="date in dates" :key="date">
    <p class="instructorText">{{ date }}</p>
    <div v-for="notif in notifDateMap[date]" :key="notif">
      <div :class="[notif.seen ? 'notifCard' : 'notifCardUnseen']">
        <n-card :title="notif.title">
          <template #header-extra>
            {{ getTimestamp(notif) }}
          </template>
          <p>{{ notif.body }}</p>
        </n-card>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  methods: {
    getTimestamp(notif) {
      return new Date(notif.timestamp).toLocaleTimeString(["en-US"], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
  setup() {
    const store = useStore();

    const notifs = computed(() => store.getters.getAllNotifications);
    const notifications = computed(() =>
      [...notifs.value].sort((a, b) => b.timestamp - a.timestamp)
    );

    const getDate = (notif) => {
      return new Date(notif.timestamp).toLocaleDateString(["en-US"], {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    };

    const notifDateMap = computed(() => {
      let map = {};
      notifications.value.forEach((n) =>
        map[getDate(n)] ? map[getDate(n)].push(n) : (map[getDate(n)] = [n])
      );
      return map;
    });

    return {
      notifDateMap,
      dates: computed(() => Object.keys(notifDateMap.value)),
    };
  },
});
</script>
