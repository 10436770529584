<template>
  <div class="results">
    <n-card
      class="mainDashboardCard"
      :bordered="false"
      title="Results From Past Showcases"
      style="margin-bottom: 5px"
    >
      <project-result-details-list v-if="resultsFetched" />
    </n-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ProjectResultDetailsList from "@/components/ProjectResultDetailsList.vue";

export default defineComponent({
  components: {
    "project-result-details-list": ProjectResultDetailsList,
  },
  data() {
    return {
      resultsFetched: false,
    };
  },
  async created() {
    this.$store
      .dispatch("APIHelper", { name: "fetchAllPostedResults" })
      .then(() => (this.resultsFetched = true));
  },
});
</script>
