<template>
    <div class="judge">
        <div class="judgeDefault" v-if="!isJudgeLoggedIn">
            <n-card class="judgeDashboardCard"
                    :bordered="false"
                    title="Welcome, Judge!"
                    style="margin-bottom: 5px">
                <div class="getInvolved">
                    <p class="mainHeader">Looking to get involved?</p>
                    <p class="mainDescription">
                        The CECS Alumni Chapter is always looking for new volunteer members! If you’re interested in learning more about the chapter and how we work with the college, reach out to Francesca Botteri, Assoc. Director for Alumni Engagement and Annual Giving at CECS, <a href="mailto:Francesca.botteri@ucf.edu">Francesca.botteri@ucf.edu</a>.
                        If you did not sign up to be a judge for this showcase and would like to participate, please visit <a href="https://ucfalumni.com/cecsseniordesign">https://ucfalumni.com/cecsseniordesign</a> to sign up.
                    </p>
                </div>
                <!--
                             <div class="getInvolvedDescription">
                                 <p class="mainSubheader">Looking to get involved?</p>
                                 <p class="mainDescription">
                                     The CECS Alumni Chapter is always looking for new volunteer members! If you’re interested in learning more about the chapter and how we work with the college, reach out to Francesca Botteri, Assoc. Director for Alumni Engagement and Annual Giving at CECS, <a href="mailto:Francesca.botteri@ucf.edu">Francesca.botteri@ucf.edu</a>.
                If you did not sign up to be a judge for this showcase and would like to participate, please visit <a href="https://ucfalumni.com/cecsseniordesign">https://ucfalumni.com/cecsseniordesign</a> to sign up.
                                 </p>
                             </div>
                                 -->
                <div class="registeredJudge">
                    <p class="mainHeader">Are you a registered Judge?</p>
                    <div class="registeredJudgeIncomplete" v-if="!hashAcquired">
                        <p class="mainDescription">
                            Retrieve your Judge URL by inputting the email you used to
                            register for the UCF Senior Design Showcase down below.
                        </p>
                        <div :class="[
                isMobileView()
                  ? 'mainJudgeEmailInputMobile'
                  : 'mainJudgeEmailInput',
              ]">
                            <n-auto-complete placeholder="Your Email"
                                             v-model:value="email"
                                             @update:value="ohno = false"
                                             :options="options" />
                            <div class="primaryButton">
                                <n-button @click="getJudgeHash()" :disabled="email.length == 0">
                                    Get Link
                                </n-button>
                            </div>
                        </div>
                        <div v-if="ohno">
                            <p>{{ errorText }}</p>
                        </div>
                    </div>

                    <div v-else>
                        <span className="homepageContactSection">
                            <CheckCircleIcon class="buttonIcon" /> Click below for your unique juding link
                        </span>

                        <div class="primaryButton">
                            <n-button @click="OpenURL()">
                                Start Judging!
                            </n-button>
                        </div>
                    </div>

                </div>
            </n-card>
        </div>
        <div class="judgeReturning" v-else>
            <n-card class="judgeDashboardCard"
                    :bordered="false"
                    title="Welcome, Judge!"
                    style="margin-bottom: 5px">
                <p class="mainSubheader">
                    You are already logged in. Proceed to your dashboard below.
                </p>
                <div class="primaryButton mainDescription">
                    <n-button @click="goToDashboard()" :loading="isLoading">
                        Go To Dashboard
                    </n-button>
                </div>
            </n-card>
        </div>
    </div>
</template>

<script>import { defineComponent, ref, computed } from "vue";
    import { useStore } from "vuex";
    import { CursorClickIcon, CheckCircleIcon } from "@heroicons/vue/outline";
    import { useWindowSize } from "vue-window-size";

    export default defineComponent({
        components: {
            CursorClickIcon,
            CheckCircleIcon,
        },
        data() {
            const { width, height } = useWindowSize();
            return {
                windowWidth: width,
                windowHeight: height,
            };
        },
        computed: {
            getJudgeIdFromLocal() {
                return this.$store.getters.getJudgeHash;
            },
            isJudgeLoggedIn() {
                return (
                    this.$store.getters.getAccountType == "Judge" &&
                    this.getJudgeIdFromLocal
                );
            },
        },
        methods: {
            isMobileView() {
                if (this.windowWidth <= 950) {
                    return true;
                } else {
                    return false;
                }
            },

            goToDashboard() {
                this.$router.push({
                    name: "JudgeCheckin",
                    params: { uniqueId: this.getJudgeIdFromLocal },
                });
            },
        },
        setup() {
            const store = useStore();
            const email = ref("");
            const hashAcquired = ref(false);
            const errorText = ref("");
            const ohno = ref(false);
            const isLoading = ref(false);
            const hash = computed(() => {
                return "https://www.UCFsdShowcase.com/judge/" + store.getters.getHash;
            });

            const OpenURL = () => {
                window.open("https://www.UCFsdShowcase.com/judge/" + store.getters.getHash, "_blank");
            };

            const getJudgeHash = () => {
                isLoading.value = true;
                store
                    // APG: emailHashLink will email the Judges Link
                    //emailHashLink
                    .dispatch("getHash", email.value)
                    .then(() => {
                        ohno.value = false;
                        errorText.value = "";
                        hashAcquired.value = true;
                    })
                    .catch((e) => {
                        errorText.value = "Error: " + e.response.data.description;
                        ohno.value = true;
                    })
                    .finally(() => (isLoading.value = false));
            };
            return {
                isLoading,
                ohno,
                email,
                hash,
                OpenURL,
                hashAcquired,
                getJudgeHash,
                errorText,
                options: computed(() => {
                    return [
                        "@gmail.com",
                        "@hotmail.com",
                        "@yahoo.com",
                        "@knights.ucf.edu",
                    ].map((suffix) => {
                        const prefix = email.value.split("@")[0];
                        return {
                            label: prefix + suffix,
                            value: prefix + suffix,
                        };
                    });
                }),
            };
        },
    });</script>
