<template>
  <n-form :model="formValue" :rules="rules" ref="formRef">
    <n-form-item
      class="formItemBlock mt-2"
      :label="$strings.EVENT_NAME"
      path="name"
    >
      <n-input
        class="eventFormInput"
        v-model:value="formValue.name"
        :placeholder="$strings.EVENT_NAME"
      />
    </n-form-item>
    <n-form-item
      class="formItemBlock"
      :label="$strings.EVENT_TIMESPAN"
      path="timespan"
    >
      <n-date-picker
        class="w-full"
        v-model:value="formValue.timespan"
        type="daterange"
        :actions="null"
        clearable
        close-on-select
        update-value-on-close
      />
    </n-form-item>
    <n-grid :cols="4" x-gap="12">
      <n-gi v-for="index in calcNumDays" :key="index">
        <n-form-item :label="getDateLabel(index)" path="modality">
          <n-radio-group v-model:value="formValue.modality[index - 1]">
            <n-radio :value="$strings.VIRTUAL">{{ $strings.VIRTUAL }}</n-radio>
            <n-radio :value="$strings.IN_PERSON">
              {{ $strings.IN_PERSON }}
            </n-radio>
            <n-radio :value="$strings.SKIP">{{ $strings.SKIP }}</n-radio>
          </n-radio-group>
        </n-form-item>
      </n-gi>
    </n-grid>
    <n-form-item
      class="formItemBlock"
      :label="$strings.EVENT_DISC"
      path="disciplines"
    >
      <n-select
        :placeholder="$strings.SELECT_DISC"
        :options="disciplineOptions"
        v-model:value="formValue.disciplines"
        multiple
        filterable
      />
    </n-form-item>
    <n-form-item :label="$strings.EVENT_STATUS" path="status">
      <n-radio-group
        class="flex flex-col space-y-2.5"
        v-model:value="formValue.status"
      >
        <n-radio :value="$strings.ACTIVE">{{ $strings.ACTIVE_TEXT }}</n-radio>
        <n-radio :value="$strings.INACTIVE">
          {{ $strings.INACTIVE_TEXT }}
        </n-radio>
        <n-radio :value="$strings.ARCHIVED" :disabled="!currEvent">
          {{ $strings.ARCHIVED_TEXT }}
        </n-radio>
      </n-radio-group>
    </n-form-item>
  </n-form>
  <div class="grid">
    <div class="justify-self-center">
      <save-button @save="handleSaveEvent" />
    </div>
  </div>
  <confirmation-dialog
    v-if="showDialog"
    :message="confirmationMessage"
    @handlePositive="handlePositive"
    @handleNegative="handleNegative"
  />
</template>

<script>
import { defineComponent, ref, computed, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import DateFormatters from "../helpers/DateFormatters.js";
import SaveButton from "../components/SaveButton.vue";
import ConfirmationDialog from "../components/ConfirmationDialog.vue";

function padModality(modality, text) {
  const newArray = [];
  modality.forEach((s) => newArray.push(s));
  while (newArray.length < 7) newArray.push(text);
  return newArray;
}

export default defineComponent({
  props: {
    currEvent: {
      type: Object,
      default: null,
      required: false,
    },
  },
  emits: ["close", "name"],
  components: {
    "save-button": SaveButton,
    "confirmation-dialog": ConfirmationDialog,
  },
  methods: {
    getDateLabel(index) {
      return DateFormatters.formatDate(
        DateFormatters.incrementDayBy(
          new Date(this.formValue.timespan[0]),
          index - 1
        )
      );
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const disciplineList = store.getters.getAllDisciplines;
    const defaultDisciplines = store.getters.getDefaultDisciplines;

    const strings =
      getCurrentInstance().appContext.config.globalProperties.$strings;

    const showDialog = ref(false);
    const formRef = ref(null);
    const disciplineOptions = disciplineList.map((v) => ({
      label: v.name,
      value: v.name,
    }));
    const isEdit = props.currEvent != null;
    const formValue = ref({
      name: isEdit ? props.currEvent.name : null,
      timespan: isEdit ? props.currEvent.timespan : null,
      modality: padModality(
        isEdit ? props.currEvent.modality : [],
        strings.VIRTUAL
      ),
      disciplines: isEdit
        ? props.currEvent.disciplines
        : defaultDisciplines.map((v) => v.name),
      status: isEdit ? props.currEvent.status : strings.ACTIVE,
      judges: [],
      teams: [],
    });

    const calcNumDays = computed(() => {
      if (formValue.value.timespan == null) return 0;
      const days = DateFormatters.getLength(formValue.value.timespan);
      return days <= 7 ? days : 0;
    });

    const rules = {
      name: {
        required: true,
        message: strings.ERROR_EVENT_NAME,
        trigger: "blur",
      },
      timespan: {
        validator() {
          const input = formValue.value.timespan;
          if (input == null) return false;
          return DateFormatters.getLength(input) <= 7;
        },
        required: true,
        message: strings.ERROR_TIMESPAN,
        trigger: "change",
      },
    };

    const handleSaveEvent = async () => {
      formRef.value.validate().then(() => {
        formValue.value.modality = formValue.value.modality.slice(
          0,
          calcNumDays.value
        );

        let replaceActive =
          store.getters.hasActiveEvent &&
          formValue.value.status == strings.ACTIVE;

        if (isEdit) replaceActive &= props.currEvent.status != strings.ACTIVE;

        if (replaceActive) {
          showDialog.value = true;
        } else {
          store
            .dispatch(
              "APIHelper",
              isEdit
                ? {
                    name: "editEvent",
                    params: { ...formValue.value, id: props.currEvent.id }, // Event Item with Id
                  }
                : { name: "addEvent", params: formValue.value }
            )
            .then(() => {
              if (props.currEvent.name != formValue.value.name)
                emit("name", formValue.value.name);
              emit("close");
            });
        }
      });
    };

    const handlePositive = () => {
      const nameCall = isEdit ? "editEvent" : "addEvent";
      const id = props.currEvent?.id; // New events will evaluate id to null
      const event = { ...formValue.value, id };
      store
        .dispatch("APIHelper", {
          name: nameCall,
          params: event,
        })
        .then(() => {
          emit("close");
        })
        .finally(() => (showDialog.value = false));
    };
    const handleNegative = () => {
      showDialog.value = false;
    };

    return {
      formRef,
      showDialog,
      calcNumDays,
      handleSaveEvent,
      confirmationMessage: strings.REPLACE_ACTIVE_EVENT,
      handlePositive,
      handleNegative,
      disciplineOptions: disciplineOptions,
      formValue: formValue,
      rules: rules,
    };
  },
});
</script>
