import axios from "axios";

export const judgesDefaultState = () => {
    return {
        judges: undefined
    };
};

export const judges = {
    state: judgesDefaultState,
    getters: {
        getAllJudges: (state) => {
            return state.judges;
        },
        getJudgeCount: (state) => {
            return state.judges?.length;
        },
        getJudgesOnDay: (state) => (day) => {
            return state.judges.filter((j) => j.days.includes(day));
        },
        getJudgeWithId: (state) => (id) => {
            const index = state.judges.findIndex((e) => e.id == id);
            return state.judges[index];
        },
        judgesLoaded: (state) => {
            return !!state.judges;
        },
        getHash: (state) => {
            return state.hash;
            //return state.hash.filter((j) => j.hash == hash);
        },
    },
    mutations: {
        setJudges(state, judges) {
            state.judges = judges;
        },
        addJudge(state, judge) {
            state.judges.push(judge);
        },
        deleteJudge(state, id) {
            state.judges = state.judges.filter((p) => p.id != id);
        },
        editJudge(state, judge) {
            if (!judge.id) judge.id = judge.judgeId;
            const index = state.judges.findIndex((e) => e.id == judge.id);
            if (index == -1) state.judges.push(judge);
            else state.judges[index] = judge;
        },
        addHash(state, hash) {
            state.hash = hash;
        },
    },
    actions: {
        async fetchAllJudges(context, eventName) {
            const eventId = context.getters.getEventWithName(eventName)?.id;
            await axios.get(`judge/${eventId}`).then((response) => {

                const judges = response.data;

                // convert the judges array to an array of strings 
                for (var i = 0; i < judges.length; i += 1) {

                    for (var j = 0; j < judges[i].days.length; j += 1) {

                        judges[i].days[j] = '' + judges[i].days[j];
                    }
                }
                context.commit("setJudges", judges);
            });
        },
        async fetchAllJudgesOfActive(context) {
            await axios.get("judge").then((response) => {
                const judges = response.data;
                context.commit("setJudges", judges);
            });
        },
        async addJudge(context, judge) {
            await axios.post(`judge`, judge).then((response) => {
                const judge = response.data;
                context.commit("addJudge", judge);
                context.commit("setJudgeCount", {
                    eventId: judge.eventId,
                    count: context.getters.getJudgeCount,
                });
            });
        },
        async deleteJudge(context, id) {
            await axios.delete(`judge/${id}`).then(() => {
                context.commit("setJudgeCount", {
                    eventId: context.getters.getJudgeWithId(id).eventId,
                    count: context.getters.getJudgeCount - 1,
                });
                context.commit("deleteJudge", id);
            });
        },
        async editJudge(context, judge) {
            await axios.put(`judge/${judge.id}`, judge).then((response) => {
                const judge = response.data;
                context.commit("editJudge", judge);
            });
        },
        async emailHashLink(context, email) {
            await axios.post(`/judge/hash/${email}`);
        },
        async getHash(context, email) {
            await axios.get(`/judge/hash/${email}`).then((response) => {
                const hash = response.data;
                context.commit("addHash", hash);
            });
        },
    },
};
