<template>
  <div id="app" class="primaryButton">
    <n-button
      tag="a"
      :href="'/files/' + file"
      download
      target="_blank"
      type="primary"
    >
      <DownloadIcon class="buttonIcon" />
      {{ text }}
    </n-button>
  </div>
</template>

<script>
import { DownloadIcon } from "@heroicons/vue/outline";
export default {
  props: {
    file: String,
    text: String,
  },
  components: {
    DownloadIcon,
  },
};
</script>
