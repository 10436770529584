<template>
  <div className="notificationsBody">
    <n-select
      class="notificationsBody"
      placeholder="Select Judges"
      v-model:value="sendList"
      :options="judgeList"
      multiple
      filterable
      clearable
    />
  </div>
  <div className="notificationsBody">
    <n-input v-model:value="header" placeholder="Title" />
  </div>
  <div className="notificationsBody">
    <n-input v-model:value="body" type="textarea" placeholder="Description" />
  </div>
  <n-checkbox-group v-model:value="notify" class="mt-2">
    <n-checkbox :value="true"> Send Email </n-checkbox>
  </n-checkbox-group>
  <div class="primaryButton notificationsSendButton">
    <n-button @click="sendNotifications()" :disabled="header.length == 0">
      Send
    </n-button>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useMessage } from "naive-ui";

export default defineComponent({
  props: {
    recipientId: String,
  },
  emtis: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const message = useMessage();
    const judgeList = computed(() =>
      store.getters
        .getJudgesOnDay(parseInt(route.params.dayIndex))
        .map((j) => ({ label: j.name, value: j.id }))
    );
    const sendList = ref(
      props.recipientId
        ? [props.recipientId]
        : judgeList.value.map((j) => j.value)
    );

    const header = ref("");
    const body = ref("");

    const isLoading = ref(false);
    const notify = ref([true]);

    const sendNotifications = () => {
      isLoading.value = true;
      let failedValues = [];
      sendList.value.forEach(async (id) => {
        await store
          .dispatch("sendNotificationToJudge", {
            judgeId: id,
            notification: { title: header.value, body: body.value },
            sendEmail: notify.value[0],
          })
          .catch(() => failedValues.push(id));
      });

      isLoading.value = false;

      if (failedValues.length > 0) {
        message.error("These notifications failed to send.");
        sendList.value = failedValues;
      } else {
        emit("close");
        message.success("Notification(s) Sent!");
      }
    };

    return {
      notify,
      sendList,
      judgeList,
      header,
      body,
      sendNotifications,
    };
  },
});
</script>
