<template>
  <div class="NotificationButton">
    <n-button v-if="newNotifications" @click="showModal = true" class="bg-red">
      <ExclamationCircleIcon class="notifIcon" />
    </n-button>
    <n-button v-else @click="showModal = true">
      <BellIcon class="notifIcon" />
    </n-button>
    <div class="primaryModal">
      <n-modal
        :class="[isMobileView() ? 'popoverModalMobile' : 'popoverModal']"
        v-model:show="showModal"
        preset="card"
        title="Recent Notifications"
        @after-leave="afterClose"
      >
        <notifications-list />
      </n-modal>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { BellIcon, ExclamationCircleIcon } from "@heroicons/vue/outline";
import NotificationsList from "@/components/NotificationsList.vue";
import { useWindowSize } from "vue-window-size";

export default defineComponent({
  name: "NotificationButton",
  components: {
    "notifications-list": NotificationsList,
    BellIcon,
    ExclamationCircleIcon,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      showModal: ref(false),
      windowWidth: width,
      windowHeight: height,
    };
  },
  methods: {
    isMobileView() {
      if (this.windowWidth <= 800) {
        return true;
      } else {
        return false;
      }
    },
    afterClose() {
      this.$store.dispatch("seeAllNotifications");
    },
  },
  computed: {
    newNotifications() {
      return this.$store.getters.unseenNotifications;
    },
  },
});
</script>
