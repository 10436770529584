<template>
  <n-card
    class="adminDashboardCard"
    :bordered="false"
    title="Ballot Management"
    style="margin-bottom: 5px"
  >
    <n-grid :cols="isMobileView()">
      <n-gi>
        <n-input
          v-model:value="filterValue"
          placeholder="Search Projects by Name, Discipline, Sponsor, or Team"
          @update:value="filterProjects"
        />
      </n-gi>
      <n-gi
        :class="[
          isSmallerView()
            ? 'advancedClearFilterBlockMobile'
            : 'advancedClearFilterBlock',
        ]"
      >
        <div class="primaryButton">
          <n-button @click="showFilterModal = true"> Advanced Filter </n-button>
          <span @click="clearFilter" className="clearFilterSearch">
            Clear Filter
          </span>
        </div>
      </n-gi>
    </n-grid>
    <p className="ballotFilterResultsShown">
      {{ projectListFiltered.length }} Results Shown
    </p>
    <div v-if="projectListFiltered.length > 0">
      <div v-for="project in paginatedProjectList" :key="project.id">
        <ballot-card :projectObject="project" @send="didClickNotification" />
      </div>
      <div class="grid justify-items-center mt-4">
        <n-pagination
          v-if="getPageCount > 1"
          v-model:page="page"
          :page-count="getPageCount"
        />
      </div>
    </div>
    <div v-else className="ballotFilterResultsShown">
      There are no projects in this round that match this description.
    </div>
  </n-card>
  <div class="primaryModal">
    <n-modal
      :class="[isMobileView() ? 'popoverModalMobile' : 'popoverModal']"
      v-model:show="showFilterModal"
      preset="card"
      title="Advanced Filter"
    >
      <n-input
        v-model:value="filterValue"
        placeholder="Search Projects by Name, Discipline, Sponsor, or Team"
      />
      <div className="popoverModalBody">
        <p className="advancedFilterBodyHeader">Sort By</p>
        <n-radio-group v-model:value="sortBy">
          <n-radio value="default">Default</n-radio>
          <n-radio value="alpha">Alphabetical Order</n-radio>
          <n-radio value="mostSubmitted">Most Submitted Ballots</n-radio>
          <n-radio value="leastSubmitted">Least Submitted Ballots</n-radio>
          <n-radio value="mostAssigned">Most Assigned Ballots</n-radio>
          <n-radio value="leastAssigned">Least Assigned Ballots</n-radio>
        </n-radio-group>
        <p className="advancedFilterBodyHeader">View by Disciplines</p>
        <n-checkbox-group v-model:value="disc">
          <div v-for="d in disciplines" :key="d">
            <n-checkbox :value="d" :label="d" />
          </div>
        </n-checkbox-group>
        <p className="advancedFilterBodyHeader">Filter by Ballots</p>
        <n-checkbox-group v-model:value="numBallots">
          <n-checkbox value="noneSubmitted">No Ballots Submitted</n-checkbox>
          <n-checkbox value="uptothreeSubmitted">
            1 to 3 Ballots Submitted
          </n-checkbox>
          <n-checkbox value="morethanthreeSubmitted">
            More than 3 Ballots Submitted
          </n-checkbox>
          <n-checkbox value="noneAssigned">No Ballots Assigned</n-checkbox>
          <n-checkbox value="uptothreeAssigned">
            1 to 3 Ballots Assigned
          </n-checkbox>
          <n-checkbox value="morethanthreeAssigned">
            More than 3 Ballots Assigned
          </n-checkbox>
        </n-checkbox-group>
      </div>
      <div class="primaryButton advancedFilterApplyButton">
        <n-button @click="filterProjects"> Apply </n-button>
      </div>
    </n-modal>
  </div>
  <div class="primaryModal">
    <n-modal
      v-model:show="showAddProjectModal"
      preset="card"
      title="Add Projects"
    >
      <n-select
        :placeholder="
          'Select Project(s) Based on Round ' +
          (activeEvent.currentRound - 1) +
          ' Ranking'
        "
        v-model:value="newProjects"
        :options="getAvailableProjects"
        multiple
        filterable
      >
        <template #empty> There are no projects to add. </template>
      </n-select>
      <div class="primaryButton">
        <n-button @click="addProjects()" :loading="assignIsLoading">
          Add Project(s)
        </n-button>
      </div>
    </n-modal>
  </div>
</template>

<script>
import { XCircleIcon } from "@heroicons/vue/outline";
import { defineComponent, ref, computed } from "vue";
import { useWindowSize } from "vue-window-size";

import { useStore } from "vuex";
import BallotCard from "./BallotCard.vue";

const NUM_PER_PAGE = 5;

export default defineComponent({
  components: {
    "ballot-card": BallotCard,
    XCircleIcon,
  },
  emits: ["send"],
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  methods: {
    didClickNotification(recipientId) {
      this.$emit("send", recipientId);
    },
    isMobileView() {
      if (this.windowWidth <= 1000) {
        return 1;
      } else {
        return 2;
      }
    },
    isSmallerView() {
      if (this.windowWidth <= 1000) {
        return true;
      } else {
        return false;
      }
    },
  },
  setup() {
    const store = useStore();

    const activeEvent = computed(() => {
      return store.getters.getActiveEvent;
    });

    const projectList = computed(() => {
      return store.getters.getAllProjectsOfRound(
        activeEvent.value.currentRound
      );
    });

    const filterValue = ref("");
    const sortBy = ref("default");
    const disc = ref(null);
    const numBallots = ref(null);

    const projectListFiltered = computed(() => {
      const ballotCount = {},
        submittedCount = {};
      const value = filterValue.value?.toLowerCase();

      let ret = projectList.value?.filter((p) => {
        let discFlag = false,
          inputFlag = false;

        if (p.name.toLowerCase().includes(value)) inputFlag = true;

        p.disciplines.forEach((d) => {
          if (d.toLowerCase().includes(value)) inputFlag = true;
          if (disc.value?.includes(d)) discFlag = true;
        });

        p.students.forEach((s) => {
          if (s.toLowerCase().includes(value)) inputFlag = true;
        });

        p.sponsors.forEach((s) => {
          if (s.toLowerCase().includes(value)) inputFlag = true;
        });

        ballotCount[p.id] = 0;
        submittedCount[p.id] = 0;
        store.getters.getBallotsByProject(p.id).forEach((b) => {
          ballotCount[p.id]++;
          b.completed ? submittedCount[p.id]++ : submittedCount[p.id];
        });

        const isAdvanced = disc.value && disc.value.length > 0;

        return isAdvanced ? discFlag && inputFlag : inputFlag;
      });

      ret?.sort((a, b) => {
        if (sortBy.value == "alpha") {
          return a.name < b.name ? -1 : 1;
        } else if (sortBy.value == "mostSubmitted") {
          return submittedCount[b.id] - submittedCount[a.id];
        } else if (sortBy.value == "leastSubmitted") {
          return submittedCount[a.id] - submittedCount[b.id];
        } else if (sortBy.value == "mostAssigned") {
          return ballotCount[b.id] - ballotCount[a.id];
        } else if (sortBy.value == "leastAssigned") {
          return ballotCount[a.id] - ballotCount[b.id];
        } else {
          return a.id - b.id;
        }
      });

      if (numBallots.value && numBallots.value.length > 0) {
        ret = ret?.filter((p) => {
          let flag = false;
          const val = numBallots.value,
            subCount = submittedCount[p.id],
            assCount = ballotCount[p.id];

          if (val.includes("noneSubmitted")) flag |= subCount == 0;
          if (val.includes("uptothreeSubmitted"))
            flag |= subCount > 0 && subCount < 3;
          if (val.includes("morethanthreeSubmitted")) flag |= subCount >= 3;
          if (val.includes("noneAssigned")) flag |= assCount == 0;
          if (val.includes("uptothreeAssigned"))
            flag |= assCount > 1 && assCount < 3;
          if (val.includes("morethanthreeAssigned")) flag |= assCount >= 3;

          return flag;
        });
      }

      return ret;
    });

    const disciplines = computed(() => {
      return activeEvent.value.disciplines;
    });

    const showFilterModal = ref(false);

    const resetPageValue = () => {
      page.value = 1;
    };

    const filterProjects = () => {
      showFilterModal.value = false;
      resetPageValue();
    };

    const clearFilter = () => {
      filterValue.value = "";
      sortBy.value = "default";
      disc.value = null;
      numBallots.value = null;

      resetPageValue();
    };

    const page = ref(1);
    const paginatedProjectList = computed(() => {
      if (projectListFiltered.value != null)
        return [...projectListFiltered.value].slice(
          (page.value - 1) * NUM_PER_PAGE,
          page.value * NUM_PER_PAGE
        );
      else return null;
    });
    const getPageCount = computed(() =>
      projectListFiltered.value != null
        ? parseInt(
            (projectListFiltered.value.length + NUM_PER_PAGE - 1) / NUM_PER_PAGE
          )
        : 0
    );

    const assignIsLoading = ref(false);
    const newProjects = ref([]);
    const getAvailableProjects = computed(() => {
      return [];
    });

    const addProjects = () => {
      console.log(newProjects.value);
    };

    return {
      activeEvent,
      paginatedProjectList,
      getPageCount,
      page,
      projectListFiltered,
      filterValue,
      sortBy,
      disc,
      numBallots,
      filterProjects,
      clearFilter,
      showFilterModal,
      disciplines,
      showAddProjectModal: ref(false),
      newProjects,
      getAvailableProjects,
      addProjects,
      assignIsLoading,
    };
  },
});
</script>
