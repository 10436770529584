<template>
  <div class="checkin" v-if="allInfoIsLoaded && getAccount">
    <n-steps
      vertical
      :current="current"
      :class="[isMobileView() ? 'checkInStepsMobile' : 'checkinSteps']"
    >
      <n-step
        :title="isMobileView() ? '' : 'Event'"
        :class="[current >= 1 ? 'activatedStep' : 'inactiveStep']"
      />
      <n-step
        :title="isMobileView() ? '' : 'Attendance'"
        :class="[current >= 2 ? 'activatedStep' : 'inactiveStep']"
      />

      <n-step
        :title="isMobileView() ? '' : 'Affiliation'"
        :class="[current >= 3 ? 'activatedStep' : 'inactiveStep']"
      />

      <n-step
        :title="isMobileView() ? '' : 'Overview'"
        :class="[current >= 4 ? 'activatedStep' : 'inactiveStep']"
      />
    </n-steps>
    <judge-welcome
      v-if="current == 1"
      @next="current += 1"
      @cancel="showConfirm = true"
    />
    <judge-attendance
      v-else-if="current == 2"
      :formInfo="formRef"
      @next="current += 1"
      @back="current -= 1"
      @update="addAttendance"
    />
    <judge-affiliation
      v-else-if="current == 3"
      :formInfo="formRef"
      @next="current += 1"
      @back="current -= 1"
      @update="addAffiliations"
    />
    <judge-overview
      v-else
      :formInfo="formRef"
      @complete="completeCheckIn()"
      @back="current -= 1"
      :key="formRef"
    />
    <confirm
      v-if="showConfirm"
      message="Clicking yes confirms you cannot attend this event. You can revisit this page later."
      @handlePositive="cancelCheckin"
      @handleNegative="showConfirm = false"
    />
  </div>
  <div v-else-if="errorWithAccount">
    <Error />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useWindowSize } from "vue-window-size";

import JudgeWelcome from "@/components/judge-checkin/JudgeWelcome.vue";
import JudgeAttendance from "@/components/judge-checkin/JudgeAttendance.vue";
import JudgeAffiliation from "@/components/judge-checkin/JudgeAffiliation.vue";
import JudgeOverview from "@/components/judge-checkin/JudgeOverview.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import Error from "../404.vue";

export default defineComponent({
  components: {
    "judge-welcome": JudgeWelcome,
    "judge-attendance": JudgeAttendance,
    "judge-affiliation": JudgeAffiliation,
    "judge-overview": JudgeOverview,
    confirm: ConfirmationDialog,
    Error,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      id: this.$route.params.uniqueId,
      showConfirm: false,
      windowWidth: width,
      windowHeight: height,
      errorWithAccount: ref(false),
    };
  },
  computed: {
    getAccount() {
      return this.$store.getters.getAccount;
    },
    getAccessToken() {
      return this.$store.getters.getAccessToken;
    },
    getJudgeHash() {
      return this.$store.getters.getJudgeHash;
    },
    allInfoIsLoaded() {
      return (
        this.$store.getters.affiliationsLoaded &&
        this.$store.getters.eventsLoaded &&
        this.getAccount
      );
    },
  },
  methods: {
    isMobileView() {
      if (this.windowWidth <= 1000) {
        return true;
      } else {
        return false;
      }
    },
    getResponsive() {
      if (this.windowWidth <= 1000) {
        return false;
      } else {
        return true;
      }
    },
    cancelCheckin() {
      this.$store.commit("resetState");
    },
    goToDashboard() {
      this.$router.push({ name: "JudgeDashboard" });
    },
    completeCheckIn() {
      this.$store
        .dispatch("APIHelper", {
          name: "checkInJudge",
          params: this.formRef,
        })
        .then(() => this.goToDashboard());
    },
  },
  setup() {
    const formRef = ref({
      attendance: [],
      affiliations: [],
    });
    const addAffiliations = (newValue) => {
      formRef.value.affiliations = newValue;
    };
    const addAttendance = (newValue) => {
      formRef.value.attendance = newValue;
    };
    return {
      formRef,
      addAffiliations,
      addAttendance,
      current: ref(1),
    };
  },
  async created() {
    const judgeHash = this.getJudgeHash;

    if (!this.$store.getters.affiliationsLoaded)
      this.$store.dispatch("APIHelper", { name: "fetchAllAffiliations" });

    if (!this.$store.getters.eventsLoaded)
      this.$store.dispatch("APIHelper", { name: "fetchActiveEvent" });

    if (!judgeHash || judgeHash != this.id || !this.getAccount) {
      await this.$store.dispatch("setJudgeAccessToken", this.id);
      await this.$store
        .dispatch("APIHelper", {
          name: "getJudgeFromId",
          params: this.id,
        })
        .then(() => {
          if (!this.getAccount) this.errorWithAccount = true;
          if (this.getAccount?.isCheckedIn) this.goToDashboard();
        });
    } else if (this.getAccount.isCheckedIn) {
      this.goToDashboard();
    }
  },
});
</script>
