<template>
  <nav class="userNavBar">
    <div class="ml-5 userNavBar" v-if="back != null">
      <ArrowLeftIcon class="cursor-pointer buttonIcon" @click="goBack" />
      <p class="userNavBarNameBack">
        {{ fullName }}
      </p>
    </div>
    <div v-else>
      <p class="userNavBarNameNoBack">
        {{ fullName }}
      </p>
    </div>
    <div v-if="isMobileView">
      <MenuIcon @click.stop="isToggled ^= true" class="mobileNavButton" />
      <Transition name="menu">
        <div v-if="isToggled" class="mobileNavMenu" @click="isToggled ^= true">
          <slot></slot>
          <n-button class="logButton w-full" @click.stop="handleClick">
            {{ buttonText }}
          </n-button>
        </div>
      </Transition>
    </div>
    <ul v-else class="desktopNavMenuList">
      <slot></slot>
      <n-button class="logButton" @click.stop="handleClick">
        {{ buttonText }}
      </n-button>
    </ul>
  </nav>
</template>

<script>
import msal from "@/helpers/MsalHelpers.js";
import { MenuIcon, ChevronUpIcon, ArrowLeftIcon } from "@heroicons/vue/outline";
import { useWindowSize } from "vue-window-size";
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    back: {
      default: null,
      name: String,
      params: Object,
    },
  },
  components: {
    MenuIcon,
    ChevronUpIcon,
    ArrowLeftIcon,
  },
  name: "NavBar",
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
      isToggled: ref(false),
    };
  },
  computed: {
    isMobileView() {
      if (this.windowWidth <= 650) {
        return true;
      } else {
        return false;
      }
    },
    fullName() {
      return this.loginStatus
        ? this.$store.getters.getAccount.name
        : this.$strings.UNKNOWN_USER_NAV;
    },
    loginStatus() {
      return !!this.$store.getters.getAccount;
    },
    isJudge() {
      return this.$store.getters.getAccountType == "Judge";
    },
    showLogout() {
      return this.loginStatus && this.$route.meta.dir != "Main";
    },
    showGoToDashboard() {
      return this.loginStatus && this.$route.meta.dir == "Main";
    },
    buttonText() {
      if (this.showLogout) return this.$strings.LOGOUT;
      else if (this.showGoToDashboard) return "Go To Dashboard";
      else return this.$strings.LOGIN;
    },
  },
  methods: {
    async handleClick() {
      if (this.showLogout) {
        msal.logout(this.$msalInstance, this.$store);
      } else {
        const accountType = this.$store.getters.getAccountType;
        if (accountType == "Admin") {
          this.goToAdmin();
        } else if (accountType == "Instructor") {
          this.goToInstructor();
        } else if (accountType == "Judge") {
          this.goToJudge();
        } else {
          msal.login(this.$msalInstance, this.$store);
        }
      }
    },
    goToAdmin() {
      this.$router.push({
        name: "MainAdminDashboard",
      });
    },
    goToInstructor() {
      this.$router.push({
        name: "InstructorDashboard",
      });
    },
    goToJudge() {
      this.$router.push({
        name: "JudgeCheckin",
        params: { uniqueId: this.$store.getters.getJudgeHash },
      });
    },
    goToHome() {
      this.$router.push({
        name: "MainHome",
      });
    },
    goBack() {
      this.$router.push({
        name: this.back.name,
        params: this.back.params,
      });
    },
  },
  watch: {
    loginStatus: function (newStatus) {
      if (newStatus) {
        switch (this.$store.getters.getAccountType) {
          case "Admin":
            this.goToAdmin();
            return;
          case "Instructor":
            this.goToInstructor();
            return;
          default:
            return;
        }
      } else {
        this.goToHome();
      }
    },
  },
});
</script>

<style>
.menu-enter-active {
  transition: all 0.25s ease-out;
}

.menu-leave-active {
  transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}

.menu-enter-from,
.menu-leave-to {
  transform: translateX(100%);
}
</style>
