<template>
  <p className="manageOnDesktopDescription">
    Manage
    <span className="manageOnDesktopContent">{{ contentDescription }}</span>
    from a Desktop view.
  </p>
</template>

<script>
export default {
  name: "ManageOnDesktop",
  props: {
    contentDescription: String,
  },
};
</script>
