<template>
  <div class="404">
    <div class="deadPageContent">
      <div class="deadpageHeader">
        404
        <div class="deadpageSubheader">Something went wrong!</div>
        <div class="deadpageDescription">
          What you are searching for is unavailable or does not exist. Go back
          <span class="deadPageHyperlink">
            <a class="cursor-pointer" @click="goToHome()">home </a>
          </span>
          or to the
          <span class="deadPageHyperlink">
            <a class="cursor-pointer" @click="goBack()">previous page.</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    goToHome() {
      this.$router.push({
        name: "MainHome",
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
});
</script>
