<template>
  <div class="ballot" v-if="projectsLoaded">
    <n-card
      class="adminDashboardCard"
      :bordered="false"
      title="Judging"
      style="margin-bottom: 5px"
    >
      <template #header-extra>
        <save-button
          class="fixed z-30 right-10"
          @save="saveBallot(false)"
          :disabled="!hasChanged"
          :loading="saveLoading"
        />
      </template>
      <h1 class="ballotProjectName">{{ project.name }}</h1>
      <h2 class="ballotProjectStudents">
        {{ getStringValue(project.students) }}
      </h2>
      <div class="mt-4 mb-10">
        <h2>
          Click
          <span @click="showVid = true" class="judgingHyperlink">here</span> to
          watch the video for this team
          <span>
            (or
            <span @click.stop="goToYoutube()" class="judgingHyperlink"
              >here</span
            >
            to open in a new tab).
          </span>
        </h2>
        <h2>
          If there is no video or demo provided, click
          <span @click="autoCompleteBallot" class="judgingHyperlink">here</span>
          to autofill your ballot.
          <span class="judgingPreface"
            >Note this will automatically grant the team a 0% score.</span
          >
        </h2>
      </div>
      <n-form :model="formValue" :rules="rules" ref="formRef">
        <div v-for="(question, index) in questions" :key="question">
          <div
            :class="[
              !isValid[index]
                ? 'ballotQuestionIncomplete'
                : 'ballotQuestionCard',
            ]"
          >
            <n-card
              :title="index + 1 + '. ' + question"
              bordered
              :class="[isMobileView() ? 'ballotQuestionHeader' : '']"
            >
              <n-grid
                :cols="getResponsiveCols()"
                :class="[
                  isMobileView()
                    ? 'justify-items-center'
                    : 'justify-items-start',
                ]"
              >
                <n-gi>
                  <h1>{{ $strings.SUBPROMPT }}</h1>
                  <n-form-item path="scores">
                    <n-rate
                      allow-half
                      :count="5"
                      v-model:value="formValue.scores[index]"
                      @update:value="hasChanged = isValid[index] = true"
                    >
                      <n-icon :size="isMobileView() ? '50' : '60'">
                        <EmojiHappyIcon v-if="formValue.scores[index] >= 2.5" />
                        <EmojiSadIcon v-else />
                      </n-icon>
                    </n-rate>
                  </n-form-item>
                </n-gi>
                <n-gi
                  :class="[
                    isMobileView()
                      ? 'ballotCommentsMobile '
                      : 'ballotCommentsDesktop',
                  ]"
                >
                  <h1 className="ballotCommentsHeader">
                    {{ $strings.ADDITIONAL_COMMENTS }}
                  </h1>
                  <n-form-item path="comments">
                    <n-input
                      v-model:value="formValue.comments[index]"
                      @update:value="hasChanged = true"
                      type="textarea"
                      :placeholder="$strings.EMPTY_COMMENTS"
                    />
                  </n-form-item>
                </n-gi>
              </n-grid>
            </n-card>
          </div>
        </div>
      </n-form>
      <n-back-top :show="!isTop" />
    </n-card>
    <div>
      <n-modal v-model:show="showVid">
        <iframe
          :src="getEmbedLink()"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; fullscreen"
          :class="[isMobileView() ? 'videoModalMobile' : 'videoModal']"
        />
      </n-modal>
    </div>
    <confirmation-dialog
      v-if="showDialog"
      message="This will automatically grant the team a 0% score."
      @handlePositive="handlePositive"
      @handleNegative="showDialog = false"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import SaveButton from "@/components/SaveButton.vue";
import { useWindowSize } from "vue-window-size";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useMessage } from "naive-ui";
import getYouTubeID from "get-youtube-id";
import { EmojiHappyIcon, EmojiSadIcon } from "@heroicons/vue/outline";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";

export default defineComponent({
  components: {
    "save-button": SaveButton,
    EmojiHappyIcon,
    EmojiSadIcon,
    "confirmation-dialog": ConfirmationDialog,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      isTop: true,
      windowWidth: width,
      windowHeight: height,
    };
  },
  computed: {
    projectsLoaded() {
      return this.$store.getters.projectsLoaded;
    },
  },
  methods: {
    goToYoutube() {
      window.open(this.project.vidLink);
    },
    isMobileView() {
      if (this.windowWidth <= 900) {
        return true;
      } else {
        return false;
      }
    },
    getResponsiveCols() {
      if (this.windowWidth <= 900) {
        return 1;
      } else {
        return 2;
      }
    },
    getEmbedLink() {
      return `https://www.youtube.com/embed/${getYouTubeID(
        this.project.vidLink
      )}`;
    },
    getStringValue(list) {
      let listString = "";
      list.forEach((p) => (listString += p + ", "));
      return listString.slice(0, listString.length - 2);
    },
    onScroll() {
      this.isTop = window.top.scrollY == 0;
    },
    beforeWindowUnload(e) {
      if (this.confirmStayInDirtyForm()) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
    confirmLeave() {
      return window.confirm("Changes you made may not be saved.");
    },
    confirmStayInDirtyForm() {
      return this.hasChanged && !this.confirmLeave();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.confirmStayInDirtyForm()) {
      next(false);
    } else {
      next();
    }
  },
  mounted() {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("beforeunload", this.beforeWindowUnload);

    if (!this.$store.getters.projectsLoaded)
      this.$router.push({ name: "JudgeDashboard" });
  },
  unmounted() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  setup() {
    const store = useStore();

    if (store.getters.projectsLoaded) {
      const route = useRoute();
      const router = useRouter();
      const message = useMessage();

      const hasChanged = ref(false);

      const project = store.getters.getProjectWithId(route.params.projectId);

      const ballot = project.ballots[0];
      const scores = ballot?.scores ?? [];
      const questions = scores.map((b) => b.question ?? "");
      const ratings = scores.map((b) => b.rating ?? 0);
      const comments = scores.map((b) => b.comment ?? "");

      const isValid = ref([]);
      questions.forEach(() => isValid.value.push(true));

      const formRef = ref(null);
      const formValue = ref({
        scores: ratings,
        comments,
      });

      const rules = {
        scores: {
          required: true,
        },
        comments: {
          required: false,
        },
      };

      const showDialog = ref(false);
      const handlePositive = () => {
        formValue.value.scores.fill(0);
        saveBallot(true);
      };

      const autoCompleteBallot = () => {
        showDialog.value = true;
      };

      const saveLoading = ref(false);

      const saveBallot = async (auto) => {
        let flag = true;

        if (!auto) {
          const input = formValue.value.scores;
          input.forEach((s, index) => {
            if (s <= 0) {
              (isValid.value[index] = false), (flag = false);
            } else {
              isValid.value[index] = true;
            }
          });
        }

        if (!flag) {
          message.error("Incomplete Fields");
          return;
        }

        // Ballot model
        const updatedBallotItem = {
          projectId: route.params.projectId,
          scores: formValue.value.scores.map((score, idx) => ({
            rating: score,
            comment: formValue.value.comments[idx],
            question: questions[idx],
          })),
        };

        saveLoading.value = true;

        await store
          .dispatch("saveBallot", {
            ballot: updatedBallotItem,
          })
          .then(() => {
            message.success("Ballot Saved");
            hasChanged.value = false;
            saveLoading.value = false;
            router.go(-1);
          })
          .catch(() => message.error("Ballot Save Failed"));
      };

      const showVid = ref(false);

      return {
        showVid,
        hasChanged,
        isValid,
        project,
        ballot,
        questions,
        formRef,
        formValue,
        rules,
        saveBallot,
        autoCompleteBallot,
        handlePositive,
        showDialog,
        saveLoading,
      };
    } else {
      return {};
    }
  },
});
</script>
