const ERROR_MESSAGE = "Something went wrong";

export const utilitiesDefaultState = () => {
  return {
    loadingQueue: 0,
    header: "Error",
    errorMessage: ERROR_MESSAGE,
    showError: false,
    retryQueue: [],
  };
};

export const utilities = {
  state: utilitiesDefaultState,
  getters: {
    isLoading: (state) => {
      return state.loadingQueue > 0;
    },
    getErrorMessage: (state) => {
      return state.errorMessage;
    },
    getHeader: (state) => {
      return state.header;
    },
    isErrorShowing: (state) => {
      return state.showError;
    },
    retryQueueIsEmpty: (state) => {
      return state.retryQueue.length == 0;
    },
    getNextRetryItem: (state) => {
      return state.retryQueue.pop();
    },
  },
  mutations: {
    startLoading(state) {
      state.loadingQueue++;
    },
    stopLoading(state) {
      setTimeout(
        () => (--state.loadingQueue < 0 ? 0 : state.loadingQueue),
        1000
      );
    },
    showErrorDialog(state, { header, message, callDetails }) {
      state.header = header;
      state.errorMessage = message;
      state.showError = true;
      if (callDetails) state.retryQueue.push(callDetails);
    },
    hideErrorDialog(state) {
      state.errorMessage = ERROR_MESSAGE;
      state.showError = false;
    },
  },
  actions: {
    async APIHelper(context, { name, params }) {
      context.commit("startLoading");

      await context
        .dispatch(name, params)
        .catch((e) => {
          console.log(e);
          const data = e.response?.data;
          const header = data?.name ?? "Error";
          const body = data?.description ?? e.response?.statusText;

          const message =
            "Our server responded with a " +
            e.response?.status +
            " (" +
            body +
            ") error.";
          const callDetails = { name: name, params: params };
          context.commit("showErrorDialog", {
            header: header,
            message: message,
            callDetails: callDetails,
          });
        })
        .finally(() => context.commit("stopLoading"));
    },
  },
};
