class DateFormatters {
  static formatDate(date) {
    return date.toLocaleDateString(["en-US"], {
      month: "long",
      day: "2-digit",
      year: "numeric",
    });
  }

  static getDateRange(timespan) {
    return (
      DateFormatters.startDate(timespan) +
      " - " +
      DateFormatters.endDate(timespan)
    );
  }

  static getTimeElapsedFromString(timeString) {
    const now = new Date();
    const then = new Date(timeString);
    const diff = now - then;
    const timeDiff = now.getTime() - then.getTime();

    const days = parseInt(diff / (1000 * 60 * 60 * 24));
    const hours = parseInt((Math.abs(diff) / (1000 * 60 * 60)) % 24);
    const minutes = parseInt((Math.abs(timeDiff) / (1000 * 60)) % 60);
    const seconds = parseInt((Math.abs(timeDiff) / 1000) % 60);

    let ret = "";
    if (days > 1) ret += days + " days ";
    else if (days > 0) ret += days + " day ";
    if (hours > 1) ret += hours + " hours ";
    else if (hours > 0) ret += hours + " hour ";
    if (minutes > 1) ret += minutes + " minutes ";
    else if (minutes > 0) ret += minutes + " minute ";
    if (seconds > 1) ret += seconds + " seconds ";
    else if (seconds > 0) ret += seconds + " second ";

    return ret;
  }

  static getDayFromString(dateString) {
    return new Date(dateString);
  }

  static getLength(timespan) {
    return (timespan[1] - timespan[0]) / (1000 * 3600 * 24) + 1;
  }

  static incrementDayBy(date, numDays) {
    date.setDate(date.getDate() + numDays);
    return date;
  }

  static startDate(timespan) {
    return this.formatDate(this.getDayFromString(timespan[0]));
  }

  static endDate(timespan) {
    return this.formatDate(this.getDayFromString(timespan[1]));
  }
}

export default DateFormatters;
