const strings = {
  // Ballot Questions
  BALLOT_QUESTIONS: [
    "Clearly-Stated Project Goals and Objectives",
    "Broader Impacts of Project",
    "Creativity/Originality in Problem Solving",
    "Verbal Communication Skills",
    "Technical Merit/Soundness of the approach",
    "Design Completeness/Polish",
    "Quality of Testing Plan/Consideration of Stakeholder Feedback",
    "Project Demo/Display Quality",
    "Teamwork/Division of Labor/Project Management",
    "Overall Project Impression",
  ],
  ADDITIONAL_COMMENTS: "Additional Comments",
  SUBPROMPT: "Select the score you would like to assign the team.",
  INCOMPLETE: "Incomplete.",
  GREAT: "Great!",

  // Placeholders
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  EMAIL: "Email",
  STATUS: "Status",
  FILTER_BY_NAME: "Filter by Name",
  FILTER_BY_DISCIPLINE: "Filter by Discipline",
  EVENT_NAME: "Event Name",
  EVENT_TIMESPAN: "Event Timespan",
  EVENT_DISC: "Event Discipline(s)",
  SELECT_DISC: "Select Disciplines",
  SELECT_AFFIL: "Select Affiliations",
  EVENT_STATUS: "Event Status",
  ACTIVE_TEXT: "Set this event as active.",
  INACTIVE_TEXT: "Set this event as inactive.",
  ARCHIVED_TEXT: "Set this event as archived.",
  ACTIVE_EVENT: "Active Event",
  INACTIVE_EVENTS: "Inactive Events",
  ARCHIVED_EVENTS: "Archived Events",
  EMPTY_COMMENTS: "Tell me something I don't know...",

  // Statuses
  ADMIN: "Administrator",
  INSTRUCTOR: "Instructor",
  JUDGE: "Judge",
  TBD: "TBD",
  NO_EVENTS: "There are no events to manage.",
  NO_EVENTS_FILTERED: "There are no events with this name.",
  VIRTUAL: "Virtual",
  IN_PERSON: "In Person",
  SKIP: "Skip",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  ARCHIVED: "Archived",

  // Button Text
  EDIT_ACCOUNT_INFO: "Edit Account Info",
  ADD_INSTRUCTOR: "Add Instructor",
  YES: "Yes",
  CANCEL: "Cancel",
  CREATE_AN_EVENT: "Create an Event",
  EDIT: "Edit",
  LOGIN: "Login",
  LOGOUT: "Logout",
  UNKNOWN_USER_NAV: "Welcome!",
  SAVE: "Save",
  SD_SHOWCASE_HOMEPAGE: "SENIOR DESIGN SHOWCASE HOMEPAGE",
  CONFIRM_ERROR: "Ok, I'll try again!",
  CANCEL_ERROR: "I give up!",

  // Error Text
  ERROR_TITLE: "Are you sure?",
  ERROR_EVENT_NAME: "Please input event name.",
  ERROR_TIMESPAN: "Please choose a valid start and end date (max seven days).",

  // Warning Text
  REPLACE_ACTIVE_EVENT: "This action will replace the current active event.",

  // Card Titles
  EDIT_EVENT: "Edit Event",
  CREATE_EVENT: "Create Event",

  // Event Details
  TEAMS: "Teams",
  JUDGES: "Judges",

  // Settings
  PROFILE_HEADER: "Profile",
  PROFILE_DESCRIPTION:
    "This contains information visible to others on the application. Filler sentence detailing block.",
  ACCOUNT_HEADER: "Instructor Management",
  ACCOUNT_DESCRIPTION:
    "Filler sentence detailing block. Something about how the administrator can modify instructor accounts.",
  EVENT_HEADER: "Event Management",
  EVENT_DESCRIPTION:
    "Filler sentence detailing block. Something about the disciplines for events",
  EVENT_DEFAULT_DISC: "Default Disciplines",
  EVENT_DEFAULT_DISC_DESCRIPTION:
    "These disciplines will be assigned to every event by default.",
  EVENT_ALL_DISC: "All Disciplines",
  EVENT_ALL_DISC_DESCRIPTION:
    "All disciplines you could potentially select from. Filler text goes here.",
};

export default strings;
