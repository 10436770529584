<template>
  <div v-if="eventListLength == 0" class="grid place-items-center">
    <h1 class="font-bold my-10">{{ $strings.NO_EVENTS }}</h1>
  </div>
  <div v-else>
    <n-layout
      class="eventDetailsList mx-12 sm:mx-32 md:mx-44"
      :native-scrollbar="false"
    >
      <div class="eventDetailsListFilter grid justify-items-center">
        <n-input
          v-if="eventListLength > 1"
          v-model:value="searchValue"
          @update:value="resetPageValue"
          :placeholder="$strings.FILTER_BY_NAME"
        />
      </div>
      <div class="grid place-items-center">
        <h1 v-if="filteredListIsEmpty" class="font-bold my-10">
          {{ $strings.NO_EVENTS_FILTERED }}
        </h1>
      </div>
      <div v-for="event in paginatedEventList" :key="event">
        <event-details :event-id="event.id" />
      </div>
      <div class="grid justify-items-center mt-4">
        <n-pagination
          v-if="getPageCount > 1"
          v-model:page="page"
          :page-count="getPageCount"
        />
      </div>
    </n-layout>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import EventDetails from "./EventDetails.vue";

const NUM_PER_PAGE = 2;

export default defineComponent({
  name: "EventDetailsList",
  props: {
    tab: String,
  },
  components: {
    "event-details": EventDetails,
  },
  computed: {
    eventListLength() {
      return this.eventList == null ? 0 : this.eventList.length;
    },
  },
  setup(props) {
    const store = useStore();
    const eventList = computed(() => {
      return store.getters.getEventsWithStatus(props.tab);
    });
    const searchValue = ref("");

    const filteredEventList = computed(() => {
      if (!eventList.value) return null;
      return eventList.value.filter((event) =>
        event.name.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    });

    const page = ref(1);
    const paginatedEventList = computed(() => {
      if (filteredEventList.value != null)
        return [...filteredEventList.value]
          .sort((a, b) => a.timespan[0] - b.timespan[0])
          .slice((page.value - 1) * NUM_PER_PAGE, page.value * NUM_PER_PAGE);
      else return null;
    });
    const getPageCount = computed(() =>
      filteredEventList.value != null
        ? parseInt(
            (filteredEventList.value.length + NUM_PER_PAGE - 1) / NUM_PER_PAGE
          )
        : 0
    );
    const filteredListIsEmpty = computed(() =>
      filteredEventList.value == null
        ? true
        : filteredEventList.value.length == 0
    );
    const resetPageValue = () => {
      page.value = 1;
    };

    return {
      searchValue: searchValue,
      page: page,
      eventList,
      filteredEventList,
      paginatedEventList,
      filteredListIsEmpty,
      getPageCount,
      resetPageValue,
    };
  },
});
</script>

<style></style>
