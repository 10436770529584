<template>
  <n-layout class="eventDetailsList" :native-scrollbar="false">
    <n-grid :cols="getResponsiveCols()">
      <n-gi class="resultsInputFieldDesktop">
        <n-input
          placeholder="Search by Name, Disciplines..."
          v-model:value="filterValue"
        />
      </n-gi>
      <n-gi
        :class="
          isMobileView()
            ? 'resultsSelectFieldMobile'
            : 'resultsSelectFieldDesktop'
        "
      >
        <n-select
          placeholder="Select Event"
          v-model:value="value"
          :options="options"
        >
          <template #empty> No events </template>
        </n-select>
      </n-gi>
    </n-grid>
    <div v-if="filteredProjects?.length > 0">
      <div v-for="project in filteredProjects" :key="project">
        <project-result-details :projectObject="project" />
      </div>
    </div>
    <div v-else className="ballotFilterResultsShown">
      There are no results that match this description. 
    </div>
  </n-layout>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import ProjectResultDetails from "./ProjectResultDetails.vue";
import { useWindowSize } from "vue-window-size";

export default defineComponent({
  components: {
    "project-result-details": ProjectResultDetails,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  methods: {
    isMobileView() {
      if (this.windowWidth <= 900) {
        return true;
      } else {
        return false;
      }
    },
    getResponsiveCols() {
      if (this.windowWidth <= 900) {
        return 1;
      } else {
        return 2;
      }
    },
  },
  setup() {
    const store = useStore();

    const results = computed(() =>
      [...store.getters.getAllResults].sort((a, b) => {
        if (a.event.timespan[0] == b.event.timespan[0]) {
          return a.event.timespan[1] == b.event.timespan[1]
            ? a.event.name < b.event.name
              ? -1
              : 1
            : a.event.timespan[1] - b.event.timespan[1];
        }
        return a.event.timespan[0] - b.event.timespan[0];
      })
    );

    const options = computed(() => {
      return results.value?.map((r) => ({
        label: r.event.name,
        value: r.id,
      }));
    });

    const value = ref(options.value[0]?.value);
    const filterValue = ref("");

    const filteredProjects = computed(() => {
      const currResults = results.value?.filter((r) => r.id == value.value);
      if (currResults?.length < 1) return [];

      // get last nonnull round
      let mostRecent = currResults[0].rounds.length - 1;
      while (!currResults[0].rounds[mostRecent] && mostRecent >= 0)
        mostRecent--;

      if (mostRecent < 0) return [];

      const ret = [...currResults[0].rounds[mostRecent].projects]
        ?.sort((a, b) => {
          if (
            a.discRanking == b.discRanking &&
            a.disciplines.length == b.disciplines.length
          ) {
            for (let index = 0; index < a.disciplines.length; index++) {
              if (a.disciplines[index] < b.disciplines[index]) return -1;
              else if (a.disciplines[index] > b.disciplines[index]) return 1;
            }
            return a.name < b.name ? -1 : 1;
          } else if (a.discRanking == b.discRanking) {
            return a.disciplines.length - b.disciplines.length;
          } else {
            return a.discRanking - b.discRanking;
          }
        })
        .filter((p) => {
          const filter = filterValue.value?.toLowerCase();
          if (p.name.toLowerCase().includes(filter)) return true;

          let flag = false;
          p.disciplines.forEach(
            (d) => (flag |= d.toLowerCase().includes(filter))
          );
          p.sponsors.forEach((d) => (flag |= d.toLowerCase().includes(filter)));
          p.students.forEach((d) => (flag |= d.toLowerCase().includes(filter)));

          return flag;
        });
      return ret;
    });

    return {
      filteredProjects,
      results,
      value,
      options,
      filterValue,
    };
  },
});
</script>
