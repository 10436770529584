<template>
  <div v-if="isAllLoaded && eventObject">
    <n-card
      class="adminDashboardCard"
      :bordered="false"
      title="Dashboard"
      style="margin-bottom: 5px"
    >
      <template
        #header-extra
        v-if="eventObject.status == 'Active' && !eventObject.eventOngoing"
      >
        <div class="primaryButton">
          <n-button @click="startEvent" :loading="isLoading">
            Start Event
          </n-button>
        </div>
      </template>
      <n-grid :cols="2">
        <n-gi>{{ eventObject.name }}</n-gi>
        <n-gi class="editEventButton">
          <edit-event-button :event-object="eventObject" @name="nameChanged" />
        </n-gi>
      </n-grid>
      <div class="adminDashboardDayCard">
        <n-card
          :bordered="false"
          title="Event Management"
          v-if="eventObject.eventOngoing || archivedEvent"
        >
          <div
            :class="[
              isMobileView()
                ? 'adminEventManagementMobile'
                : 'adminEventManagementDesktop',
            ]"
          >
            <n-grid :cols="getDayResponsiveCols()">
              <n-gi
                v-for="index in numDays - eventObject.currentDay"
                :key="index"
              >
                <div
                  :class="[
                    isCurrentDay(index + eventObject.currentDay - 1) &&
                    eventObject.eventOngoing
                      ? 'eventDayCardCurrent'
                      : 'eventDayCard',
                  ]"
                >
                  <n-card
                    @click="handleDayClick(index + eventObject.currentDay - 1)"
                    :hoverable="
                      !isFutureDay(index + eventObject.currentDay - 1) &&
                      !cardDisabled(index + eventObject.currentDay - 1)
                    "
                  >
                    <p class="eventDayCardDayText">
                      Day {{ getDay(index + eventObject.currentDay) }}
                    </p>
                    <p class="eventDayCardDateText">
                      {{ getDateLabel(index + eventObject.currentDay) }}
                    </p>
                    <p class="eventDayCardModalityText">
                      {{
                        eventObject.modality[index + eventObject.currentDay - 1]
                      }}
                    </p>
                  </n-card>
                </div>
              </n-gi>
            </n-grid>
            <n-grid :cols="getDayResponsiveCols()">
              <n-gi
                v-for="roundIndx in eventObject.currentRound"
                :key="roundIndx"
              >
                <div class="eventDayCard">
                  <n-card>
                    <p class="eventDayCardDateText">
                      Round {{ roundIndx }} Results
                    </p>
                    <div class="primaryButtonMini mt-4">
                      <n-button @click="getRoundBallots(roundIndx - 1)">
                        Ballots
                      </n-button>
                    </div>
                    <div class="primaryButtonMini mt-4">
                      <n-button @click="getRoundProjects(roundIndx - 1)">
                        Projects
                      </n-button>
                    </div>
                  </n-card>
                </div>
              </n-gi>
              <n-gi>
                <div class="eventDayCard">
                    <n-card>
                        <p class="eventDayCardDateText">All Results</p>
                        <div class="primaryButtonMini mt-4">
                            <n-button @click="getAllBallots(roundIndx - 1)">
                                Ballots
                            </n-button>
                        </div>
                        <div class="primaryButtonMini mt-4">
                            <n-button @click="getAllProjects(roundIndx - 1)">
                                Projects
                            </n-button>
                        </div>

                        <div class="primaryButtonMini mt-4">
                            <n-button @click="getProjectBallots()">
                                Project Ballots
                            </n-button>
                        </div>

                    </n-card>
                </div>
              </n-gi>
            </n-grid>
          </div>
        </n-card>
        <n-card :bordered="false" title="General Event Information">
          <div class="generalEventInfoGrid">
            <n-grid :cols="getResponsiveCols()">
              <n-gi>
                <span class="generalEventItem"> Timespan </span>
              </n-gi>
              <n-gi> {{ getTimespanString }}</n-gi>
              <n-gi> <span class="generalEventItem"> Teams </span></n-gi
              ><n-gi> {{ projectCount }}</n-gi>
              <n-gi> <span class="generalEventItem"> Judges </span></n-gi
              ><n-gi> {{ judgeCount }}</n-gi>
            </n-grid>
          </div>
        </n-card>
        <n-card :bordered="false" title="Event Disciplines">
          <div class="eventDisciplines">
            <n-space>
              <n-tag v-for="disc in eventObject.disciplines" :key="disc">
                {{ disc }}
              </n-tag>
            </n-space>
          </div>
        </n-card>
      </div>
    </n-card>
  </div>
  <div v-else-if="errorWithEvent">
    <Error />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useWindowSize } from "vue-window-size";
import { useMessage } from "naive-ui";
import { useStore } from "vuex";
import EditEventButton from "@/components/EditEventButton.vue";
import DateFormatters from "@/helpers/DateFormatters.js";
import Error from "../../404.vue";

export default defineComponent({
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
      errorWithEvent: ref(false),
    };
  },
  components: {
    "edit-event-button": EditEventButton,
    Error,
  },
  computed: {
    eventsLoaded() {
      return this.$store.getters.eventsLoaded;
    },
    projectCount() {
      return this.eventObject.numProjects ?? 0;
    },
    judgeCount() {
      return this.eventObject.numJudges ?? 0;
    },
    disciplinesLoaded() {
      return this.$store.getters.disciplinesLoaded;
    },
    isAllLoaded() {
      const ret = this.eventsLoaded && this.disciplinesLoaded;
      return ret;
    },
    eventObject() {
      return this.$store.getters.getEventWithName(this.$route.params.eventName);
    },
    inactiveEvent() {
      return this.eventObject.status == "Inactive";
    },
    archivedEvent() {
      return this.eventObject.status == "Archived";
    },
    numDays() {
      return DateFormatters.getLength(this.eventObject.timespan);
    },
    getTimespanString() {
      return (
        DateFormatters.formatDate(
          DateFormatters.getDayFromString(this.eventObject.timespan[0])
        ) +
        " - " +
        DateFormatters.formatDate(
          DateFormatters.getDayFromString(this.eventObject.timespan[1])
        )
      );
    },
  },
  watch: {
    isAllLoaded(newValue) {
      if (newValue && !this.eventObject) this.errorWithEvent = true;
    },
  },
  methods: {
    isMobileView() {
      if (this.windowWidth <= 500) {
        return true;
      } else {
        return false;
      }
    },
    getResponsiveCols() {
      if (this.windowWidth <= 1000) {
        return 1;
      } else {
        return 2;
      }
    },
    getDayResponsiveCols() {
      if (this.windowWidth > 1320) {
        return 7;
      } else if (this.windowWidth <= 1320 && this.windowWidth > 1200) {
        return 6;
      } else if (this.windowWidth <= 1200 && this.windowWidth > 1000) {
        return 5;
      } else if (this.windowWidth <= 1000 && this.windowWidth > 800) {
        return 4;
      } else if (this.windowWidth <= 800 && this.windowWidth > 610) {
        return 3;
      } else if (this.windowWidth <= 610 && this.windowWidth > 500) {
        return 2;
      } else {
        return 1;
      }
    },
    getDateLabel(index) {
      return DateFormatters.formatDate(
        DateFormatters.incrementDayBy(
          new Date(this.eventObject.timespan[0]),
          index - 1
        )
      );
    },
    getDay(number) {
      return number;
    },
    handleDayClick(index) {
      if (this.cardDisabled(index)) return;

      if (this.isCurrentDay(index)) this.goToDay(index);
      else if (this.isFutureDay(index)) this.showFutureDayMessage(index);
    },
    isPastDay(index) {
      return this.eventObject.currentDay > index;
    },
    isCurrentDay(index) {
      return this.eventObject.currentDay == index;
    },
    isFutureDay(index) {
      return this.eventObject.currentDay < index;
    },
    cardDisabled(index) {
      if (this.isPastDay(index) && this.eventObject.status == "Archived")
        return false;
      return this.eventObject.status != "Active" && !this.isPastDay(index);
    },
    goToDay(index) {
      this.$router.push({
        name: "DayAdminDashboard",
        params: {
          eventName: this.eventObject.name,
          dayIndex: index,
        },
      });
    },
    nameChanged(name) {
      this.$router.push({
        name: "EventAdminDashboard",
        params: {
          eventName: name,
        },
      });
    },
    getRoundBallots(index) {
      let url = null,
        link = null;

      this.$store
        .dispatch("downloadBallots", {
          eventId: this.eventObject.id,
          round: index,
        })
        .then((r) => {
          url = window.URL.createObjectURL(new Blob([r.data]));
          link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "day_" + index + "_ballots.csv");
          link.click();
        });
    },
    getRoundProjects(index) {
      let url = null,
        link = null;

      this.$store
        .dispatch("downloadProjects", {
          eventId: this.eventObject.id,
          round: index,
        })
        .then((r) => {
          url = window.URL.createObjectURL(new Blob([r.data]));
          link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "day_" + index + "_projects.csv");
          link.click();
        });
    },
    getAllBallots() {
      const eventName = this.eventObject.name
        .toLowerCase()
        .replaceAll(" ", "_");
      let url = null,
        link = null;

      this.$store
        .dispatch("downloadBallots", {
          eventId: this.eventObject.id,
        })
        .then((r) => {
          url = window.URL.createObjectURL(new Blob([r.data]));
          link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", eventName + "_ballots.csv");
          link.click();
        });
      },
      getProjectBallots() {
          const eventName = this.eventObject.name
              .toLowerCase()
              .replaceAll(" ", "_");
          let url = null,
              link = null;

          this.$store
              .dispatch("downloadProjectBallots", {
                  eventId: this.eventObject.id,
              })
              .then((r) => {
                  url = window.URL.createObjectURL(new Blob([r.data]));
                  link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", eventName + "_ProjectBallots.csv");
                  link.click();
              });
      },
    getAllProjects() {
      const eventName = this.eventObject.name
        .toLowerCase()
        .replaceAll(" ", "_");
      let url = null,
        link = null;

      this.$store
        .dispatch("downloadProjects", {
          eventId: this.eventObject.id,
        })
        .then((r) => {
          url = window.URL.createObjectURL(new Blob([r.data]));
          link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", eventName + "_projects.csv");
          link.click();
        });
    },
  },
  async created() {
    if (!this.eventsLoaded)
      this.$store.dispatch("APIHelper", { name: "fetchAllEvents" });
    if (!this.disciplinesLoaded)
      this.$store.dispatch("APIHelper", { name: "fetchAllDisciplines" });
  },
  setup() {
    const store = useStore();
    const message = useMessage();

    const showFutureDayMessage = () => {
      message.error("This is not the current day");
    };

    const isLoading = ref(false);
    const startEvent = () => {
      isLoading.value = true;
      store
        .dispatch("startActiveEvent")
        .finally(() => (isLoading.value = false));
    };

    return {
      showFutureDayMessage,
      isLoading,
      startEvent,
    };
  },
});
</script>
