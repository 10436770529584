<template>
  <div
    class="ProjectDetails"
    v-if="$store.getters.judgesLoaded"
    @click="toggle ^= true"
  >
    <n-card class="ballotDetailsCard">
      <n-grid :cols="2">
        <n-gi className="ballotDetailsDisciplines">{{ disciplines }} </n-gi>
        <n-gi className="ballotDetailsSubmittedVal">
          <p>
            <span className="ballotDetailsNumCompleted">{{
              numCompleted
            }}</span>
            / {{ numTotal }} Ballots Submitted
          </p>
        </n-gi>
      </n-grid>
      <n-divider />
      <p className="ballotDetailsName">{{ projectObject.name }}</p>
      <div class="ballotDetailsDescription">
        <div>
          <p class="ballotDetailsSubdescription">
            <span class="ballotDetailsSubtext">Sponsors(s)</span>
            <span>
              {{ sponsors }}
            </span>
          </p>
          <p class="ballotDetailsSubdescription">
            <span class="ballotDetailsSubtext">Team Members</span>
            <span>{{ team }}</span>
          </p>
        </div>
        <div class="primaryButton ballotDetailsToggleButton">
          <n-button @click.stop="toggle ^= true">{{ getText }}</n-button>
        </div>
      </div>
      <div v-show="toggle">
        <p className="ballotCardJudgeManagement">Judge Management</p>
        <n-grid :cols="2">
          <n-gi>
            <div className="eventJudgesDataTable">
              <n-data-table
                ref="table"
                :columns="columns"
                :data="data"
                :pagination="pagination"
                @update:page="toggle ^= true"
              >
                <template #empty>
                  This project has no judges assigned.</template
                >
              </n-data-table>
            </div>
          </n-gi>
          <n-gi className="judgeManagementButtons">
            <span class="primaryButton">
              <n-button
                @click.stop="showAddJudgeModal = true"
                :disabled="!activeEvent.dayOngoing"
              >
                Add Judge
              </n-button>
            </span>
            <span>
              <n-button
                @click.stop="showRemoveProjectModal = true"
                type="error"
                v-if="activeEvent.currentRound > 0"
                :loading="updateProjectsLoading"
              >
                De-Advance Project
              </n-button>
            </span>
          </n-gi>
        </n-grid>
      </div>
    </n-card>
    <div class="primaryModal">
      <n-modal
        :class="[isMobileView() ? 'popoverModalMobile' : 'popoverModal']"
        v-model:show="showRemoveProjectModal"
        preset="card"
        :title="
          'Remove ' +
          projectObject.name +
          ' from Round ' +
          (projectObject.round + 1)
        "
        :key="projectObject.id"
      >
        <div className="removeProjModalBody">
          <p>
            Warning: Removing this project will delete all associated ballots
            for this round. This action cannot be undone.
          </p>
          <n-select
            placeholder="Optional: Select Project(s) to Replace"
            v-model:value="newProjects"
            :options="getAvailableProjects"
            multiple
            filterable
          >
            <template #empty> There are no projects to add. </template>
          </n-select>
        </div>
        <div className="addJudgeRemoveProjButton">
          <n-button @click="updateProjects()" type="error">
            {{ projectModalText }}
          </n-button>
        </div>
      </n-modal>
    </div>
    <div class="primaryModal" v-if="$store.getters.judgesLoaded">
      <n-modal
        class="popoverModal"
        v-model:show="showAddJudgeModal"
        preset="card"
        :title="'Add Judge(s) to ' + projectObject.name"
        :key="projectObject.id"
      >
        <n-select
          placeholder="Select Judges"
          v-model:value="newJudges"
          :options="getAvailableJudges"
          multiple
          filterable
        >
          <template #empty>
            <div v-if="!conflictsEnabled">
              There are no judges available for this project. Really need a
              judge?
              <div class="primaryButton enableConflictsButton">
                <n-button @click="enableConflicts()">
                  Enable Judges With Conflicts
                </n-button>
              </div>
            </div>
            <div v-else>There are no judges available for this project.</div>
          </template>
        </n-select>
        <n-checkbox-group v-model:value="notify" class="mt-2">
          <n-checkbox :value="true"> Notify Judges </n-checkbox>
        </n-checkbox-group>
        <div class="primaryButton addJudgeRemoveProjButton">
          <n-button
            @click="assignBallots()"
            :loading="assignIsLoading"
            :disabled="newJudges.length < 1"
          >
            Assign Ballots
          </n-button>
        </div>
      </n-modal>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, h } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { BellIcon } from "@heroicons/vue/outline";
import { NButton, useMessage } from "naive-ui";
import { useWindowSize } from "vue-window-size";

export default defineComponent({
  props: {
    projectObject: Object,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  methods: {
    isMobileView() {
      if (this.windowWidth <= 1000) {
        return 1;
      } else {
        return 2;
      }
    },
  },
  computed: {
    disciplines() {
      return this.projectObject.disciplines.toString().replaceAll(",", ", ");
    },
    sponsors() {
      return this.projectObject.sponsors.toString().replaceAll(",", ", ");
    },
    team() {
      return this.projectObject.students.toString().replaceAll(",", ", ");
    },
    getText() {
      return this.toggle ? "Show Less" : "Show More";
    },
    projectModalText() {
      return this.newProjects.length > 0 ? "Delete & Replace" : "Delete";
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const message = useMessage();

    const activeEvent = computed(() => {
      return store.getters.getActiveEvent;
    });

    const ballots = computed(() => {
      return store.getters.getBallotsByProject(props.projectObject.id);
    });

    const conflictsEnabled = ref(false);
    const enableConflicts = () => (conflictsEnabled.value = true);

    const oldJudges = ref([]);

    const getAvailableJudges = computed(() => {
      return [...store.getters.getJudgesOnDay(parseInt(route.params.dayIndex))]
        ?.filter((j) => {
          if (oldJudges.value?.includes(j.id)) return false;
          if (conflictsEnabled.value) return true;

          let flag = false;
          props.projectObject.sponsors.forEach(
            (s) => (flag |= j.affiliations.includes(s))
          );
          return !flag;
        })
        .sort((a, b) => {
          let aFlag = 0,
            bFlag = 0;
          props.projectObject.disciplines.forEach((d) => {
            if (a.disciplines.includes(d)) aFlag++;
            if (b.disciplines.includes(d)) bFlag++;
          });

          if (aFlag == bFlag) {
            return a.name < b.name;
          } else if (aFlag > bFlag) {
            return -1;
          } else {
            return 1;
          }
        })
        .map((j) => ({ label: j.name, value: j.id }));
    });

    const didClickNotification = (recipientId) => {
      emit("send", recipientId);
    };

    const table = ref(null);
    const columns = [
      {
        title: "Judge",
        key: "judge",
      },
      {
        title: "Status",
        key: "status",
        width: 150,
      },
      {
        key: "notif",
        width: 50,
        render(row) {
          return h(
            "div",
            { class: "primaryButton" },
            h(
              NButton,
              {
                size: "small",
                onClick: (e) => {
                  e.stopPropagation();
                  didClickNotification(row.judgeId);
                },
              },
              { icon: () => h(BellIcon) }
            )
          );
        },
      },
    ];

    const newJudges = ref([]);
    const newProjects = ref([]);

    const resultsFromPrevRound = computed(() => {
      if (!activeEvent.value) return null;

      const round = activeEvent.value.currentRound;

      if (round == 0) return null;

      const id = activeEvent.value.id;
      const projects =
        store.getters.getResultsForEvent(id).rounds[round - 1]?.projects;

      let ret = {};

      projects?.forEach((p) => (ret[p.id] = p));

      return ret;
    });

    const getAvailableProjects = computed(() => {
      let ret = store.getters.getAllProjects;
      const currRoundProjects = store.getters
        .getAllProjectsOfRound(activeEvent.value.currentRound)
        ?.map((p) => p.id);

      ret = ret?.filter((p) => !currRoundProjects.includes(p.id));

      ret?.sort((a, b) => {
        const aDisc =
          resultsFromPrevRound.value[a.id]?.disciplines.length > 1
            ? "Interdisciplinary"
            : resultsFromPrevRound.value[a.id]?.disciplines[0];
        const bDisc =
          resultsFromPrevRound.value[b.id]?.disciplines.length > 1
            ? "Interdisciplinary"
            : resultsFromPrevRound.value[b.id]?.disciplines[0];

        if (aDisc != bDisc) {
          return aDisc < bDisc ? -1 : 1;
        } else {
          return a.discRanking - b.discRanking;
        }
      });

      return ret?.map((p) => ({
        value: p.id,
        label:
          p.name +
          " (#" +
          resultsFromPrevRound.value[p.id]?.discRanking +
          " in " +
          (resultsFromPrevRound.value[p.id]?.disciplines.length > 1
            ? "Interdisciplinary"
            : resultsFromPrevRound.value[p.id]?.disciplines[0]) +
          ")",
      }));
    });

    const idMap = {};
    const data = computed(() => {
      return ballots.value?.map((b) => {
        const judge = idMap[b.judgeId]
          ? idMap[b.judgeId]
          : (idMap[b.judgeId] = store.getters.getJudgeWithId(b.judgeId).name);

        oldJudges.value.push(b.judgeId);

        return {
          id: b.id,
          judge: judge,
          judgeId: b.judgeId,
          status: b.completed ? "Complete" : "Incomplete",
        };
      });
    });

    const numCompleted = computed(() => {
      let ret = 0;
      data.value.forEach((b) => (b.status == "Complete" ? ret++ : ret));
      return ret;
    });

    const numTotal = computed(() => {
      return data.value.length;
    });

    const showAddJudgeModal = ref(false);
    const showRemoveProjectModal = ref(false);
    const notify = ref([true]);

    const assignIsLoading = ref(false);
    const assignBallots = () => {
      assignIsLoading.value = true;
      newJudges.value.forEach(
        async (j) =>
          await store
            .dispatch("assignBallotToJudge", {
              judgeId: j,
              projectId: props.projectObject.id,
            })
            .then(() => {
              if (notify.value[0]) {
                store.dispatch("sendNotificationToJudge", {
                  judgeId: j,
                  notification: {
                    title: "New Ballot Assigned!",
                    body:
                      "You have been assigned a ballot for " +
                      props.projectObject.name +
                      ".",
                  },
                });
              }
            })
      );
      newJudges.value = [];
      assignIsLoading.value = false;
      showAddJudgeModal.value = false;
    };

    const updateProjectsLoading = ref(false);

    const updateProjects = async () => {
      showRemoveProjectModal.value = false;
      updateProjectsLoading.value = true;

      const doTheThings = async () => {
        store
          .dispatch("deadvanceProject", props.projectObject.id)
          .then(() => message.success("Deadvanced " + props.projectObject.name))
          .catch(() => message.error("Deadvance Failed"));
        newProjects.value.forEach((id) =>
          store
            .dispatch("advanceProject", id)
            .then(() =>
              message.success(
                "Advanced " + resultsFromPrevRound.value[id]?.name
              )
            )
            .catch(() =>
              message.error(
                "Advance " + resultsFromPrevRound.value[id]?.name + " Failed"
              )
            )
        );
      };

      await doTheThings();

      newProjects.value = [];
      updateProjectsLoading.value = false;
    };

    return {
      activeEvent,
      toggle: ref(false),
      table,
      columns,
      data,
      showAddJudgeModal,
      numCompleted,
      numTotal,
      getAvailableJudges,
      newJudges,
      pagination: {
        pageSize: 3,
      },
      enableConflicts,
      assignBallots,
      assignIsLoading,
      showRemoveProjectModal,
      newProjects,
      getAvailableProjects,
      updateProjects,
      updateProjectsLoading,
      notify,
    };
  },
});
</script>
