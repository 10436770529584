<template>
  <div class="judgeWelcomeCard">
    <n-card title="Welcome!" :bordered="false" class="judgeDashboardCard">
      <h1 class="judgeCheckinSubheader">Review Your Event</h1>
      <h2 class="judgeCheckinDescription">
        Please review the information below to confirm everything is correct.
      </h2>
      <div class="judgeCheckinBody">
        <div class="judgeCheckinGrid">
          <n-grid :cols="getResponsiveCols()">
            <n-gi class="welcomePrimaryText">Name</n-gi>
            <n-gi>{{ getAccount.name }}</n-gi>
            <n-gi class="welcomePrimaryText">Status</n-gi>
            <n-gi>Judge</n-gi>
            <n-gi class="welcomePrimaryText">Event</n-gi>
            <n-gi>{{ getActiveEvent.name }}</n-gi>
            <n-gi class="welcomePrimaryText">Date</n-gi>
            <n-gi>{{ getDate }}</n-gi>
          </n-grid>
        </div>
        <h2 class="italicSubtext">
          Note that Name can be modified in Settings after the check in process
          is complete.
        </h2>
      </div>
      <div
        :class="[isMobileView() ? 'confirmGridMobile' : 'confirmGridDesktop']"
      >
        <n-grid :cols="1">
          <n-gi>
            <div class="primaryButton">
              <n-button @click="emitNextEvent()"> Continue </n-button>
            </div>
          </n-gi>
          <n-gi>
            <div class="secondaryButton mt-3">
              <n-button @click="emitCancelCheckin()">
                I cannot attend this event
              </n-button>
            </div>
          </n-gi>
        </n-grid>
      </div>
    </n-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useWindowSize } from "vue-window-size";

import DateFormatters from "@/helpers/DateFormatters.js";

export default defineComponent({
  emits: ["next", "cancel"],
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  computed: {
    getAccount() {
      return this.$store.getters.getAccount;
    },
    getActiveEvent() {
      return this.$store.getters.getEventsWithStatus("Active")[0];
    },
    getDate() {
      return DateFormatters.getDateRange(this.getActiveEvent.timespan);
    },
  },
  methods: {
    isMobileView() {
      if (this.windowWidth <= 900) {
        return true;
      } else {
        return false;
      }
    },
    getResponsiveCols() {
      if (this.windowWidth <= 900) {
        return 1;
      } else {
        return 2;
      }
    },
    emitNextEvent() {
      this.$emit("next");
    },
    emitCancelCheckin() {
      this.$emit("cancel");
    },
  },
  setup() {},
});
</script>
