<template>
  <div>
    <p class="settingsSubheader">{{ $strings.ACCOUNT_HEADER }}</p>
    <p class="settingsDescription">{{ $strings.ACCOUNT_DESCRIPTION }}</p>
    <div class="settingsBody">
      <n-grid :cols="getResponsiveCols()" :x-gap="24">
        <n-gi>
          <n-input
            :placeholder="$strings.FILTER_BY_NAME"
            v-model:value="nameFilterString"
            @update:value="filterNames"
            clearable
          />
        </n-gi>
        <n-gi>
          <n-select
            :placeholder="$strings.FILTER_BY_DISCIPLINE"
            v-model:value="disciplineFilterList"
            v-bind:options="disciplineOptions"
            @update:value="filterDisciplines"
            multiple
            clearable
            filterable
          />
        </n-gi>
      </n-grid>
    </div>
    <n-data-table
      class="mt-3"
      ref="table"
      :columns="columns"
      :data="data"
      :pagination="pagination"
      v-if="$store.getters.instructorsLoaded"
    />
    <div class="primaryButton">
      <n-button @click="goToAzure">
        <PlusCircleIcon class="h-3 w-3 mr-1" />
        {{ $strings.ADD_INSTRUCTOR }}
      </n-button>
    </div>
  </div>
</template>

<script>
import { ref, h, defineComponent, computed } from "vue";
import { NSelect } from "naive-ui";
import { PlusCircleIcon } from "@heroicons/vue/outline";
import { useStore } from "vuex";
import { useWindowSize } from "vue-window-size";

const createColumns = (disciplineOptions, { handleChangeTags }) => {
  return [
    {
      title: "Name",
      key: "name",
      width: "200",
      filter(value, row) {
        return row.name.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Discipline(s)",
      key: "disciplines",
      filter(value, row) {
        for (let disc in row.disciplines) {
          if (row.disciplines[disc] == value) {
            return true;
          }
        }
        return false;
      },
      render(row) {
        const tags = h(NSelect, {
          value: row.disciplines,
          onUpdateValue: (v) => handleChangeTags(row, v),
          options: disciplineOptions.value,
          filterable: true,
          multiple: true,
        });
        return tags;
      },
    },
  ];
};

export default defineComponent({
  components: {
    PlusCircleIcon,
  },
  methods: {
    isMobileView() {
      if (this.windowWidth <= 650) {
        return true;
      } else {
        return false;
      }
    },
    getResponsiveCols() {
      if (this.windowWidth <= 650) {
        return 1;
      } else {
        return 2;
      }
    },
    goToAzure() {
      window.open("https://portal.azure.com/");
    },
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      resultsFetched: false,
      windowWidth: width,
      windowHeight: height,
      collapsed: ref(this.isMobileView),
    };
  },
  setup() {
    const store = useStore();
    const disciplineList = computed(() => {
      return store.getters.getAllDisciplines;
    });
    const instructors = computed(() => {
      return store.getters.getAllInstructors;
    });
    const data = computed(() => {
      return instructors.value.map((v) => ({
        id: v.id,
        name: v.name,
        disciplines: v.disciplines,
      }));
    });
    const tableRef = ref(null);
    const disciplineFilterList = ref(null);
    const nameFilterString = ref(null);
    const disciplineOptions = computed(() =>
      disciplineList.value.map((v) => ({
        label: v.name,
        value: v.name,
      }))
    );

    return {
      data: data,
      table: tableRef,
      columns: createColumns(disciplineOptions, {
        handleChangeTags(rowData, newDisciplines) {
          rowData.disciplines = newDisciplines;
          store.dispatch("editInstructor", rowData);
        },
      }),
      pagination: {
        pageSize: 10,
      },
      disciplineOptions: disciplineOptions,
      disciplineFilterList: disciplineFilterList,
      nameFilterString: nameFilterString,
      filterDisciplines: () => {
        tableRef.value.filter({
          disciplines: disciplineFilterList.value,
        });
      },
      filterNames: () => {
        tableRef.value.filter({
          name: nameFilterString.value,
        });
      },
    };
  },
});
</script>
