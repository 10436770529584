<template>
  <div v-if="allIsLoaded">
    <n-card
      class="adminDashboardCard"
      :bordered="false"
      title="Dashboard"
      style="margin-bottom: 5px"
    >
      <template #header-extra>
        <div :class="[isMobileView() ? 'hidden' : 'visible']">
          <n-grid :cols="2">
            <n-gi>
              <div class="primaryButton">
                <n-button
                  @click="showUpload = true"
                  :disabled="eventObject.eventOngoing"
                >
                  Upload File
                </n-button>
              </div>
            </n-gi>
            <n-gi>
              <div class="primaryButton">
                <n-button
                  @click="showManual = true"
                  :disabled="eventObject.eventOngoing"
                >
                  Add Manually
                </n-button>
              </div>
            </n-gi>
          </n-grid>
        </div>
      </template>
      <div :class="[isMobileView() ? 'visible' : 'hidden']">
        <n-grid :cols="2">
          <n-gi>
            <div class="primaryButton">
              <n-button
                @click="showUpload = true"
                :disabled="eventObject.eventOngoing"
              >
                Upload File
              </n-button>
            </div>
          </n-gi>
          <n-gi class="projectAddManually">
            <div class="primaryButton">
              <n-button
                @click="showManual = true"
                :disabled="eventObject.eventOngoing"
              >
                Add Manually
              </n-button>
            </div>
          </n-gi>
        </n-grid>
      </div>
      <div class="instructorText" v-if="eventObject.eventOngoing">
        View Only Mode - Event Ongoing
      </div>
      <div class="instructorText">
        Showing {{ disciplineFilterList }} Projects in {{ eventObject.name }}
      </div>
      <n-grid :cols="getResponsiveCols()">
        <n-gi>
          <div
            :class="[
              isMobileView() ? 'filterFieldMobile' : 'filterFieldDesktop',
            ]"
          >
            <n-input
              placeholder="Filter by Name, Students, Sponsor"
              v-model:value="nameFilterString"
              @update:value="filterNames"
            />
          </div>
        </n-gi>
        <n-gi :class="[isMobileView() ? 'filterFieldMobile' : '']">
          <n-select
            :placeholder="$strings.FILTER_BY_DISCIPLINE"
            v-model:value="disciplineFilterList"
            v-bind:options="disciplineOptions"
          />
        </n-gi>
      </n-grid>
      <div
        :class="[
          isMobileView()
            ? 'eventProjectsDataTableMobile'
            : 'eventProjectsDataTable',
        ]"
      >
        <n-data-table
          class="mt-3"
          ref="table"
          :columns="columns"
          :data="data"
          :pagination="pagination"
        />
      </div>
    </n-card>
    <div class="primaryModal">
      <n-modal
        :class="[
          isMobileView()
            ? 'adminProjectsModalMobile'
            : 'adminProjectsModalDesktop',
        ]"
        v-model:show="showUpload"
        preset="card"
        title="Upload File"
      >
        <template #header-extra>
          <file-download
            class="mr-4"
            file="projectCSV.csv"
            text="Download Template"
          />
        </template>
        <upload-file
          @close="closeUpload"
          :event-id="eventObject.id"
          :event-name="eventObject.name"
        />
      </n-modal>
    </div>
    <div class="primaryModal">
      <n-modal
        :class="[
          isMobileView()
            ? 'adminProjectsModalMobile'
            : 'adminProjectsModalDesktop',
        ]"
        v-model:show="showManual"
        preset="card"
        :title="!!currProject ? 'Edit Project' : 'Add Project'"
        @after-leave="currProject = null"
      >
        <add-manual
          @close="closeManual"
          :currProject="currProject"
          :eventName="eventObject.name"
          :instructor-disciplines="disciplineOptions.map((d) => d.value)"
        />
      </n-modal>
    </div>
    <confirmation-dialog
      v-if="showConfirm"
      :message="
        'Clicking yes confirms you want to delete &ldquo;' +
        currProject.name +
        '&rdquo;? This action cannot be undone.'
      "
      @handlePositive="deleteProject"
      @handleNegative="
        showConfirm = false;
        currProject = null;
      "
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, h } from "vue";
import { TrashIcon, PencilAltIcon } from "@heroicons/vue/outline";
import { useStore } from "vuex";
import { NButton, NTag } from "naive-ui";
import UploadFile from "@/components/add-info-modals/UploadFile.vue";
import AddManual from "@/components/add-info-modals/AddManualProject.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import { useWindowSize } from "vue-window-size";
import FileDownload from "@/components/FileDownload.vue";

const createColumns = ({ handleRemoveProject, handleEditProject }) => {
  return [
    {
      title: "Name",
      key: "name",
      width: "200",
      filter(value, row) {
        return (
          row.name.toLowerCase().includes(value.toLowerCase()) ||
          row.students.toLowerCase().includes(value.toLowerCase()) ||
          row.sponsors.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Team Members",
      key: "students",
      width: "200",
    },
    {
      title: "Discipline(s)",
      key: "disciplines",
      width: "200",
      filter(value, row) {
        for (let disc in row.disciplines) {
          if (row.disciplines[disc] == value) {
            return true;
          }
        }
        return false;
      },
      render(row) {
        const tags = row.disciplines.map((d) => {
          return h(
            NTag,
            {
              style: {
                marginRight: "6px",
              },
            },
            {
              default: () => d,
            }
          );
        });
        return tags;
      },
    },
    {
      title: "Sponsor(s)",
      key: "sponsors",
      width: "200",
    },
    {
      title: "Video URL",
      key: "vidLink",
      width: "200",
      render(row) {
        return h("a", { href: row.vidLink }, { default: () => row.vidLink });
      },
    },
    {
      key: "edit",
      width: "50",
      render(row) {
        return h(
          "div",
          { class: "primaryButton" },
          h(
            NButton,
            {
              size: "small",
              onClick: () => handleEditProject(row),
            },
            { icon: () => h(PencilAltIcon) }
          )
        );
      },
    },
    {
      key: "remove",
      width: "50",
      render(row) {
        return h(
          "div",
          { class: "primaryButton" },
          h(
            NButton,
            {
              size: "small",
              onClick: () => handleRemoveProject(row),
            },
            { icon: () => h(TrashIcon) }
          )
        );
      },
    },
  ];
};

export default defineComponent({
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  components: {
    "file-download": FileDownload,
    "upload-file": UploadFile,
    "add-manual": AddManual,
    "confirmation-dialog": ConfirmationDialog,
  },
  methods: {
    isMobileView() {
      if (this.windowWidth <= 1000) {
        return true;
      } else {
        return false;
      }
    },
    getResponsiveCols() {
      if (this.windowWidth <= 1000) {
        return 1;
      } else {
        return 2;
      }
    },
    closeUpload() {
      this.showUpload = false;
    },
    closeManual() {
      this.showManual = false;
      this.currProject = null;
    },
  },
  computed: {
    allIsLoaded() {
      return (
        this.$store.getters.projectsLoaded && this.$store.getters.eventsLoaded
      );
    },
  },
  async created() {
    if (!this.$store.getters.eventsLoaded) {
      this.$store
        .dispatch("APIHelper", { name: "fetchActiveEvent" })
        .then(() => {
          this.$store.dispatch("APIHelper", {
            name: "fetchAllProjects",
            params: this.eventObject.name,
          });
        });
    } else {
      this.$store.dispatch("APIHelper", {
        name: "fetchAllProjects",
        params: this.eventObject.name,
      });
    }
  },
  watch: {
    disciplineOptions(newValue) {
      this.disciplineFilterList = newValue[0]?.label;
    },
  },
  setup() {
    const store = useStore();

    const eventObject = computed(() => {
      return store.getters.getActiveEvent;
    });

    const account = store.getters.getAccount;
    const disciplines = account.disciplines;
    const disciplineFilterList = ref(null);

    const projects = computed(() => {
      return store.getters.getAllProjects.filter((p) =>
        p.disciplines.includes(disciplineFilterList.value)
      );
    });

    const disciplineOptions = computed(() => {
      const ret = disciplines
        ?.filter((d) => eventObject.value?.disciplines.includes(d))
        .map((v) => ({
          label: v,
          value: v,
        }));
      return ret;
    });
    const data = computed(() => {
      return projects.value.map((v) => ({
        id: v.id,
        name: v.name,
        desc: v.desc,
        students: v.students.toString().replaceAll(",", ", "),
        studentsArray: [...(v.students ?? [])],
        disciplines: v.disciplines,
        sponsors: v.sponsors.toString().replaceAll(",", ", "),
        sponsorsArray: [...(v.sponsors ?? [])],
        vidLink: v.vidLink,
        ballots: v.ballots,
        eventId: v.eventId,
        avgScore: v.avgScore,
        ranking: v.ranking,
        discRanking: v.discRanking,
      }));
    });
    const tableRef = ref(null);
    const nameFilterString = ref(null);
    const currProject = ref(null);
    const showManual = ref(false);
    const showConfirm = ref(false);

    const deleteProject = () => {
      store
        .dispatch("APIHelper", {
          name: "deleteProject",
          params: currProject.value.id,
        })
        .finally(() => {
          currProject.value = null;
          showConfirm.value = false;
        });
    };

    return {
      eventObject,
      data: data,
      table: tableRef,
      currProject,
      showManual,
      showConfirm,
      showUpload: ref(false),
      deleteProject,
      columns: createColumns({
        handleRemoveProject(rowData) {
          showConfirm.value = true;
          currProject.value = rowData;
        },
        handleEditProject(rowData) {
          currProject.value = rowData;
          showManual.value = true;
        },
      }),
      pagination: {
        pageSize: 10,
      },
      disciplineOptions,
      disciplineFilterList,
      nameFilterString: nameFilterString,
      filterNames: () => {
        tableRef.value.filter({
          name: nameFilterString.value,
          sponsor: nameFilterString.value,
          students: nameFilterString.value,
        });
      },
    };
  },
});
</script>
