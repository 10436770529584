<template>
  <div v-if="allIsLoaded">
    <n-card
      class="adminDashboardCard"
      :bordered="false"
      title="Judges"
      style="margin-bottom: 5px"
    >
      <template #header-extra>
        <div :class="[isMobileView() ? 'hidden' : 'visible']">
            <n-grid :cols="3">

              <n-gi>
                    <div class="primaryButton">
                        <n-button @click="getAllProjects()">
                            Export
                        </n-button>
                    </div>
                </n-gi>

                <n-gi>
                    <div class="primaryButton">
                        <n-button @click="showUpload = true"
                                  :disabled="eventObject.status == 'Archived'">
                            Upload File
                        </n-button>
                    </div>
                </n-gi>
                
                <n-gi>
                    <div class="primaryButton">
                        <n-button @click="showManual = true"
                                  :disabled="eventObject.status == 'Archived'">
                            Add Manually
                        </n-button>
                    </div>
                </n-gi>



            </n-grid>
        </div>
      </template>
      <div :class="[isMobileView() ? 'visible' : 'hidden']">
          <n-grid :cols="3">

              <n-gi>
                  <div class="primaryButton">
                      <n-button @click="showUpload = true"
                                :disabled="eventObject.status == 'Archived'">
                          Export
                      </n-button>
                  </div>
              </n-gi>


              <n-gi>
                  <div class="primaryButton">
                      <n-button @click="showUpload = true"
                                :disabled="eventObject.status == 'Archived'">
                          Upload File
                      </n-button>
                  </div>
              </n-gi>
              <n-gi class="projectAddManually">
                  <div class="primaryButton">
                      <n-button @click="showManual = true"
                                :disabled="eventObject.status == 'Archived'">
                          Add Manually
                      </n-button>
                  </div>
              </n-gi>
          </n-grid>
      </div>
      <n-grid :cols="getResponsiveCols()">
        <n-gi>
          <div
            :class="[
              isMobileView() ? 'filterFieldMobile' : 'filterFieldDesktop',
            ]"
          >
            <n-input
              placeholder="Filter by Name"
              v-model:value="nameFilterString"
              @update:value="filterNames"
            />
          </div>
        </n-gi>
        <n-gi :class="[isMobileView() ? 'filterFieldMobile' : '']">
          <n-select
            :placeholder="$strings.FILTER_BY_DISCIPLINE"
            v-model:value="disciplineFilterList"
            v-bind:options="disciplineOptions"
            @update:value="filterDisciplines"
            multiple
            clearable
            filterable
          />
        </n-gi>
      </n-grid>
      <div
        :class="[
          isMobileView()
            ? 'eventJudgesDataTableMobile'
            : 'eventJudgesDataTable',
        ]"
      >
        <n-data-table
          class="mt-3"
          ref="table"
          :columns="columns"
          :data="data"
          :pagination="pagination"
        />
      </div>
    </n-card>
    <div class="primaryModal">
      <n-modal
        :class="[
          isMobileView() ? 'adminJudgesModalMobile' : 'adminJudgesModalDesktop',
        ]"
        v-model:show="showUpload"
        preset="card"
        title="Upload File"
      >
        <template #header-extra>
          <file-download
            class="mr-4"
            file="judgesCSV.csv"
            text="Download Template"
          />
        </template>
        <upload-file
          @close="closeUpload"
          :is-judge="true"
          :event-id="eventObject.id"
          :event-name="eventObject.name"
        />
      </n-modal>
    </div>
    <div class="primaryModal">
      <n-modal
        :class="[
          isMobileView() ? 'adminJudgesModalMobile' : 'adminJudgesModalDesktop',
        ]"
        v-model:show="showManual"
        preset="card"
        :title="!!currJudge ? 'Edit Judge' : 'Add Judge'"
        @after-leave="currJudge = null"
      >
        <add-manual @close="closeManual" :currJudge="currJudge" />
      </n-modal>
    </div>
    <confirmation-dialog
      v-if="showConfirm"
      :message="
        'Clicking yes confirms you want to delete &ldquo;' +
        currJudge.name +
        '&rdquo;? This action cannot be undone.'
      "
      @handlePositive="deleteJudge"
      @handleNegative="
        showConfirm = false;
        currJudge = null;
      "
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, h } from "vue";
import { TrashIcon, PencilAltIcon } from "@heroicons/vue/outline";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { NButton, NTag } from "naive-ui";
import UploadFile from "@/components/add-info-modals/UploadFile.vue";
import AddManual from "@/components/add-info-modals/AddManualJudge.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import { useWindowSize } from "vue-window-size";
import FileDownload from "@/components/FileDownload.vue";

const createColumns = ({ handleRemoveJudge, handleEditJudge }) => {
  return [
    {
      title: "Name",
      key: "name",
      width: "200",
      filter(value, row) {
        return row.name.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: "Email",
      key: "email",
      width: "200",
    },
    {
      title: "Discipline(s)",
      key: "disciplines",
      filter(value, row) {
        for (let disc in row.disciplines) {
          if (row.disciplines[disc] == value) {
            return true;
          }
        }
        return false;
      },
      render(row) {
        const tags = row.disciplines.map((d) => {
          return h(
            NTag,
            {
              style: {
                marginRight: "6px",
              },
            },
            {
              default: () => d,
            }
          );
        });
        return tags;
      },
    },
      {
          title: "Affiliation(s)",
          key: "affiliations",
          filter(value, row) {
              for (let aff in row.affiliations) {
                  if (row.affiliations[aff] == value) {
                      return true;
                  }
              }
              return false;
          },
          render(row) {
              const tags = row.affiliations.map((d) => {
                  return h(
                      NTag,
                      {
                          style: {
                              marginRight: "6px",
                          },
                      },
                      {
                          default: () => d,
                      }
                  );
              });
              return tags;
          },
      },

      {
          title: "Day(s)",
          key: "days",
          filter(value, row) {
              for (let day in row.days) {
                  if (row.days[day] == value) {
                      return true;
                  }
              }
              return false;
          },
          render(row) {
              const tags = row.days.map((d) => {
                  return h(
                      NTag,
                      {
                          style: {
                              marginRight: "6px",
                          },
                      },
                      {
                          default: () => d,
                      }
                  );
              });
              return tags;
          },
      },
      {
      key: "edit",
      width: "50",
      render(row) {
        return h(
          "div",
          { class: "primaryButton" },
          h(
            NButton,
            {
              size: "small",
              onClick: () => handleEditJudge(row),
            },
            { icon: () => h(PencilAltIcon) }
          )
        );
      },
    },
    {
      key: "remove",
      width: "50",
      render(row) {
        return h(
          "div",
          { class: "primaryButton" },
          h(
            NButton,
            {
              size: "small",
              onClick: () => handleRemoveJudge(row),
            },
            { icon: () => h(TrashIcon) }
          )
        );
      },
    },
  ];
};

export default defineComponent({
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  components: {
    "file-download": FileDownload,
    "upload-file": UploadFile,
    "add-manual": AddManual,
    "confirmation-dialog": ConfirmationDialog,
  },
  methods: {
    isMobileView() {
      if (this.windowWidth <= 1000) {
        return true;
      } else {
        return false;
      }
    },
    getResponsiveCols() {
      if (this.windowWidth <= 1000) {
        return 1;
      } else {
        return 2;
      }
    },
    getAllProjects () {
      const eventName = this.eventObject.name
        .toLowerCase()
        .replaceAll(" ", "_");
      let url = null,
        link = null;

      this.$store
          .dispatch("downloadJudges", {
          eventId: this.eventObject.id,
        })
        .then((r) => {
          url = window.URL.createObjectURL(new Blob([r.data]));
          link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", eventName + "_judges.psv");
          link.click();
        });
    
    },
    closeUpload() {
      this.showUpload = false;
    },
    closeManual() {
      this.showManual = false;
      this.currJudge = null;
    },
  },
  computed: {
    allIsLoaded() {
      return (
        this.$store.getters.judgesLoaded && this.$store.getters.eventsLoaded
      );
    },
  },
  async created() {
    if (!this.$store.getters.eventsLoaded) {
      this.$store.dispatch("APIHelper", { name: "fetchAllEvents" }).then(() => {
        this.$store.dispatch("APIHelper", {
          name: "fetchAllJudges",
          params: this.$route.params.eventName,
        });
      });
    } else {
      this.$store.dispatch("APIHelper", {
        name: "fetchAllJudges",
        params: this.$route.params.eventName,
      });
    }
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const eventObject = computed(() => {
      return store.getters.getEventWithName(route.params.eventName);
    });
    const judges = computed(() => {
      return store.getters.getAllJudges;
    });
    const disciplines = computed(() => {
      return eventObject.value.disciplines;
    });
    const disciplineOptions = computed(() =>
      disciplines.value.map((v) => ({
        label: v,
        value: v,
      }))
    );
    const data = computed(() => {
      return judges.value.map((v) => ({
        id: v.id,
        name: v.name,
        email: v.email,
        disciplines: v.disciplines,
        affiliations: v.affiliations,
        days: v.days,
        isCheckedIn: v.isCheckedIn,
        projects: v.projects,
        notifications: v.notifications,
        role: v.role,
        eventId: v.eventId,
        numCompleted: v.numCompleted,
        hash: v.hash,
      }));
    });
    const tableRef = ref(null);
    const disciplineFilterList = ref(null);
    const nameFilterString = ref(null);
    const currJudge = ref(null);
    const showManual = ref(false);
    const showConfirm = ref(false);

    const deleteJudge = () => {
      store
        .dispatch("APIHelper", {
          name: "deleteJudge",
          params: currJudge.value.id,
        })
        .finally(() => {
          currJudge.value = null;
          showConfirm.value = false;
        });
    };

    return {
      eventObject,
      data: data,
      table: tableRef,
      currJudge,
      showManual,
      showConfirm,
      showUpload: ref(false),
      deleteJudge,
      columns: createColumns({
        handleRemoveJudge(rowData) {
          showConfirm.value = true;
          currJudge.value = rowData;
        },
        handleEditJudge(rowData) {
          currJudge.value = rowData;
          showManual.value = true;
        },
      }),
      pagination: {
        pageSize: 10,
      },
      disciplineOptions,
      disciplineFilterList: disciplineFilterList,
      nameFilterString: nameFilterString,
      filterDisciplines: () => {
        tableRef.value.filter({
          disciplines: disciplineFilterList.value,
        });
      },
      filterNames: () => {
        tableRef.value.filter({
          name: nameFilterString.value,
        });
      },
    };
  },
});
</script>
