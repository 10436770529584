<template>
  <div>
    <p class="settingsSubheader">{{ $strings.PROFILE_HEADER }}</p>
    <p class="settingsDescription">{{ $strings.PROFILE_DESCRIPTION }}</p>
    <div class="settingsBody">
      <n-form
        v-if="$store.getters.accountLoaded"
        :model="personalInfo"
        ref="formRef"
        label-placement="top"
      >
        <n-grid :cols="getResponsiveCols()" :span="24" :x-gap="24">
          <n-form-item-gi
            :span="12"
            :label="$strings.FIRST_NAME"
            path="firstName"
          >
            <n-input
              :placeholder="$strings.FIRST_NAME"
              v-model:value="personalInfo.firstName"
              @blur="updateAccount"
            />
          </n-form-item-gi>
          <n-form-item-gi
            :span="12"
            :label="$strings.LAST_NAME"
            path="lastName"
          >
            <n-input
              :placeholder="$strings.LAST_NAME"
              v-model:value="personalInfo.lastName"
              @blur="updateAccount"
            />
          </n-form-item-gi>
          <n-form-item-gi :span="12" :label="$strings.EMAIL" path="email">
            <n-input
              :placeholder="$strings.EMAIL"
              v-model:value="personalInfo.email"
              disabled
            />
          </n-form-item-gi>
          <n-form-item-gi :span="12" :label="$strings.STATUS">
            <n-input :value="$strings.ADMIN" disabled />
          </n-form-item-gi>
        </n-grid>
      </n-form>
    </div>

    <div class="primaryButton">
      <n-button @click="goToAzure">
        <PencilAltIcon class="buttonIcon" />
        {{ $strings.EDIT_ACCOUNT_INFO }}
      </n-button>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { PencilAltIcon } from "@heroicons/vue/outline";
import { useWindowSize } from "vue-window-size";

export default defineComponent({
  components: {
    PencilAltIcon,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      resultsFetched: false,
      windowWidth: width,
      windowHeight: height,
      collapsed: ref(this.isMobileView),
    };
  },
  methods: {
    goToAzure() {
      window.open("https://portal.azure.com/");
    },
    isMobileView() {
      if (this.windowWidth <= 650) {
        return true;
      } else {
        return false;
      }
    },
    getResponsiveCols() {
      if (this.windowWidth <= 650) {
        return 1;
      } else {
        return 2;
      }
    },
  },
  setup() {
    const store = useStore();
    const account = computed(() => {
      return store.getters.getAccount;
    });

    const name = account.value.name.split(" ");
    const firstName = name[0];
    const lastName = name.slice(1).join(" ");
    const formRef = ref(null);

    const personalInfo = ref({
      firstName: firstName,
      lastName: lastName,
      email: account.value.email,
    });

    const updateAccount = () => {
      store.dispatch("updateAccountInfo", {
        firstName: personalInfo.value.firstName,
        lastName: personalInfo.value.lastName,
      });
    };

    return {
      formRef,
      updateAccount,
      personalInfo: personalInfo,
    };
  },
});
</script>
