<template>
  <div class="home">
    <div class="flex justify-center mt-24 mx-24">
      <div class="homepageHeader m-auto text-center">
        <div class="homepageSubheader m-auto">
          University of Central Florida
        </div>
        Senior Design Showcase
      </div>
    </div>
    <n-card
      class="judgeDashboardCard"
      :bordered="false"
      style="margin-bottom: 5px"
    >
      <div class="homepageSection">
        <h1 class="homepageSectionHeader">About Us</h1>
        <p>
            Welcome to the College of Engineering and Computer Science Senior Design
            Showcase! Our alumni chapter thanks you for volunteering your time
            and expertise as a judge for our showcase. Since the Fall of 2017,
            the CECS Alumni Chapter has worked hard to transform this event to what
            it is today. This showcase offers an opportunity for our students to hear
            feedback from the alumni who have been in their shoes and connect with
            those who could be their future employers! It also provides a chance for
            our alumni and industry partners to stay connected with the college and
            engage with their peers, students, faculty and staff. We hope you’ll
            continue to come back as a volunteer judge and stay engaged with our team.
        </p>
      </div>
      <div class="homepageSection">
        <h1 class="homepageSectionHeader">Join Us</h1>
        <p>
            If you are an alum of the UCF College of Engineering and
            Computer Science and would like to get more involved with
            the planning of events like this, are interested in growing
            your network or want to learn about how you can have a larger
            impact for the college…then you should consider joining our alumni
            chapter! We meet once a month (in-person & virtually) and are always
            looking to hear from new alumni. Reach out to Harmony Brown
            for more details: harmony.brown@ucf.edu.
        </p>
      </div>
      <div class="homepageSection">
        <h1 class="homepageSectionHeader">Contact Us</h1>
        <p>Reach out to us.</p>
        <div>
            <span className="homepageContactSection">
                <MailIcon class="buttonIcon" /> harmony.brown@ucf.edu.
            </span>
        </div>
        <span className="homepageContactSection">
          <LocationMarkerIcon class="buttonIcon" />
          4000 Central Florida Blvd, Orlando FL 32816
        </span>
      </div>
    </n-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { MailIcon, LocationMarkerIcon } from "@heroicons/vue/outline";

export default defineComponent({
  components: {
    MailIcon,
    LocationMarkerIcon,
  },
  setup() {},
});
</script>
