import axios from "axios";

export const affiliationsDefaultState = () => {
  return { affiliations: undefined };
};

export const affiliations = {
  state: affiliationsDefaultState,
  getters: {
    getAllAffiliations: (state) => {
      return state.affiliations;
    },
    affiliationsLoaded: (state) => {
      return !!state.affiliations;
    },
  },
  mutations: {
    setAffiliations(state, affiliations) {
      state.affiliations = affiliations;
    },
  },
  actions: {
    async fetchAllAffiliations(context) {
      await axios.get("affiliations").then((response) => {
        let aff = response.data; // Remap to an array of affiliations
        context.commit("setAffiliations", [
          ...new Set(aff.map(({ sponsor }) => sponsor)),
        ]);
      });
    },
  },
};
