<template>
  <div class="dashboard">
    <n-card
      class="adminDashboardCard"
      :bordered="false"
      title="All Projects"
      style="margin-bottom: 5px"
    >
      <template #header-extra>
        <div class="primaryButton">
          <notification-button />
        </div>
      </template>
      <project-details-list
        v-if="projectsLoaded && eventsLoaded"
        :is-ballots="false"
      />
    </n-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useMessage } from "naive-ui";
import ProjectDetailsList from "@/components/ProjectDetailsList.vue";
import NotificationButton from "@/components/NotificationButton.vue";

export default defineComponent({
  computed: {
    projectsLoaded() {
      return this.$store.getters.projectsLoaded;
    },
    eventsLoaded() {
      return this.$store.getters.eventsLoaded;
    },
    unseenNotifications() {
      return this.$store.getters.getUnseenCount;
    },
  },
  components: {
    "project-details-list": ProjectDetailsList,
    "notification-button": NotificationButton,
  },
  watch: {
    unseenNotifications(newValue, oldValue) {
      if (newValue > oldValue) this.newNotification(newValue);
    },
  },
  async created() {
    if (!this.$store.getters.eventsLoaded)
      this.$store.dispatch("APIHelper", { name: "fetchActiveEvent" });
    if (!this.$store.getters.projectsLoaded)
      this.$store.dispatch("APIHelper", { name: "fetchAllMyProjects" });
    if (!this.$store.getters.notificationsLoaded)
      this.$store.dispatch("fetchAllNotifications");
  },
  setup() {
    const message = useMessage();
    return {
      newNotification: (num) =>
        message.info("You have a new notification! (" + num + ")"),
    };
  },
});
</script>
