<template>
  <n-card
    class="adminDashboardCard"
    :bordered="false"
    title="Live Results"
    style="margin-bottom: 5px"
  >
    <n-grid :cols="1">
      <n-gi>
        <p class="donutLegend">
          <span className="donutKeyText" id="donutCompletedText"
            >Completed Ballots</span
          >{{ formatComplete }}% ({{ numCompleted }})
        </p>
      </n-gi>
      <n-gi>
        <p class="donutLegend">
          <span className="donutKeyText" id="donutIncompleteText"
            >Incomplete Ballots</span
          >{{ formatIncomplete }}% ({{ numIncomplete }})
        </p>
      </n-gi>
    </n-grid>
    <n-gi class="svg-item">
      <svg viewBox="0 0 40 40" class="donut">
        <circle
          class="donut-hole"
          cx="20"
          cy="20"
          r="15.91549430918954"
          fill="#fff"
        />
        <circle
          class="donut-incomplete"
          cx="20"
          cy="20"
          r="15.91549430918954"
          fill="transparent"
          stroke-width="8"
        />
        <circle
          class="donut-complete"
          cx="20"
          cy="20"
          r="15.91549430918954"
          fill="transparent"
          stroke-width="8"
          :stroke-dasharray="stroke"
          stroke-dashoffset="25"
        />
      </svg>
    </n-gi>
  </n-card>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useWindowSize } from "vue-window-size";
import { useStore } from "vuex";

export default defineComponent({
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  methods: {
    isMobileView() {
      if (this.windowWidth <= 1150) {
        return 1;
      } else {
        return 2;
      }
    },
  },
  computed: {
    stroke() {
      return this.completePercent + " " + this.incompletePercent;
    },
    formatComplete() {
      return this.completePercent.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });
    },
    formatIncomplete() {
      return this.incompletePercent.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });
    },
  },
  setup() {
    const store = useStore();

    const ballotList = computed(() => store.getters.getAllBallots);
    const numCompleted = computed(() => {
      let ret = 0;
      ballotList.value.forEach((b) => (b.completed ? ret++ : ret));
      return ret;
    });
    const numTotal = computed(() => ballotList.value.length);

    const completePercent = computed(() => {
      if (numTotal.value == 0) return 0;
      return (numCompleted.value / numTotal.value) * 100;
    });
    const incompletePercent = computed(() => 100 - completePercent.value);

    return {
      numCompleted,
      numIncomplete: computed(() => numTotal.value - numCompleted.value),
      completePercent,
      incompletePercent,
    };
  },
});
</script>
