<template>
  <n-card
    class="adminDashboardCard"
    :bordered="false"
    title="Day Management"
    style="margin-bottom: 5px"
  >
    <p>
      Click the button below to start or end this day within your event. You are
      also able to add a project to this current ongoing round.
      <span class="italicSubtext">
        Note that once you end a day you cannot restart it.
      </span>
    </p>
    <div class="primaryButton startEndEventDayButton">
      <n-button @click="handleButton" :loading="isLoading">
        {{ buttonText }}
      </n-button>
      <span className="eventDayDurationText" :key="activeEvent.dayOngoing">
        Running for: <span>{{ getTime }}</span>
      </span>
    </div>
    <div class="secondaryButton" v-if="activeEvent.currentRound > 0">
      <n-button
        @click="showAddProjectModal = true"
        :loading="updateProjectsLoading"
      >
        Add Projects to Round {{ activeEvent.currentRound + 1 }}
      </n-button>
    </div>
  </n-card>
  <div class="primaryModal">
    <n-modal
      :class="[isMobileView() ? 'popoverModalMobile' : 'popoverModal']"
      v-model:show="showEndDayModal"
      preset="card"
      title="End Day"
    >
      <div v-if="lastDay"></div>
      <div v-else>
        <div className="popoverModalBody">
          <p>
            Choose to continue this current round onto the next day in your
            event.
          </p>
          <div class="primaryButton continueRoundButton">
            <n-button @click="handleChoice(true)">
              Continue Round To Next Day
            </n-button>
          </div>
        </div>
        <div className="popoverModalBody">
          <p>
            Alternatively, select how many teams per discipline you would like
            to advance to the next round.
          </p>
          <n-grid cols="3" class="continueRoundBody">
            <n-gi
              ><n-input-number v-model:value="numAdvancing" clearable
            /></n-gi>
            <n-gi className="nextRoundButton"
              ><div class="primaryButton">
                <n-button
                  @click="handleChoice(false)"
                  :disabled="numAdvancing <= 0"
                >
                  Next Round
                </n-button>
              </div></n-gi
            >
          </n-grid>
        </div>
      </div>
    </n-modal>
  </div>
  <div class="primaryModal">
    <n-modal
      :class="[isMobileView() ? 'popoverModalMobile' : 'popoverModal']"
      v-model:show="showAddProjectModal"
      preset="card"
      :title="'Add Projects to Round ' + (activeEvent.currentRound + 1)"
    >
      <div className="removeProjModalBody">
        <n-select
          placeholder="Select Project(s)"
          v-model:value="newProjects"
          :options="getAvailableProjects"
          multiple
          filterable
        >
          <template #empty> There are no projects to add. </template>
        </n-select>
      </div>
      <div className="primaryButton">
        <n-button @click="updateProjects()"> Add Project(s) </n-button>
      </div>
    </n-modal>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useMessage } from "naive-ui";
import DateFormatters from "@/helpers/DateFormatters.js";
import { useWindowSize } from "vue-window-size";

export default defineComponent({
  computed: {
    getTime() {
      return this.activeEvent.dayOngoing && this.activeEvent.dayTimestamp
        ? this.time
        : "TBA";
    },
  },
  methods: {
    isMobileView() {
      if (this.windowWidth <= 650) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      showModal: ref(false),
      windowWidth: width,
      windowHeight: height,
    };
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const message = useMessage();

    const activeEvent = computed(() => {
      return store.getters.getEventsWithStatus("Active")[0];
    });

    const lastDay = ref(
      DateFormatters.getLength(activeEvent.value.timespan) ==
        activeEvent.value.currentDay
    );

    const time = ref(
      DateFormatters.getTimeElapsedFromString(activeEvent.value.dayTimestamp)
    );

    setInterval(() => {
      time.value = DateFormatters.getTimeElapsedFromString(
        activeEvent.value.dayTimestamp
      );
    }, 1000);

    const ballots = computed(() => {
      return store.getters.getAllBallots;
    });

    const ballotsHaveBeenAllocated = computed(() => {
      return ballots.value && ballots.value.length > 0;
    });

    const showEndDayModal = ref(false);

    const isLoading = ref(false);
    const buttonText = computed(() => {
      const dayOngoing = activeEvent.value.dayOngoing;

      if (!ballotsHaveBeenAllocated.value) {
        return "Start Round";
      } else {
        if (dayOngoing) {
          return activeEvent.value.currentDay + 1 == numDays.value
            ? "Tabulate Results"
            : "End Day";
        } else {
          return "Allow Judging";
        }
      }
    });

    const numDays = computed(() => {
      return DateFormatters.getLength(activeEvent.value?.timespan);
    });

    const handleButton = () => {
      const dayOngoing = activeEvent.value.dayOngoing;

      if (!ballotsHaveBeenAllocated.value) {
        // Start the day
        isLoading.value = true;
        store
          .dispatch("startCurrentRound")
          .then(() => store.dispatch("fetchAllBallots"))
          .finally(() => (isLoading.value = false));
      } else {
        if (dayOngoing) {
          // End the day
          if (activeEvent.value.currentDay + 1 == numDays.value)
            store
              .dispatch("APIHelper", {
                name: "endDayNextRound",
                params: 1,
              })
              .then(() => {
                router.push({
                  name: "MainAdminDashboard",
                });
              });
          else showEndDayModal.value = true;
        } else {
          isLoading.value = true;
          store
            .dispatch("startJudging")
            .finally(() => (isLoading.value = false));
        }
      }
    };

    const handleChoice = (sameRound) => {
      store
        .dispatch("APIHelper", {
          name: sameRound ? "endDaySameRound" : "endDayNextRound",
          params: numAdvancing.value,
        })
        .then(() => {
          router.push({
            name: "EventAdminDashboard",
            params: {
              eventName: activeEvent.value.name,
            },
          });
        });
    };

    const numAdvancing = ref(0);

    const resultsFromPrevRound = computed(() => {
      if (!activeEvent.value) return null;

      const round = activeEvent.value.currentRound;

      if (round == 0) return null;

      const id = activeEvent.value.id;
      const projects =
        store.getters.getResultsForEvent(id).rounds[round - 1]?.projects;

      let ret = {};

      projects?.forEach((p) => (ret[p.id] = p));

      return ret;
    });

    const getAvailableProjects = computed(() => {
      let ret = store.getters.getAllProjects;
      const currRoundProjects = store.getters
        .getAllProjectsOfRound(activeEvent.value.currentRound)
        ?.map((p) => p.id);

      ret = ret?.filter((p) => !currRoundProjects.includes(p.id));

      ret?.sort((a, b) => {
        const aDisc =
          resultsFromPrevRound.value[a.id]?.disciplines.length > 1
            ? "Interdisciplinary"
            : resultsFromPrevRound.value[a.id]?.disciplines[0];
        const bDisc =
          resultsFromPrevRound.value[b.id]?.disciplines.length > 1
            ? "Interdisciplinary"
            : resultsFromPrevRound.value[b.id]?.disciplines[0];

        if (aDisc != bDisc) {
          return aDisc < bDisc ? -1 : 1;
        } else {
          return a.discRanking - b.discRanking;
        }
      });

      return ret?.map((p) => ({
        value: p.id,
        label:
          p.name +
          " (#" +
          resultsFromPrevRound.value[p.id]?.discRanking +
          " in " +
          (resultsFromPrevRound.value[p.id]?.disciplines.length > 1
            ? "Interdisciplinary"
            : resultsFromPrevRound.value[p.id]?.disciplines[0]) +
          ")",
      }));
    });

    const newProjects = ref([]);
    const showAddProjectModal = ref(false);
    const updateProjectsLoading = ref(false);

    const updateProjects = async () => {
      showAddProjectModal.value = false;
      updateProjectsLoading.value = true;

      newProjects.value.forEach(async (id) => {
        await store
          .dispatch("advanceProject", id)
          .then(() =>
            message.success("Advanced " + resultsFromPrevRound.value[id]?.name)
          )
          .catch(() =>
            message.error(
              "Advance " + resultsFromPrevRound.value[id]?.name + " Failed"
            )
          );
      });

      newProjects.value = [];
      updateProjectsLoading.value = false;
    };

    return {
      ballotsHaveBeenAllocated,
      activeEvent,
      isLoading,
      showEndDayModal,
      buttonText,
      handleButton,
      handleChoice,
      numAdvancing,
      getAvailableProjects,
      updateProjectsLoading,
      updateProjects,
      showAddProjectModal,
      newProjects,
      time,
      lastDay,
    };
  },
});
</script>
