<template>
  <div class="judgeWelcomeCard">
    <n-card title="Check In" :bordered="false" class="judgeDashboardCard">
      <h1 class="judgeCheckinSubheader">Overview</h1>
      <h2 class="judgeCheckinDescription">
        Please review the information below and ensure everything is correct
        before proceeding.
      </h2>
      <div class="judgeCheckinBody">
        <div class="judgeCheckinGrid">
          <n-grid :cols="getResponsiveCols()">
            <n-gi class="welcomePrimaryText">Name</n-gi>
            <n-gi>{{ getAccount.name }}</n-gi>
            <n-gi class="welcomePrimaryText">Status</n-gi>
            <n-gi>Judge</n-gi>
            <n-gi class="welcomePrimaryText">Event</n-gi>
            <n-gi>{{ getActiveEvent.name }}</n-gi>
            <n-gi class="welcomePrimaryText">Date</n-gi>
            <n-gi>{{ getDate }}</n-gi>
            <n-gi class="welcomePrimaryText">Company Affiliations</n-gi>
            <n-gi>{{ getStringValue(formInfo.affiliations) }}</n-gi>
          </n-grid>
        </div>
        <h1 class="judgeCheckinSubheader">Attendance</h1>
        <n-grid :cols="getAttendanceResponsiveCols()">
          <n-gi v-for="index in calcNumDays" :key="index">
            <h2>{{ getActiveEvent.modality[index - 1] }}</h2>
            <n-form-item :label="getDateLabel(index)">
              <n-radio-group :value="formInfo.attendance[index - 1]">
                <n-radio value="Attending" :disabled="true">Attending</n-radio>
                <n-radio value="Absent" :disabled="true">Absent</n-radio>
              </n-radio-group>
            </n-form-item>
          </n-gi>
        </n-grid>
      </div>

      <div
        :class="[isMobileView() ? 'confirmGridMobile' : 'confirmGridDesktop']"
      >
        <n-grid :cols="1">
          <n-gi>
            <div class="primaryButton">
              <n-button @click="emitCompleteEvent()"> Confirm </n-button>
            </div>
          </n-gi>
          <n-gi>
            <div class="secondaryButton mt-3">
              <n-button @click="emitBackEvent()"> Back </n-button>
            </div>
          </n-gi>
        </n-grid>
      </div>
    </n-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useWindowSize } from "vue-window-size";

import DateFormatters from "@/helpers/DateFormatters.js";

export default defineComponent({
  props: {
    formInfo: Object,
  },
  emits: ["complete", "back"],
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  computed: {
    getAccount() {
      return this.$store.getters.getAccount;
    },
    getActiveEvent() {
      return this.$store.getters.getEventsWithStatus("Active")[0];
    },
    calcNumDays() {
      return DateFormatters.getLength(this.getActiveEvent.timespan);
    },
    getDate() {
      return DateFormatters.getDateRange(this.getActiveEvent.timespan);
    },
  },
  methods: {
    isMobileView() {
      if (this.windowWidth <= 900) {
        return true;
      } else {
        return false;
      }
    },
    getResponsiveCols() {
      if (this.windowWidth <= 900) {
        return 1;
      } else {
        return 2;
      }
    },
    getAttendanceResponsiveCols() {
      if (this.windowWidth > 1000) {
        return 4;
      } else if (this.windowWidth <= 1000 && this.windowWidth > 800) {
        return 3;
      } else if (this.windowWidth <= 800 && this.windowWidth > 450) {
        return 2;
      } else {
        return 1;
      }
    },
    emitCompleteEvent() {
      this.$emit("complete");
    },
    emitBackEvent() {
      this.$emit("back");
    },
    getDateLabel(index) {
      return DateFormatters.formatDate(
        DateFormatters.incrementDayBy(
          new Date(this.getActiveEvent.timespan[0]),
          index - 1
        )
      );
    },
    getStringValue(list) {
      let listString = "";
      list.forEach((p) => (listString += p + ", "));
      listString = listString.slice(0, listString.length - 2);
      return listString.length == 0 ? "None" : listString;
    },
  },
  setup() {},
});
</script>
