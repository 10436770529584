import axios from "axios";

export const ballotsDefaultState = () => {
  return { ballots: undefined };
};

export const ballots = {
  state: ballotsDefaultState,
  getters: {
    getAllBallots: (state) => {
      return state.ballots;
    },
    getBallotsByProject: (state) => (pid) => {
      return state.ballots.filter((b) => b.projectId == pid);
    },
    getBallotsByJudge: (state) => (jid) => {
      return state.ballots.filter((b) => b.judgeId == jid);
    },
    ballotsLoaded: (state) => {
      return !!state.ballots;
    },
  },
  mutations: {
    setBallots(state, ballots) {
      state.ballots = ballots;
    },
    addBallot(state, ballot) {
      state.ballots.push(ballot);
    },
    deleteBallot(state, id) {
      state.ballots = state.ballots.filter((p) => p.id != id);
    },
    editBallot(state, ballot) {
      if (!state.ballots) return;

      if (!ballot.id) ballot.id = ballot.ballotId;
      const index = state.ballots.findIndex((b) => b.id == ballot.id);
      if (index == -1) state.ballots.push(ballot);
      else state.ballots[index] = ballot;
    },
  },
  actions: {
    async fetchAllBallots(context) {
      await axios
        .get("events/ballots")
        .then((response) => context.commit("setBallots", response.data));
    },
    async fetchAllBallotsForRound(context) {
      const roundId = context.getters.getActiveEvent.currentRound;
      await axios
        .get(`events/ballots/round/${roundId}`)
        .then((response) => context.commit("setBallots", response.data));
    },
    async assignBallotToJudge(context, { judgeId, projectId }) {
      await axios.post(`/ballots/${judgeId}/${projectId}`);
    },
  },
};
