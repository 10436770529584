<template>
  <div class="rules">
    <n-card
      class="adminDashboardCard"
      :bordered="false"
      title="Judging Rules"
      style="margin-bottom: 5px"
    >
      <n-grid :cols="getResponsiveCols()">
        <n-gi>
          <n-card title="Frequently Asked Questions" :bordered="false">
              <n-collapse arrow-placement="left">
                  <n-collapse-item title="Where do I find my judging assignments?"
                                   name="1">
                      <div>
                          Judging assignments can be found on your Dashboard. Additional projects
                          can be found on the Projects page.
                      </div>
                  </n-collapse-item>
                  <n-collapse-item title="Where to I find the rubrics to judge the projects?"
                                   name="2">
                      <div>
                          Under Judging Rules - click the button that says "View full Judging Rubric 2022 here"
                      </div>
                  </n-collapse-item>
                  <n-collapse-item title="What do I do if there is no video to judge (we will need answers for virtual and live)?"
                                   name="3">
                      <div>
                          Virtual - Assign 0.5 (smiley face) to each part of the project and note there was no video to judge. <br />
                          Live tabling - Assign 0.5 (smiley face) to each part of the project and note that no live project was available to judge.
                      </div>
                  </n-collapse-item>
                  <n-collapse-item title="Can I go back and update my scores for projects I've previously judged?" name="4">
                      <div>
                          Yes! Simply go back to your Dashboard and select the project that you would like to change a score for.
                      </div>
                  </n-collapse-item>
                  <n-collapse-item title="Who do I contact if I have any issues / concerns / questions on anything not listed above?" name="5">
                      <div>
                          Please contact anyone below<br />
                          Francesca at: Francesca.botteri@ucf.edu <br />
                          Tony at: anthony.goodman@ieee.org <br />
                          Sondra at: sondra.kennett@orlando.gov <br />
                      </div>
                  </n-collapse-item>

              </n-collapse>
          </n-card>
        </n-gi>
        <n-gi>
          <n-card title="Please see below to download the virtual and in-person Juding Rubrics." :bordered="false">
              <div class="rubricListItems">
              
                      
               

                  <!--<n-li>
        Eget arcu dictum varius duis at consectetur lorem donec massa.
    </n-li>
    <n-li>
        Bibendum enim facilisis gravida neque convallis a. Luctus
        venenatis lectus magna fringilla urna porttitor rhoncus dolor
        purus.
    </n-li>
    <n-li>
        Dui ut ornare lectus sit amet. Mauris pellentesque pulvinar.
    </n-li>-->

                  <file-download file="Rubric_Fall2022_virtual.pdf"
                                 text="View virtual Judging Rubric 2022 here." />

                  <file-download file="Rubric_Fall2022_Face-to-Face.pdf"
                                 text="View in-person Judging Rubric 2022 here." />

              </div>
          </n-card>
        </n-gi>
      </n-grid>
    </n-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import FileDownload from "@/components/FileDownload.vue";
import { useWindowSize } from "vue-window-size";

export default defineComponent({
  components: {
    "file-download": FileDownload,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  methods: {
    getResponsiveCols() {
      if (this.windowWidth <= 1000) {
        return 1;
      } else {
        return 2;
      }
    },
  },
  setup() {},
});
</script>
