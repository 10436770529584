<template>
  <div>
    <p class="settingsSubheader">{{ $strings.PROFILE_HEADER }}</p>
    <p class="settingsDescription">{{ $strings.PROFILE_DESCRIPTION }}</p>
    <div class="settingsBody">
      <n-form
        v-if="$store.getters.accountLoaded"
        :model="personalInfo"
        ref="formRef"
        label-placement="top"
      >
        <n-grid :cols="getResponsiveCols()" :span="24" :x-gap="24">
          <n-form-item-gi
            :span="12"
            :label="$strings.FIRST_NAME"
            path="firstName"
          >
            <n-input
              :placeholder="$strings.FIRST_NAME"
              v-model:value="personalInfo.firstName"
              @update:value="changesMade = true"
            />
          </n-form-item-gi>
          <n-form-item-gi
            :span="12"
            :label="$strings.LAST_NAME"
            path="lastName"
          >
            <n-input
              :placeholder="$strings.LAST_NAME"
              v-model:value="personalInfo.lastName"
              @update:value="changesMade = true"
            />
          </n-form-item-gi>
          <n-form-item-gi :span="12" :label="$strings.EMAIL" path="email">
            <n-input
              :placeholder="$strings.EMAIL"
              v-model:value="personalInfo.email"
              @update:value="changesMade = true"
            />
          </n-form-item-gi>
          <n-form-item-gi :span="12" :label="$strings.STATUS">
            <n-input value="Judge" disabled />
          </n-form-item-gi>
        </n-grid>
        <n-form-item label="Affiliations">
          <n-select
            placeholder="Select Affiliations"
            :options="affiliationOptions"
            v-model:value="personalInfo.affiliations"
            @update:value="changesMade = true"
            multiple
            filterable
          />
        </n-form-item>
      </n-form>
    </div>

    <div class="primaryButton">
      <n-button @click="updateAccount" :disabled="!changesMade">
        <PencilAltIcon class="buttonIcon" />
        Save Changes
      </n-button>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useMessage } from "naive-ui";
import { PencilAltIcon } from "@heroicons/vue/outline";
import { useWindowSize } from "vue-window-size";

export default defineComponent({
  components: {
    PencilAltIcon,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      resultsFetched: false,
      windowWidth: width,
      windowHeight: height,
      collapsed: ref(this.isMobileView),
    };
  },
  methods: {
    getResponsiveCols() {
      if (this.windowWidth <= 650) {
        return 1;
      } else {
        return 2;
      }
    },
  },
  setup() {
    const store = useStore();
    const message = useMessage();

    const account = computed(() => {
      return store.getters.getAccount;
    });

    const name = account.value.name.split(" ");
    const firstName = name[0];
    const lastName = name.slice(1).join(" ");
    const formRef = ref(null);

    const affiliationList = computed(() => store.getters.getAllAffiliations);
    const affiliationOptions = affiliationList.value.map((v) => ({
      label: v,
      value: v,
    }));

    const personalInfo = ref({
      firstName: firstName,
      lastName: lastName,
      email: account.value.email,
      affiliations: account.value.affiliations,
    });

    const changesMade = ref(false);

    const updateAccount = () => {
      store
        .dispatch("updateJudgeInfo", {
          firstName: personalInfo.value.firstName,
          lastName: personalInfo.value.lastName,
          email: personalInfo.value.email,
          affiliations: personalInfo.value.affiliations,
        })
        .then(() => {
          message.success("Profile Updated");
          changesMade.value = false;
        })
        .catch(() => message.error("Update Failed"));
    };

    return {
      formRef,
      updateAccount,
      affiliationOptions,
      personalInfo,
      changesMade,
    };
  },
});
</script>
