import { createStore } from "vuex";
import axios from "axios";

import { user, userDefaultState, disposeAuthInterceptor } from "./user.js";
import { events, eventsDefaultState } from "./events.js";
import { disciplines, disciplinesDefaultState } from "./disciplines.js";
import { instructors, instructorsDefaultState } from "./instructors.js";
import { utilities, utilitiesDefaultState } from "./utilities.js";
import { projects, projectsDefaultState } from "./projects.js";
import { affiliations, affiliationsDefaultState } from "./affiliations.js";
import { judges, judgesDefaultState } from "./judges.js";
import { ballots, ballotsDefaultState } from "./ballots.js";
import { notifications, notificationsDefaultState } from "./notifications.js";
import { results, resultsDefaultState } from "./results.js";

export const resetState = (state) => {
  // Clear State
  Object.keys(state).forEach((key) => {
    state[key] = defaultStates[key];
  });

  resetLocal();
};

const resetLocal = () => {
  // Clear Local Storage
  localStorage.clear();

  // Clear axios state stuff
  delete axios.defaults.headers.common["AUTHORIZATION"];
  delete axios.defaults.headers.common["X-JUDGE-AUTH"];

  // Dispose old Auth Interceptor
  disposeAuthInterceptor();
};

const defaultStates = {
  user: userDefaultState(),
  events: eventsDefaultState(),
  disciplines: disciplinesDefaultState(),
  instructor: instructorsDefaultState(),
  utilities: utilitiesDefaultState(),
  projects: projectsDefaultState(),
  affiliations: affiliationsDefaultState(),
  judges: judgesDefaultState(),
  ballots: ballotsDefaultState(),
  notifications: notificationsDefaultState(),
  results: resultsDefaultState(),
};

export const store = createStore({
  mutations: {
    resetState(state) {
      resetState(state);
    },
  },
  modules: {
    user,
    events,
    disciplines,
    instructors,
    utilities,
    projects,
    affiliations,
    judges,
    ballots,
    notifications,
    results,
  },
});
