<template>
  <div class="judgeWelcomeCard">
    <n-card title="Check In" :bordered="false" class="judgeDashboardCard">
      <h1 class="judgeCheckinSubheader">Confirm your Attendance</h1>
      <h2 class="judgeCheckinDescription">
        Confirm whether you will be able to attend the following days.
      </h2>
      <div class="judgeCheckinBody">
        <n-grid :cols="getResponsiveCols()">
          <n-gi v-for="index in numDays" :key="index">
            <h2>{{ activeEvent.modality[index - 1] }}</h2>
            <n-form-item :label="getDateLabel(index)">
              <n-radio-group
                v-model:value="attendance[index - 1]"
                @update:value="emitUpdateForm"
              >
                <n-radio value="Attending">Attending</n-radio>
                <n-radio value="Absent">Absent</n-radio>
              </n-radio-group>
            </n-form-item>
          </n-gi>
        </n-grid>
      </div>
      <h2 class="italicSubtext">
        Note that you will be able to modify your attendance after the check in
        process is complete.
      </h2>
      <div
        :class="[isMobileView() ? 'confirmGridMobile' : 'confirmGridDesktop']"
      >
        <n-grid :cols="1">
          <n-gi>
            <div class="primaryButton">
              <n-button
                :disabled="!formIsValid"
                @click="emitNextEvent()"
                :key="formIsValid"
              >
                Continue
              </n-button>
            </div>
          </n-gi>
          <n-gi>
            <div class="secondaryButton mt-3">
              <n-button @click="emitBackEvent()"> Back </n-button>
            </div>
          </n-gi>
        </n-grid>
      </div>
    </n-card>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import DateFormatters from "@/helpers/DateFormatters.js";
import { useWindowSize } from "vue-window-size";

export default defineComponent({
  props: {
    formInfo: Object,
  },
  data() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  emits: ["update", "next", "back"],
  methods: {
    isMobileView() {
      if (this.windowWidth <= 900) {
        return true;
      } else {
        return false;
      }
    },
    getResponsiveCols() {
      if (this.windowWidth > 1000) {
        return 4;
      } else if (this.windowWidth <= 1000 && this.windowWidth > 800) {
        return 3;
      } else if (this.windowWidth <= 800 && this.windowWidth > 450) {
        return 2;
      } else {
        return 1;
      }
    },
    emitNextEvent() {
      this.emitUpdateForm();
      this.$emit("next");
    },
    emitBackEvent() {
      this.$emit("back");
    },
    getDateLabel(index) {
      return DateFormatters.formatDate(
        DateFormatters.incrementDayBy(
          new Date(this.activeEvent.timespan[0]),
          index - 1
        )
      );
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const activeEvent = store.getters.getEventsWithStatus("Active")[0];
    const numDays = DateFormatters.getLength(activeEvent.timespan);

    const attendance = ref(props.formInfo.attendance);

    const formIsValid = computed(() => {
      return (
        attendance.value &&
        attendance.value.filter((a) => a != "").length >= numDays
      );
    });

    const emitUpdateForm = () => {
      emit("update", attendance.value);
    };

    return {
      activeEvent,
      numDays,
      formIsValid,
      attendance,
      emitUpdateForm,
    };
  },
});
</script>
