<template>
    <n-form :model="formValue" :rules="rules" ref="formRef">

        <n-form-item class="formItemBlock mt-2"
                     label="Project Name"
                     path="name">

            <n-input class="eventFormInput"
                     v-model:value="formValue.name"
                     placeholder="Project Name" />
        </n-form-item>

        <n-form-item class="formItemBlock mt-2"
                     label="Project Description"
                     path="description">
            <n-input v-model:value="formValue.description"
                     type="textarea"
                     placeholder="Add Description" />
        </n-form-item>

        <n-form-item class="formItemBlock mt-2"
                     label="Team Members"
                     path="students">
            <n-dynamic-tags v-model:value="formValue.students" />
        </n-form-item>
        <n-form-item class="formItemBlock"
                     label="Project Discipline(s)"
                     path="disciplines">
            <n-select :placeholder="$strings.SELECT_DISC"
                      :options="disciplineOptions"
                      v-model:value="formValue.disciplines"
                      multiple
                      filterable />
        </n-form-item>
        <n-form-item class="formItemBlock mt-2"
                     label="Project Sponsor(s)"
                     path="sponsors">
            <n-dynamic-tags v-model:value="formValue.sponsors" />
        </n-form-item>
        <n-form-item class="formItemBlock mt-2" label="Project URL" path="vidLink">
            <n-input class="eventFormInput"
                     v-model:value="formValue.vidLink"
                     placeholder="YouTube Link" />
        </n-form-item>
    </n-form>
  <div class="primaryButton">
    <n-button @click="saveForm">Save</n-button>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default defineComponent({
  props: {
    eventName: String,
    instructorDisciplines: Array,
    currProject: {
      default: null,
      type: Object,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();

    const isEdit = props.currProject != null ? true : false;

    const eventObject = computed(() => {
      return store.getters.getEventWithName(
        props.eventName ? props.eventName : route.params.eventName
      );
    });

    const disciplines = computed(() => {
      if (props.instructorDisciplines) {
        return eventObject.value.disciplines.filter((d) =>
          props.instructorDisciplines.includes(d)
        );
      }
      return eventObject.value.disciplines;
    });

    const disciplineOptions = computed(() =>
      disciplines.value.map((v) => ({
        label: v,
        value: v,
      }))
    );

    const formRef = ref(null);
    const formValue = ref({
      id: isEdit ? props.currProject.id : "",
      name: isEdit ? props.currProject.name : "",
      description: isEdit ? props.currProject.description : "",
      students: isEdit ? props.currProject.studentsArray : [],
      disciplines: isEdit ? props.currProject.disciplines : [],
      sponsors: isEdit ? props.currProject.sponsorsArray : [],
      vidLink: isEdit ? props.currProject.vidLink : "",
      ballots: isEdit ? props.currProject.ballots : [],
      eventId: isEdit ? props.currProject.eventId : eventObject.value?.id,
      avgScore: isEdit ? props.currProject.avgScore : 0,
      ranking: isEdit ? props.currProject.ranking : 0,
      discRanking: isEdit ? props.currProject.discRanking : 0,
    });
    const rules = {
      name: {
        required: true,
        message: "Please input project name.",
        trigger: "blur",
        validator() {
          const input = formValue.value.name;
          return input.length > 0;
        },
      },
        description: {
        required: false,
      },
      students: {
        required: true,
        message: "There must be at least one team member.",
        trigger: "blur",
        validator() {
          const input = formValue.value.students;
          return input != null && input.length > 0;
        },
      },
      disciplines: {
        required: true,
        message: "There must be at least one discipline.",
        trigger: "blur",
        validator() {
          const input = formValue.value.disciplines;
          return input.length > 0;
        },
      },
      sponsors: {
        required: false,
      },
      vidLink: {
        required: false,
      },
    };

    const saveForm = () => {
      formRef.value.validate().then(() => {
        store
          .dispatch("APIHelper", {
            name: isEdit ? "editProject" : "addProject",
            params: formValue.value,
          })
          .then(() => emit("close"));
      });
    };

    return {
      formRef,
      rules,
      formValue,
      saveForm,
      disciplineOptions,
    };
  },
});
</script>
