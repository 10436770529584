<template>
  <div class="primaryModal">
    <n-modal
      :show="true"
      preset="dialog"
      type="warning"
      :closable="false"
      :title="$strings.ERROR_TITLE"
      :content="message"
      :positive-text="$strings.YES"
      :negative-text="$strings.CANCEL"
      @positive-click="handlePositive"
      @negative-click="handleNegative"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    message: String,
  },
  methods: {
    handlePositive() {
      this.$emit("handlePositive");
    },
    handleNegative() {
      this.$emit("handleNegative");
    },
  },
});
</script>
