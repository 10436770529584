import axios from "axios";

export const notificationsDefaultState = () => {
  return { notifications: undefined, unseen: 0 };
};

export const notifications = {
  state: notificationsDefaultState,
  getters: {
    getAllNotifications: (state) => {
      return state.notifications;
    },
    unseenNotifications: (state) => {
      return state.unseen > 0;
    },
    notificationsLoaded: (state) => {
      return !!state.notifications;
    },
    getUnseenCount: (state) => {
      return state.unseen;
    },
  },
  mutations: {
    setNotifications(state, notifications) {
      state.notifications = notifications;
      state.unseen = state.notifications.filter((n) => n.seen).length;
    },
    seeNotification(state, notifId) {
      state.unseen--;
      const index = state.notifications.findIndex((n) => n.id == notifId);
      state.notifications[index].seen = true;
    },
    addNotification(state, notification) {
      state.notifications.push(notification);
      state.unseen++;
    },
  },
  actions: {
    fetchAllNotifications(context) {
      context.commit(
        "setNotifications",
        context.getters.getAccount.notifications
      );
    },
    async sendNotificationToJudge(
      context,
      { judgeId, notification, sendEmail }
    ) {
      notification.emailToo = sendEmail;
      axios.post(`judge/${judgeId}/notification`, notification);
    },
    async seeAllNotifications(context) {
      let notifications = context.getters.getAllNotifications;
      let account = context.getters.getAccount;
      notifications.forEach(async (n) => {
        if (!n.seen) {
          await axios.put(`judge/notification/${n.id}`).then(() => {
            context.commit("seeNotification", n.id);
            const index = account.notifications.findIndex(
              (nn) => nn.id == n.id
            );
            account.notifications[index].seen = true;
            context.commit("setAccount", account);
          });
        }
      });
    },
  },
};
