<template>
  <div class="App">
    <n-message-provider>
      <n-dialog-provider>
        <nav-bar />
        <utility-wrapper />
      </n-dialog-provider>
    </n-message-provider>
  </div>
</template>

<script>
import UtilityWrapper from "./components/UtilityWrapper.vue";
import SdShowCaseNav from "./components/SdShowCase-Nav.vue";

export default {
  name: "App",
  components: {
    "utility-wrapper": UtilityWrapper,
    "nav-bar": SdShowCaseNav,
  },
  created() {
    const accessToken = localStorage.getItem("accessToken");
    const account = localStorage.getItem("account");
    const role = localStorage.getItem("role");
    const judgeHash = localStorage.getItem("judgeHash");

    this.$store.dispatch("setFromLocal", {
      judgeHash,
      accessToken,
      account,
      role,
    });
  },
};
</script>

<style>
/* Fonts */
@font-face {
  font-family: "Montserrat Black";
  src: local("Montserrat-Black"),
    url(./assets/fonts/Montserrat/Montserrat-Black.ttf) format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Montserrat Black Italic";
  src: local("Montserrat-BlackItalic"),
    url(./assets/fonts/Montserrat/Montserrat-BlackItalic.ttf) format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Montserrat Bold";
  src: local("Montserrat-Bold"),
    url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat Bold Italic";
  src: local("Montserrat-BoldItalic"),
    url(./assets/fonts/Montserrat/Montserrat-BoldItalic.ttf) format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat Extra Bold";
  src: local("Montserrat-ExtraBold"),
    url(./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf) format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Montserrat Extra Bold Italic";
  src: local("Montserrat-Extra Bold Italic"),
    url(./assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf)
      format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Montserrat Extra Light";
  src: local("Montserrat-ExtraLight"),
    url(./assets/fonts/Montserrat/Montserrat-ExtraLight.ttf) format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Montserrat Extra Light Italic";
  src: local("Montserrat-Extra Light Italic"),
    url(./assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf)
      format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Montserrat Italic";
  src: local("Montserrat-Italic"),
    url(./assets/fonts/Montserrat/Montserrat-Italic.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat Light";
  src: local("Montserrat-Light"),
    url(./assets/fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat Light Italic";
  src: local("Montserrat-LightItalic"),
    url(./assets/fonts/Montserrat/Montserrat-LightItalic.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat Medium";
  src: local("Montserrat-Medium"),
    url(./assets/fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat Medium Italic";
  src: local("Montserrat-MediumItalic"),
    url(./assets/fonts/Montserrat/Montserrat-MediumItalic.ttf)
      format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-Regular"),
    url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat SemiBold";
  src: local("Montserrat-SemiBold"),
    url(./assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat SemiBold Italic";
  src: local("Montserrat-SemiBoldItalic"),
    url(./assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf)
      format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat Thin";
  src: local("Montserrat-Thin"),
    url(./assets/fonts/Montserrat/Montserrat-Thin.ttf) format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Montserrat Thin Italic";
  src: local("Montserrat-ThinItalic"),
    url(./assets/fonts/Montserrat/Montserrat-ThinItalic.ttf) format("truetype");
  font-weight: 100;
}
</style>
