<template>
    <div class="ProjectDetails" @click="handleSelectProject()">
        <n-card hoverable class="projectDetailsCard">
            <n-grid :cols="2">
                <n-gi>
                    <h1 class="projectDetailsDiscipline">
                        {{ getStringValue(projectObject.disciplines) }}
                    </h1>
                </n-gi>
                <n-gi v-if="isBallot">
                    <ballot-status :is-complete="getScore != '-'" />
                </n-gi>
            </n-grid>
            <n-divider />
            <n-grid :cols="getResponsiveCols()">
                <n-gi>
                    <div ref="projectInfo" class="projectInfo">
                        <p class="projectDetailsName">
                            {{ projectObject.name }}
                        </p>
                        <p class="projectDetailsSponsor">
                            {{ getStringValue(projectObject.sponsors) }}
                        </p>
                        <p class="projectDetailsDescription" v-if="!isDescLong">
                            {{ getWholeDescription }}
                        </p>
                        <p class="projectDetailsDescription"
                           v-else-if="showShort"
                           @click.stop="showShort ^= true">
                            {{ getShortDescription }}...
                            <span class="showMoreLessText">Click to see more.</span>
                        </p>
                        <p class="projectDetailsDescription"
                           v-else
                           @click.stop="showShort ^= true">
                            {{
 getWholeDescription
                            }}<span class="showMoreLessText"> Click to see less.</span>
                        </p>
                        <h2 class="projectDetailsStudentsHeader">Team Members</h2>
                        <p class="projectDetailsStudents">
                            {{ getStringValue(projectObject.students) }}
                        </p>
                        <div v-if="isBallot">
                            <h3 class="projectDetailsScore">
                                <span class="judgeScore">{{ getScore }}</span> / 5
                            </h3>
                            <p class="projectDetailsScoreText">Your Score</p>
                        </div>
                        <div v-else class="primaryButton projectDetailsOptIn">
                            <n-button @click.stop="optInBallot()"
                                      :loading="loading"
                                      :disabled="isConflict || !activeEvent.dayOngoing">
                                Opt In
                            </n-button>
                        </div>
                    </div>
                </n-gi>

                <n-gi v-if="isEmbedLink()">
                    <iframe :class="[
              getResponsiveVideoFrame()
                ? 'projectDetailsVideoMobile'
                : 'projectDetailsVideoDesktop',
            ]"
                            :width="vidWidth"
                            :height="vidHeight"
                            :src="getEmbedLink()"
                            :title="projectObject.name + ' Demo'"
                            frameborder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
                            allowfullscreen />
                </n-gi>

                <n-gi v-else>

                    <a href="https://www.cecs.ucf.edu/SeniorDesignShowcase/showcase/" target="_blank">
                        <img src="../assets/images/YouTube.png" alt="Senior Design Showcase" />
                    </a>


                </n-gi>



            </n-grid>
        </n-card>
    </div>
</template>

<script>import { defineComponent, ref } from "vue";
    import { useWindowSize } from "vue-window-size";
    import { useMessage } from "naive-ui";
    import { useStore } from "vuex";
    import BallotStatus from "./BallotStatus.vue";
    import getYouTubeID from "get-youtube-id";

    let MAX_SHORT = 200;

    export default defineComponent({
        props: {
            isBallots: Boolean,
            projectId: String,
        },
        components: {
            "ballot-status": BallotStatus,
        },
        data() {
            const { width, height } = useWindowSize();
            return {
                vidHeight: 360,
                vidWidth: "auto",
                windowWidth: width,
                windowHeight: height,
                showShort: true,
            };
        },
        computed: {
            activeEvent() {
                return this.$store.getters.getActiveEvent;
            },
            projectObject() {
                return this.$store.getters.getProjectWithId(this.projectId);
            },
            getShortDescription() {
                let description = this.projectObject.description.slice(0, MAX_SHORT);
                return description;
            },
            getWholeDescription() {
                let description = this.projectObject.description;
                return description;
            },
            isDescLong() {
                return this.projectObject.description.length > MAX_SHORT;
            },
            getScore() {
                // Check if project associated with judge
                if (!this.projectObject["ballots"]?.length) return "-";

                // Can score be calculated?
                const ballot = this.projectObject.ballots[0];
                if (!ballot?.scores?.length || !ballot.completed) return "-";

                // Calc Score
                const avg =
                    ballot.scores.reduce((tot, next) => tot + next.rating, 0) /
                    ballot.scores.length;

                // Round to 2 fixed points
                const rounded = Math.round((avg + Number.EPSILON) * 100) / 100;

                // Use rounded
                return rounded;
            },
            isConflict() {
                let flag = false;
                const account = this.$store.getters.getAccount;
                this.projectObject?.sponsors.forEach(
                    (d) => (flag |= account?.affiliations.includes(d))
                );
                return flag;
            },
        },
        methods: {
            getResponsiveVideoFrame() {
                if (this.windowWidth <= 550) {
                    return true;
                } else {
                    return false;
                }
            },
            getResponsiveCols() {
                if (this.windowWidth <= 1000) {
                    return 1;
                } else {
                    return 2;
                }
            },
            getEmbedLink() {
                if (this.projectObject.vidLink.length !== 0) {
                    return `https://www.youtube.com/embed/${getYouTubeID(
                        this.projectObject.vidLink
                    )}`
                }
                else { return false }
            },
            isEmbedLink() {
                if (this.projectObject.vidLink.length !== 0) {

                    var YouTubeId = getYouTubeID(this.projectObject.vidLink);
                    
                    if (YouTubeId !== null) {
                        return `https://www.youtube.com/embed/${YouTubeId}`
                    }
                }
                else { return false }
            },
            getStringValue(list) {
                let listString = "";
                list.forEach((p) => (listString += p + ", "));
                return listString.slice(0, listString.length - 2);
            },
            getBallotWithJID(jid) {
                const index = this.projectObject.ballots.findIndex(
                    (b) => b.judgeId == jid
                );
                return this.projectObject.ballots[index];
            },
            handleSelectProject() {
                if (this.activeEvent.dayOngoing) {
                    if (this.isBallot) {
                        this.$router.push({
                            name: "JudgeBallot",
                            params: { projectId: this.projectId },
                        });
                    } else {
                        this.showWarningMessage();
                    }
                } else {
                    this.showJudgingNotBegun();
                }
            },
            handleResize() {
                this.showShort = true;
                if (this.$refs.projectInfo) {
                    this.vidWidth = this.$refs.projectInfo.clientWidth;
                    this.vidHeight = this.$refs.projectInfo.clientHeight;
                }
            },
        },
        mounted() {
            window.addEventListener("resize", this.handleResize);
        },
        unmounted() {
            window.removeEventListener("resize", this.handleResize);
        },
        setup(props) {
            const message = useMessage();
            const store = useStore();
            const isBallot = ref(props.isBallots);
            const loading = ref(false);
            const optInBallot = () => {
                loading.value = true;
                store
                    .dispatch("addBallotForProject", props.projectId)
                    .then(() => {
                        isBallot.value = true;
                        message.success("Ballot assigned");
                    })
                    .catch(() => message.error("Ballot could not be assigned"))
                    .finally(() => (loading.value = false));
            };
            return {
                isBallot,
                loading,
                optInBallot,
                showWarningMessage: () =>
                    message.warning("You must opt in to view this ballot"),
                showJudgingNotBegun: () => message.warning("Judging has not begun"),
            };
        },
    });</script>
